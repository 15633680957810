.messages {
  background: rgba(255, 255, 255, .5);
  border: 1px solid $brand-blue;
  color: $brand-blue;
  font-family: $headings-font-family;
  font-weight: $font-weight-bold;
  margin: 3rem 0;
  padding: 1rem 1.5rem;

  em {
    color: $gray-800;
    font-style: normal;
  }
}