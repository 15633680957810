.node-full:not(.node-webform):not(.node-event):not(.node-homepage) {
  @include media-breakpoint-down(xs) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.node-full .sharing {
  .title {
    display: inline-block;
    font-size: $font-size-sm;
  }

  .sharethis-buttons {
    display: inline-block;
  }
}
