.block-feature-testimonial {
  margin-bottom: 30px;

  .field-name-field-quote {
    margin-top: 15px;
  }

  .node-teaser {
    .container {
      > a {
        display: block;

        &:hover,
        &:focus {
          text-decoration: underline;
        }

        img {
          width: 100%;
        }
      }
    }
  }
}