.view-search {
  @include media-breakpoint-down(xs) {
    .view-header,
    .view-filters {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .view-header,
  .view-empty {
    font-family: $headings-font-family;
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
    margin-bottom: $grid-gutter-width;

    .query {
      color: $brand-green;
    }
  }

  .view-filters {
    margin-bottom: $grid-gutter-width * 2;

    form {
      max-width: 100%;
      position: relative;
      width: 100%;

      .views-widget-filter-search_api_views_fulltext {
        position: relative;
      }

      .form-text {
        background: $white;
        border: 2px solid $brand-green;
        color: $brand-green;
        font-family: $headings-font-family;
        font-weight: $font-weight-bold;

        &:focus {
          outline: $brand-green auto 2px;
        }
      }

      .js-clear-field-button {
        background: url("../images/svg/close-green.svg") center center no-repeat;
        background-color: $white;
        top: 15px;
        right: 50px;

        &:hover,
        &:focus {
          background: url("../images/svg/close.svg") center center no-repeat;
          background-color: $white;
        }

        @include media-breakpoint-up(xl) {
          top: 18px;
          right: 56px;
        }
      }

      .form-submit {
        background: transparent;
        height: 50px;
        overflow: hidden;
        padding: 0;
        position: relative;
        text-indent: 101%;
        width: 50px;
        z-index: 1;
      }

      .views-submit-button {
        height: 50px;
        position: absolute;
        top: 0;
        right: 0;
        transition: $transition-base;
        width: 50px;

        &::before {
          content: "\f002";
          color: $brand-green;
          font-family: $font-awesome;
          font-weight: 700;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 0;
        }

        &:hover,
        &:focus {
          opacity: .5;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    position: relative;

    .view-header,
    .view-filters,
    .view-empty {
      height: 50px;
      width: 50%;
    }

    .view-header,
    .view-empty {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: $grid-gutter-width*3;

      .query {
        margin-left: 7px;
      }

      p {
        margin-bottom: 0;
      }
    }

    .view-filters {
      padding-left: 2rem;
      position: absolute;
      top: 0;
      right: 0;

      form {
        .form-submit,
        .views-submit-button {
          height: 50px;
          width: 50px;
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    position: relative;

    .view-header,
    .view-filters,
    .view-empty {
      font-size: $h5-font-size;
      height: 56px;
    }

    .view-header,
    .view-empty {
      margin-bottom: $grid-gutter-width*4;
    }

    .view-filters {
      padding-left: 4rem;

      form {
        .form-submit,
        .views-submit-button {
          height: 56px;
          width: 56px;
        }
      }
    }
  }

  .view-content {
    @include alternate-layout("node-search-result");

    // Alternate bg colors.
    .views-row-1 .node-search-result {
      .col--right {
        background-color: $brand-red;
      }

      &.inspiration--video .col--left {
        &::before {
          background: rgba(241, 100, 100, .8);
        }
      }
    }

    .views-row-2 .node-search-result {
      .col--right {
        background-color: $brand-turquoise-light;
      }

      &.inspiration--video .col--left {
        &::before {
          background: rgba(171, 200, 202, .8);
        }
      }
    }

    .views-row-3 .node-search-result {
      .col--right {
        background-color: $brand-green;
      }

      &.inspiration--video .col--left {
        &::before {
          background: rgba(81, 145, 122, .8);
        }
      }
    }

    .views-row-4 .node-search-result {
      .col--right {
        background-color: $brand-pink-light;
      }

      &.inspiration--video .col--left {
        &::before {
          background: rgba(238, 198, 184, .8);
        }
      }
    }
  }
}

.page-search {
  @include view-mobile-layout;
}
