.view-events {
  &.view-display-id-overview_page {
    @include alternate-layout();

    // Alternate bg colors.
    .views-row-1 .event {
      .col--left {
        background-color: $brand-red;
      }

      .col--right {
        background-color: $brand-red-light;
      }
    }

    .views-row-2 .event {
      .col--left {
        background-color: $brand-turquoise-light;
      }

      .col--right {
        background-color: $brand-turquoise-lighter;
      }
    }

    .views-row-3 .event {
      .col--left {
        background-color: $brand-green;
      }

      .col--right {
        background-color: $brand-green-light;
      }
    }

    .views-row-4 .event {
      .col--left {
        background-color: $brand-pink-light;
      }

      .col--right {
        background-color: lighten($brand-pink-light, 3%);;
      }
    }

    .pager {
      @include pager-load-more;
    }
  }

  &.view-display-id-upcoming_block,
  &.view-display-id-homepage_upcoming_block {
    .slick__slide:nth-child(3n+1) .event {
      background-color: theme-color(red);
    }

    .slick__slide:nth-child(3n+2) .event {
      background-color: theme-color(pink-light);
    }

    .slick__slide:nth-child(3n+3) .event {
      background-color: theme-color(blue);
    }

    .slick__arrow {
      display: none !important;

      @include media-breakpoint-up(sm) {
        display: block !important;
      }
    }

    .slick-dots {
      display: block !important;

      @include media-breakpoint-up(sm) {
        display: none !important;
      }
    }
  }

  &.view-display-id-promoted_block {

  }
}

.page-events {
  @include view-mobile-layout;
}
