@mixin padding-teaser($horizontal: FALSE) {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  @if $horizontal {
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;
  }

  @include media-breakpoint-up(md) {
    padding: 1.75rem 2.5rem;
  }
}
