@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");
@font-face {
  font-family: 'Gotham Book';
  src: url("../fonts/Gotham/Gotham-Book.woff2") format("woff2"), url("../fonts/Gotham/Gotham-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url("../fonts/Gotham/Gotham-Light.woff2") format("woff2"), url("../fonts/Gotham/Gotham-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url("../fonts/Gotham/Gotham-Bold.woff2") format("woff2"), url("../fonts/Gotham/Gotham-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

/*!
 * Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
.fa,
.fas,
.far,
.fal,
.fad,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -.0667em;
}

.fa-xs {
  font-size: .75em;
}

.fa-sm {
  font-size: .875em;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}

.fa-border {
  border: solid 0.08em #eee;
  border-radius: .1em;
  padding: .2em .25em .15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: .3em;
}

.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: .3em;
}

.fa-spin {
  animation: fa-spin 2s infinite linear;
}

.fa-pulse {
  animation: fa-spin 1s infinite steps(8);
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(1, -1);
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(-1, -1);
}

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-flip-both {
  filter: none;
}

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-500px:before {
  content: "\f26e";
}

.fa-abacus:before {
  content: "\f640";
}

.fa-accessible-icon:before {
  content: "\f368";
}

.fa-accusoft:before {
  content: "\f369";
}

.fa-acorn:before {
  content: "\f6ae";
}

.fa-acquisitions-incorporated:before {
  content: "\f6af";
}

.fa-ad:before {
  content: "\f641";
}

.fa-address-book:before {
  content: "\f2b9";
}

.fa-address-card:before {
  content: "\f2bb";
}

.fa-adjust:before {
  content: "\f042";
}

.fa-adn:before {
  content: "\f170";
}

.fa-adversal:before {
  content: "\f36a";
}

.fa-affiliatetheme:before {
  content: "\f36b";
}

.fa-air-conditioner:before {
  content: "\f8f4";
}

.fa-air-freshener:before {
  content: "\f5d0";
}

.fa-airbnb:before {
  content: "\f834";
}

.fa-alarm-clock:before {
  content: "\f34e";
}

.fa-alarm-exclamation:before {
  content: "\f843";
}

.fa-alarm-plus:before {
  content: "\f844";
}

.fa-alarm-snooze:before {
  content: "\f845";
}

.fa-album:before {
  content: "\f89f";
}

.fa-album-collection:before {
  content: "\f8a0";
}

.fa-algolia:before {
  content: "\f36c";
}

.fa-alicorn:before {
  content: "\f6b0";
}

.fa-alien:before {
  content: "\f8f5";
}

.fa-alien-monster:before {
  content: "\f8f6";
}

.fa-align-center:before {
  content: "\f037";
}

.fa-align-justify:before {
  content: "\f039";
}

.fa-align-left:before {
  content: "\f036";
}

.fa-align-right:before {
  content: "\f038";
}

.fa-align-slash:before {
  content: "\f846";
}

.fa-alipay:before {
  content: "\f642";
}

.fa-allergies:before {
  content: "\f461";
}

.fa-amazon:before {
  content: "\f270";
}

.fa-amazon-pay:before {
  content: "\f42c";
}

.fa-ambulance:before {
  content: "\f0f9";
}

.fa-american-sign-language-interpreting:before {
  content: "\f2a3";
}

.fa-amilia:before {
  content: "\f36d";
}

.fa-amp-guitar:before {
  content: "\f8a1";
}

.fa-analytics:before {
  content: "\f643";
}

.fa-anchor:before {
  content: "\f13d";
}

.fa-android:before {
  content: "\f17b";
}

.fa-angel:before {
  content: "\f779";
}

.fa-angellist:before {
  content: "\f209";
}

.fa-angle-double-down:before {
  content: "\f103";
}

.fa-angle-double-left:before {
  content: "\f100";
}

.fa-angle-double-right:before {
  content: "\f101";
}

.fa-angle-double-up:before {
  content: "\f102";
}

.fa-angle-down:before {
  content: "\f107";
}

.fa-angle-left:before {
  content: "\f104";
}

.fa-angle-right:before {
  content: "\f105";
}

.fa-angle-up:before {
  content: "\f106";
}

.fa-angry:before {
  content: "\f556";
}

.fa-angrycreative:before {
  content: "\f36e";
}

.fa-angular:before {
  content: "\f420";
}

.fa-ankh:before {
  content: "\f644";
}

.fa-app-store:before {
  content: "\f36f";
}

.fa-app-store-ios:before {
  content: "\f370";
}

.fa-apper:before {
  content: "\f371";
}

.fa-apple:before {
  content: "\f179";
}

.fa-apple-alt:before {
  content: "\f5d1";
}

.fa-apple-crate:before {
  content: "\f6b1";
}

.fa-apple-pay:before {
  content: "\f415";
}

.fa-archive:before {
  content: "\f187";
}

.fa-archway:before {
  content: "\f557";
}

.fa-arrow-alt-circle-down:before {
  content: "\f358";
}

.fa-arrow-alt-circle-left:before {
  content: "\f359";
}

.fa-arrow-alt-circle-right:before {
  content: "\f35a";
}

.fa-arrow-alt-circle-up:before {
  content: "\f35b";
}

.fa-arrow-alt-down:before {
  content: "\f354";
}

.fa-arrow-alt-from-bottom:before {
  content: "\f346";
}

.fa-arrow-alt-from-left:before {
  content: "\f347";
}

.fa-arrow-alt-from-right:before {
  content: "\f348";
}

.fa-arrow-alt-from-top:before {
  content: "\f349";
}

.fa-arrow-alt-left:before {
  content: "\f355";
}

.fa-arrow-alt-right:before {
  content: "\f356";
}

.fa-arrow-alt-square-down:before {
  content: "\f350";
}

.fa-arrow-alt-square-left:before {
  content: "\f351";
}

.fa-arrow-alt-square-right:before {
  content: "\f352";
}

.fa-arrow-alt-square-up:before {
  content: "\f353";
}

.fa-arrow-alt-to-bottom:before {
  content: "\f34a";
}

.fa-arrow-alt-to-left:before {
  content: "\f34b";
}

.fa-arrow-alt-to-right:before {
  content: "\f34c";
}

.fa-arrow-alt-to-top:before {
  content: "\f34d";
}

.fa-arrow-alt-up:before {
  content: "\f357";
}

.fa-arrow-circle-down:before {
  content: "\f0ab";
}

.fa-arrow-circle-left:before {
  content: "\f0a8";
}

.fa-arrow-circle-right:before {
  content: "\f0a9";
}

.fa-arrow-circle-up:before {
  content: "\f0aa";
}

.fa-arrow-down:before {
  content: "\f063";
}

.fa-arrow-from-bottom:before {
  content: "\f342";
}

.fa-arrow-from-left:before {
  content: "\f343";
}

.fa-arrow-from-right:before {
  content: "\f344";
}

.fa-arrow-from-top:before {
  content: "\f345";
}

.fa-arrow-left:before {
  content: "\f060";
}

.fa-arrow-right:before {
  content: "\f061";
}

.fa-arrow-square-down:before {
  content: "\f339";
}

.fa-arrow-square-left:before {
  content: "\f33a";
}

.fa-arrow-square-right:before {
  content: "\f33b";
}

.fa-arrow-square-up:before {
  content: "\f33c";
}

.fa-arrow-to-bottom:before {
  content: "\f33d";
}

.fa-arrow-to-left:before {
  content: "\f33e";
}

.fa-arrow-to-right:before {
  content: "\f340";
}

.fa-arrow-to-top:before {
  content: "\f341";
}

.fa-arrow-up:before {
  content: "\f062";
}

.fa-arrows:before {
  content: "\f047";
}

.fa-arrows-alt:before {
  content: "\f0b2";
}

.fa-arrows-alt-h:before {
  content: "\f337";
}

.fa-arrows-alt-v:before {
  content: "\f338";
}

.fa-arrows-h:before {
  content: "\f07e";
}

.fa-arrows-v:before {
  content: "\f07d";
}

.fa-artstation:before {
  content: "\f77a";
}

.fa-assistive-listening-systems:before {
  content: "\f2a2";
}

.fa-asterisk:before {
  content: "\f069";
}

.fa-asymmetrik:before {
  content: "\f372";
}

.fa-at:before {
  content: "\f1fa";
}

.fa-atlas:before {
  content: "\f558";
}

.fa-atlassian:before {
  content: "\f77b";
}

.fa-atom:before {
  content: "\f5d2";
}

.fa-atom-alt:before {
  content: "\f5d3";
}

.fa-audible:before {
  content: "\f373";
}

.fa-audio-description:before {
  content: "\f29e";
}

.fa-autoprefixer:before {
  content: "\f41c";
}

.fa-avianex:before {
  content: "\f374";
}

.fa-aviato:before {
  content: "\f421";
}

.fa-award:before {
  content: "\f559";
}

.fa-aws:before {
  content: "\f375";
}

.fa-axe:before {
  content: "\f6b2";
}

.fa-axe-battle:before {
  content: "\f6b3";
}

.fa-baby:before {
  content: "\f77c";
}

.fa-baby-carriage:before {
  content: "\f77d";
}

.fa-backpack:before {
  content: "\f5d4";
}

.fa-backspace:before {
  content: "\f55a";
}

.fa-backward:before {
  content: "\f04a";
}

.fa-bacon:before {
  content: "\f7e5";
}

.fa-bacteria:before {
  content: "\e059";
}

.fa-bacterium:before {
  content: "\e05a";
}

.fa-badge:before {
  content: "\f335";
}

.fa-badge-check:before {
  content: "\f336";
}

.fa-badge-dollar:before {
  content: "\f645";
}

.fa-badge-percent:before {
  content: "\f646";
}

.fa-badge-sheriff:before {
  content: "\f8a2";
}

.fa-badger-honey:before {
  content: "\f6b4";
}

.fa-bags-shopping:before {
  content: "\f847";
}

.fa-bahai:before {
  content: "\f666";
}

.fa-balance-scale:before {
  content: "\f24e";
}

.fa-balance-scale-left:before {
  content: "\f515";
}

.fa-balance-scale-right:before {
  content: "\f516";
}

.fa-ball-pile:before {
  content: "\f77e";
}

.fa-ballot:before {
  content: "\f732";
}

.fa-ballot-check:before {
  content: "\f733";
}

.fa-ban:before {
  content: "\f05e";
}

.fa-band-aid:before {
  content: "\f462";
}

.fa-bandcamp:before {
  content: "\f2d5";
}

.fa-banjo:before {
  content: "\f8a3";
}

.fa-barcode:before {
  content: "\f02a";
}

.fa-barcode-alt:before {
  content: "\f463";
}

.fa-barcode-read:before {
  content: "\f464";
}

.fa-barcode-scan:before {
  content: "\f465";
}

.fa-bars:before {
  content: "\f0c9";
}

.fa-baseball:before {
  content: "\f432";
}

.fa-baseball-ball:before {
  content: "\f433";
}

.fa-basketball-ball:before {
  content: "\f434";
}

.fa-basketball-hoop:before {
  content: "\f435";
}

.fa-bat:before {
  content: "\f6b5";
}

.fa-bath:before {
  content: "\f2cd";
}

.fa-battery-bolt:before {
  content: "\f376";
}

.fa-battery-empty:before {
  content: "\f244";
}

.fa-battery-full:before {
  content: "\f240";
}

.fa-battery-half:before {
  content: "\f242";
}

.fa-battery-quarter:before {
  content: "\f243";
}

.fa-battery-slash:before {
  content: "\f377";
}

.fa-battery-three-quarters:before {
  content: "\f241";
}

.fa-battle-net:before {
  content: "\f835";
}

.fa-bed:before {
  content: "\f236";
}

.fa-bed-alt:before {
  content: "\f8f7";
}

.fa-bed-bunk:before {
  content: "\f8f8";
}

.fa-bed-empty:before {
  content: "\f8f9";
}

.fa-beer:before {
  content: "\f0fc";
}

.fa-behance:before {
  content: "\f1b4";
}

.fa-behance-square:before {
  content: "\f1b5";
}

.fa-bell:before {
  content: "\f0f3";
}

.fa-bell-exclamation:before {
  content: "\f848";
}

.fa-bell-on:before {
  content: "\f8fa";
}

.fa-bell-plus:before {
  content: "\f849";
}

.fa-bell-school:before {
  content: "\f5d5";
}

.fa-bell-school-slash:before {
  content: "\f5d6";
}

.fa-bell-slash:before {
  content: "\f1f6";
}

.fa-bells:before {
  content: "\f77f";
}

.fa-betamax:before {
  content: "\f8a4";
}

.fa-bezier-curve:before {
  content: "\f55b";
}

.fa-bible:before {
  content: "\f647";
}

.fa-bicycle:before {
  content: "\f206";
}

.fa-biking:before {
  content: "\f84a";
}

.fa-biking-mountain:before {
  content: "\f84b";
}

.fa-bimobject:before {
  content: "\f378";
}

.fa-binoculars:before {
  content: "\f1e5";
}

.fa-biohazard:before {
  content: "\f780";
}

.fa-birthday-cake:before {
  content: "\f1fd";
}

.fa-bitbucket:before {
  content: "\f171";
}

.fa-bitcoin:before {
  content: "\f379";
}

.fa-bity:before {
  content: "\f37a";
}

.fa-black-tie:before {
  content: "\f27e";
}

.fa-blackberry:before {
  content: "\f37b";
}

.fa-blanket:before {
  content: "\f498";
}

.fa-blender:before {
  content: "\f517";
}

.fa-blender-phone:before {
  content: "\f6b6";
}

.fa-blind:before {
  content: "\f29d";
}

.fa-blinds:before {
  content: "\f8fb";
}

.fa-blinds-open:before {
  content: "\f8fc";
}

.fa-blinds-raised:before {
  content: "\f8fd";
}

.fa-blog:before {
  content: "\f781";
}

.fa-blogger:before {
  content: "\f37c";
}

.fa-blogger-b:before {
  content: "\f37d";
}

.fa-bluetooth:before {
  content: "\f293";
}

.fa-bluetooth-b:before {
  content: "\f294";
}

.fa-bold:before {
  content: "\f032";
}

.fa-bolt:before {
  content: "\f0e7";
}

.fa-bomb:before {
  content: "\f1e2";
}

.fa-bone:before {
  content: "\f5d7";
}

.fa-bone-break:before {
  content: "\f5d8";
}

.fa-bong:before {
  content: "\f55c";
}

.fa-book:before {
  content: "\f02d";
}

.fa-book-alt:before {
  content: "\f5d9";
}

.fa-book-dead:before {
  content: "\f6b7";
}

.fa-book-heart:before {
  content: "\f499";
}

.fa-book-medical:before {
  content: "\f7e6";
}

.fa-book-open:before {
  content: "\f518";
}

.fa-book-reader:before {
  content: "\f5da";
}

.fa-book-spells:before {
  content: "\f6b8";
}

.fa-book-user:before {
  content: "\f7e7";
}

.fa-bookmark:before {
  content: "\f02e";
}

.fa-books:before {
  content: "\f5db";
}

.fa-books-medical:before {
  content: "\f7e8";
}

.fa-boombox:before {
  content: "\f8a5";
}

.fa-boot:before {
  content: "\f782";
}

.fa-booth-curtain:before {
  content: "\f734";
}

.fa-bootstrap:before {
  content: "\f836";
}

.fa-border-all:before {
  content: "\f84c";
}

.fa-border-bottom:before {
  content: "\f84d";
}

.fa-border-center-h:before {
  content: "\f89c";
}

.fa-border-center-v:before {
  content: "\f89d";
}

.fa-border-inner:before {
  content: "\f84e";
}

.fa-border-left:before {
  content: "\f84f";
}

.fa-border-none:before {
  content: "\f850";
}

.fa-border-outer:before {
  content: "\f851";
}

.fa-border-right:before {
  content: "\f852";
}

.fa-border-style:before {
  content: "\f853";
}

.fa-border-style-alt:before {
  content: "\f854";
}

.fa-border-top:before {
  content: "\f855";
}

.fa-bow-arrow:before {
  content: "\f6b9";
}

.fa-bowling-ball:before {
  content: "\f436";
}

.fa-bowling-pins:before {
  content: "\f437";
}

.fa-box:before {
  content: "\f466";
}

.fa-box-alt:before {
  content: "\f49a";
}

.fa-box-ballot:before {
  content: "\f735";
}

.fa-box-check:before {
  content: "\f467";
}

.fa-box-fragile:before {
  content: "\f49b";
}

.fa-box-full:before {
  content: "\f49c";
}

.fa-box-heart:before {
  content: "\f49d";
}

.fa-box-open:before {
  content: "\f49e";
}

.fa-box-tissue:before {
  content: "\e05b";
}

.fa-box-up:before {
  content: "\f49f";
}

.fa-box-usd:before {
  content: "\f4a0";
}

.fa-boxes:before {
  content: "\f468";
}

.fa-boxes-alt:before {
  content: "\f4a1";
}

.fa-boxing-glove:before {
  content: "\f438";
}

.fa-brackets:before {
  content: "\f7e9";
}

.fa-brackets-curly:before {
  content: "\f7ea";
}

.fa-braille:before {
  content: "\f2a1";
}

.fa-brain:before {
  content: "\f5dc";
}

.fa-bread-loaf:before {
  content: "\f7eb";
}

.fa-bread-slice:before {
  content: "\f7ec";
}

.fa-briefcase:before {
  content: "\f0b1";
}

.fa-briefcase-medical:before {
  content: "\f469";
}

.fa-bring-forward:before {
  content: "\f856";
}

.fa-bring-front:before {
  content: "\f857";
}

.fa-broadcast-tower:before {
  content: "\f519";
}

.fa-broom:before {
  content: "\f51a";
}

.fa-browser:before {
  content: "\f37e";
}

.fa-brush:before {
  content: "\f55d";
}

.fa-btc:before {
  content: "\f15a";
}

.fa-buffer:before {
  content: "\f837";
}

.fa-bug:before {
  content: "\f188";
}

.fa-building:before {
  content: "\f1ad";
}

.fa-bullhorn:before {
  content: "\f0a1";
}

.fa-bullseye:before {
  content: "\f140";
}

.fa-bullseye-arrow:before {
  content: "\f648";
}

.fa-bullseye-pointer:before {
  content: "\f649";
}

.fa-burger-soda:before {
  content: "\f858";
}

.fa-burn:before {
  content: "\f46a";
}

.fa-buromobelexperte:before {
  content: "\f37f";
}

.fa-burrito:before {
  content: "\f7ed";
}

.fa-bus:before {
  content: "\f207";
}

.fa-bus-alt:before {
  content: "\f55e";
}

.fa-bus-school:before {
  content: "\f5dd";
}

.fa-business-time:before {
  content: "\f64a";
}

.fa-buy-n-large:before {
  content: "\f8a6";
}

.fa-buysellads:before {
  content: "\f20d";
}

.fa-cabinet-filing:before {
  content: "\f64b";
}

.fa-cactus:before {
  content: "\f8a7";
}

.fa-calculator:before {
  content: "\f1ec";
}

.fa-calculator-alt:before {
  content: "\f64c";
}

.fa-calendar:before {
  content: "\f133";
}

.fa-calendar-alt:before {
  content: "\f073";
}

.fa-calendar-check:before {
  content: "\f274";
}

.fa-calendar-day:before {
  content: "\f783";
}

.fa-calendar-edit:before {
  content: "\f333";
}

.fa-calendar-exclamation:before {
  content: "\f334";
}

.fa-calendar-minus:before {
  content: "\f272";
}

.fa-calendar-plus:before {
  content: "\f271";
}

.fa-calendar-star:before {
  content: "\f736";
}

.fa-calendar-times:before {
  content: "\f273";
}

.fa-calendar-week:before {
  content: "\f784";
}

.fa-camcorder:before {
  content: "\f8a8";
}

.fa-camera:before {
  content: "\f030";
}

.fa-camera-alt:before {
  content: "\f332";
}

.fa-camera-home:before {
  content: "\f8fe";
}

.fa-camera-movie:before {
  content: "\f8a9";
}

.fa-camera-polaroid:before {
  content: "\f8aa";
}

.fa-camera-retro:before {
  content: "\f083";
}

.fa-campfire:before {
  content: "\f6ba";
}

.fa-campground:before {
  content: "\f6bb";
}

.fa-canadian-maple-leaf:before {
  content: "\f785";
}

.fa-candle-holder:before {
  content: "\f6bc";
}

.fa-candy-cane:before {
  content: "\f786";
}

.fa-candy-corn:before {
  content: "\f6bd";
}

.fa-cannabis:before {
  content: "\f55f";
}

.fa-capsules:before {
  content: "\f46b";
}

.fa-car:before {
  content: "\f1b9";
}

.fa-car-alt:before {
  content: "\f5de";
}

.fa-car-battery:before {
  content: "\f5df";
}

.fa-car-building:before {
  content: "\f859";
}

.fa-car-bump:before {
  content: "\f5e0";
}

.fa-car-bus:before {
  content: "\f85a";
}

.fa-car-crash:before {
  content: "\f5e1";
}

.fa-car-garage:before {
  content: "\f5e2";
}

.fa-car-mechanic:before {
  content: "\f5e3";
}

.fa-car-side:before {
  content: "\f5e4";
}

.fa-car-tilt:before {
  content: "\f5e5";
}

.fa-car-wash:before {
  content: "\f5e6";
}

.fa-caravan:before {
  content: "\f8ff";
}

.fa-caravan-alt:before {
  content: "\e000";
}

.fa-caret-circle-down:before {
  content: "\f32d";
}

.fa-caret-circle-left:before {
  content: "\f32e";
}

.fa-caret-circle-right:before {
  content: "\f330";
}

.fa-caret-circle-up:before {
  content: "\f331";
}

.fa-caret-down:before {
  content: "\f0d7";
}

.fa-caret-left:before {
  content: "\f0d9";
}

.fa-caret-right:before {
  content: "\f0da";
}

.fa-caret-square-down:before {
  content: "\f150";
}

.fa-caret-square-left:before {
  content: "\f191";
}

.fa-caret-square-right:before {
  content: "\f152";
}

.fa-caret-square-up:before {
  content: "\f151";
}

.fa-caret-up:before {
  content: "\f0d8";
}

.fa-carrot:before {
  content: "\f787";
}

.fa-cars:before {
  content: "\f85b";
}

.fa-cart-arrow-down:before {
  content: "\f218";
}

.fa-cart-plus:before {
  content: "\f217";
}

.fa-cash-register:before {
  content: "\f788";
}

.fa-cassette-tape:before {
  content: "\f8ab";
}

.fa-cat:before {
  content: "\f6be";
}

.fa-cat-space:before {
  content: "\e001";
}

.fa-cauldron:before {
  content: "\f6bf";
}

.fa-cc-amazon-pay:before {
  content: "\f42d";
}

.fa-cc-amex:before {
  content: "\f1f3";
}

.fa-cc-apple-pay:before {
  content: "\f416";
}

.fa-cc-diners-club:before {
  content: "\f24c";
}

.fa-cc-discover:before {
  content: "\f1f2";
}

.fa-cc-jcb:before {
  content: "\f24b";
}

.fa-cc-mastercard:before {
  content: "\f1f1";
}

.fa-cc-paypal:before {
  content: "\f1f4";
}

.fa-cc-stripe:before {
  content: "\f1f5";
}

.fa-cc-visa:before {
  content: "\f1f0";
}

.fa-cctv:before {
  content: "\f8ac";
}

.fa-centercode:before {
  content: "\f380";
}

.fa-centos:before {
  content: "\f789";
}

.fa-certificate:before {
  content: "\f0a3";
}

.fa-chair:before {
  content: "\f6c0";
}

.fa-chair-office:before {
  content: "\f6c1";
}

.fa-chalkboard:before {
  content: "\f51b";
}

.fa-chalkboard-teacher:before {
  content: "\f51c";
}

.fa-charging-station:before {
  content: "\f5e7";
}

.fa-chart-area:before {
  content: "\f1fe";
}

.fa-chart-bar:before {
  content: "\f080";
}

.fa-chart-line:before {
  content: "\f201";
}

.fa-chart-line-down:before {
  content: "\f64d";
}

.fa-chart-network:before {
  content: "\f78a";
}

.fa-chart-pie:before {
  content: "\f200";
}

.fa-chart-pie-alt:before {
  content: "\f64e";
}

.fa-chart-scatter:before {
  content: "\f7ee";
}

.fa-check:before {
  content: "\f00c";
}

.fa-check-circle:before {
  content: "\f058";
}

.fa-check-double:before {
  content: "\f560";
}

.fa-check-square:before {
  content: "\f14a";
}

.fa-cheese:before {
  content: "\f7ef";
}

.fa-cheese-swiss:before {
  content: "\f7f0";
}

.fa-cheeseburger:before {
  content: "\f7f1";
}

.fa-chess:before {
  content: "\f439";
}

.fa-chess-bishop:before {
  content: "\f43a";
}

.fa-chess-bishop-alt:before {
  content: "\f43b";
}

.fa-chess-board:before {
  content: "\f43c";
}

.fa-chess-clock:before {
  content: "\f43d";
}

.fa-chess-clock-alt:before {
  content: "\f43e";
}

.fa-chess-king:before {
  content: "\f43f";
}

.fa-chess-king-alt:before {
  content: "\f440";
}

.fa-chess-knight:before {
  content: "\f441";
}

.fa-chess-knight-alt:before {
  content: "\f442";
}

.fa-chess-pawn:before {
  content: "\f443";
}

.fa-chess-pawn-alt:before {
  content: "\f444";
}

.fa-chess-queen:before {
  content: "\f445";
}

.fa-chess-queen-alt:before {
  content: "\f446";
}

.fa-chess-rook:before {
  content: "\f447";
}

.fa-chess-rook-alt:before {
  content: "\f448";
}

.fa-chevron-circle-down:before {
  content: "\f13a";
}

.fa-chevron-circle-left:before {
  content: "\f137";
}

.fa-chevron-circle-right:before {
  content: "\f138";
}

.fa-chevron-circle-up:before {
  content: "\f139";
}

.fa-chevron-double-down:before {
  content: "\f322";
}

.fa-chevron-double-left:before {
  content: "\f323";
}

.fa-chevron-double-right:before {
  content: "\f324";
}

.fa-chevron-double-up:before {
  content: "\f325";
}

.fa-chevron-down:before {
  content: "\f078";
}

.fa-chevron-left:before {
  content: "\f053";
}

.fa-chevron-right:before {
  content: "\f054";
}

.fa-chevron-square-down:before {
  content: "\f329";
}

.fa-chevron-square-left:before {
  content: "\f32a";
}

.fa-chevron-square-right:before {
  content: "\f32b";
}

.fa-chevron-square-up:before {
  content: "\f32c";
}

.fa-chevron-up:before {
  content: "\f077";
}

.fa-child:before {
  content: "\f1ae";
}

.fa-chimney:before {
  content: "\f78b";
}

.fa-chrome:before {
  content: "\f268";
}

.fa-chromecast:before {
  content: "\f838";
}

.fa-church:before {
  content: "\f51d";
}

.fa-circle:before {
  content: "\f111";
}

.fa-circle-notch:before {
  content: "\f1ce";
}

.fa-city:before {
  content: "\f64f";
}

.fa-clarinet:before {
  content: "\f8ad";
}

.fa-claw-marks:before {
  content: "\f6c2";
}

.fa-clinic-medical:before {
  content: "\f7f2";
}

.fa-clipboard:before {
  content: "\f328";
}

.fa-clipboard-check:before {
  content: "\f46c";
}

.fa-clipboard-list:before {
  content: "\f46d";
}

.fa-clipboard-list-check:before {
  content: "\f737";
}

.fa-clipboard-prescription:before {
  content: "\f5e8";
}

.fa-clipboard-user:before {
  content: "\f7f3";
}

.fa-clock:before {
  content: "\f017";
}

.fa-clone:before {
  content: "\f24d";
}

.fa-closed-captioning:before {
  content: "\f20a";
}

.fa-cloud:before {
  content: "\f0c2";
}

.fa-cloud-download:before {
  content: "\f0ed";
}

.fa-cloud-download-alt:before {
  content: "\f381";
}

.fa-cloud-drizzle:before {
  content: "\f738";
}

.fa-cloud-hail:before {
  content: "\f739";
}

.fa-cloud-hail-mixed:before {
  content: "\f73a";
}

.fa-cloud-meatball:before {
  content: "\f73b";
}

.fa-cloud-moon:before {
  content: "\f6c3";
}

.fa-cloud-moon-rain:before {
  content: "\f73c";
}

.fa-cloud-music:before {
  content: "\f8ae";
}

.fa-cloud-rain:before {
  content: "\f73d";
}

.fa-cloud-rainbow:before {
  content: "\f73e";
}

.fa-cloud-showers:before {
  content: "\f73f";
}

.fa-cloud-showers-heavy:before {
  content: "\f740";
}

.fa-cloud-sleet:before {
  content: "\f741";
}

.fa-cloud-snow:before {
  content: "\f742";
}

.fa-cloud-sun:before {
  content: "\f6c4";
}

.fa-cloud-sun-rain:before {
  content: "\f743";
}

.fa-cloud-upload:before {
  content: "\f0ee";
}

.fa-cloud-upload-alt:before {
  content: "\f382";
}

.fa-cloudflare:before {
  content: "\e07d";
}

.fa-clouds:before {
  content: "\f744";
}

.fa-clouds-moon:before {
  content: "\f745";
}

.fa-clouds-sun:before {
  content: "\f746";
}

.fa-cloudscale:before {
  content: "\f383";
}

.fa-cloudsmith:before {
  content: "\f384";
}

.fa-cloudversify:before {
  content: "\f385";
}

.fa-club:before {
  content: "\f327";
}

.fa-cocktail:before {
  content: "\f561";
}

.fa-code:before {
  content: "\f121";
}

.fa-code-branch:before {
  content: "\f126";
}

.fa-code-commit:before {
  content: "\f386";
}

.fa-code-merge:before {
  content: "\f387";
}

.fa-codepen:before {
  content: "\f1cb";
}

.fa-codiepie:before {
  content: "\f284";
}

.fa-coffee:before {
  content: "\f0f4";
}

.fa-coffee-pot:before {
  content: "\e002";
}

.fa-coffee-togo:before {
  content: "\f6c5";
}

.fa-coffin:before {
  content: "\f6c6";
}

.fa-coffin-cross:before {
  content: "\e051";
}

.fa-cog:before {
  content: "\f013";
}

.fa-cogs:before {
  content: "\f085";
}

.fa-coin:before {
  content: "\f85c";
}

.fa-coins:before {
  content: "\f51e";
}

.fa-columns:before {
  content: "\f0db";
}

.fa-comet:before {
  content: "\e003";
}

.fa-comment:before {
  content: "\f075";
}

.fa-comment-alt:before {
  content: "\f27a";
}

.fa-comment-alt-check:before {
  content: "\f4a2";
}

.fa-comment-alt-dollar:before {
  content: "\f650";
}

.fa-comment-alt-dots:before {
  content: "\f4a3";
}

.fa-comment-alt-edit:before {
  content: "\f4a4";
}

.fa-comment-alt-exclamation:before {
  content: "\f4a5";
}

.fa-comment-alt-lines:before {
  content: "\f4a6";
}

.fa-comment-alt-medical:before {
  content: "\f7f4";
}

.fa-comment-alt-minus:before {
  content: "\f4a7";
}

.fa-comment-alt-music:before {
  content: "\f8af";
}

.fa-comment-alt-plus:before {
  content: "\f4a8";
}

.fa-comment-alt-slash:before {
  content: "\f4a9";
}

.fa-comment-alt-smile:before {
  content: "\f4aa";
}

.fa-comment-alt-times:before {
  content: "\f4ab";
}

.fa-comment-check:before {
  content: "\f4ac";
}

.fa-comment-dollar:before {
  content: "\f651";
}

.fa-comment-dots:before {
  content: "\f4ad";
}

.fa-comment-edit:before {
  content: "\f4ae";
}

.fa-comment-exclamation:before {
  content: "\f4af";
}

.fa-comment-lines:before {
  content: "\f4b0";
}

.fa-comment-medical:before {
  content: "\f7f5";
}

.fa-comment-minus:before {
  content: "\f4b1";
}

.fa-comment-music:before {
  content: "\f8b0";
}

.fa-comment-plus:before {
  content: "\f4b2";
}

.fa-comment-slash:before {
  content: "\f4b3";
}

.fa-comment-smile:before {
  content: "\f4b4";
}

.fa-comment-times:before {
  content: "\f4b5";
}

.fa-comments:before {
  content: "\f086";
}

.fa-comments-alt:before {
  content: "\f4b6";
}

.fa-comments-alt-dollar:before {
  content: "\f652";
}

.fa-comments-dollar:before {
  content: "\f653";
}

.fa-compact-disc:before {
  content: "\f51f";
}

.fa-compass:before {
  content: "\f14e";
}

.fa-compass-slash:before {
  content: "\f5e9";
}

.fa-compress:before {
  content: "\f066";
}

.fa-compress-alt:before {
  content: "\f422";
}

.fa-compress-arrows-alt:before {
  content: "\f78c";
}

.fa-compress-wide:before {
  content: "\f326";
}

.fa-computer-classic:before {
  content: "\f8b1";
}

.fa-computer-speaker:before {
  content: "\f8b2";
}

.fa-concierge-bell:before {
  content: "\f562";
}

.fa-confluence:before {
  content: "\f78d";
}

.fa-connectdevelop:before {
  content: "\f20e";
}

.fa-construction:before {
  content: "\f85d";
}

.fa-container-storage:before {
  content: "\f4b7";
}

.fa-contao:before {
  content: "\f26d";
}

.fa-conveyor-belt:before {
  content: "\f46e";
}

.fa-conveyor-belt-alt:before {
  content: "\f46f";
}

.fa-cookie:before {
  content: "\f563";
}

.fa-cookie-bite:before {
  content: "\f564";
}

.fa-copy:before {
  content: "\f0c5";
}

.fa-copyright:before {
  content: "\f1f9";
}

.fa-corn:before {
  content: "\f6c7";
}

.fa-cotton-bureau:before {
  content: "\f89e";
}

.fa-couch:before {
  content: "\f4b8";
}

.fa-cow:before {
  content: "\f6c8";
}

.fa-cowbell:before {
  content: "\f8b3";
}

.fa-cowbell-more:before {
  content: "\f8b4";
}

.fa-cpanel:before {
  content: "\f388";
}

.fa-creative-commons:before {
  content: "\f25e";
}

.fa-creative-commons-by:before {
  content: "\f4e7";
}

.fa-creative-commons-nc:before {
  content: "\f4e8";
}

.fa-creative-commons-nc-eu:before {
  content: "\f4e9";
}

.fa-creative-commons-nc-jp:before {
  content: "\f4ea";
}

.fa-creative-commons-nd:before {
  content: "\f4eb";
}

.fa-creative-commons-pd:before {
  content: "\f4ec";
}

.fa-creative-commons-pd-alt:before {
  content: "\f4ed";
}

.fa-creative-commons-remix:before {
  content: "\f4ee";
}

.fa-creative-commons-sa:before {
  content: "\f4ef";
}

.fa-creative-commons-sampling:before {
  content: "\f4f0";
}

.fa-creative-commons-sampling-plus:before {
  content: "\f4f1";
}

.fa-creative-commons-share:before {
  content: "\f4f2";
}

.fa-creative-commons-zero:before {
  content: "\f4f3";
}

.fa-credit-card:before {
  content: "\f09d";
}

.fa-credit-card-blank:before {
  content: "\f389";
}

.fa-credit-card-front:before {
  content: "\f38a";
}

.fa-cricket:before {
  content: "\f449";
}

.fa-critical-role:before {
  content: "\f6c9";
}

.fa-croissant:before {
  content: "\f7f6";
}

.fa-crop:before {
  content: "\f125";
}

.fa-crop-alt:before {
  content: "\f565";
}

.fa-cross:before {
  content: "\f654";
}

.fa-crosshairs:before {
  content: "\f05b";
}

.fa-crow:before {
  content: "\f520";
}

.fa-crown:before {
  content: "\f521";
}

.fa-crutch:before {
  content: "\f7f7";
}

.fa-crutches:before {
  content: "\f7f8";
}

.fa-css3:before {
  content: "\f13c";
}

.fa-css3-alt:before {
  content: "\f38b";
}

.fa-cube:before {
  content: "\f1b2";
}

.fa-cubes:before {
  content: "\f1b3";
}

.fa-curling:before {
  content: "\f44a";
}

.fa-cut:before {
  content: "\f0c4";
}

.fa-cuttlefish:before {
  content: "\f38c";
}

.fa-d-and-d:before {
  content: "\f38d";
}

.fa-d-and-d-beyond:before {
  content: "\f6ca";
}

.fa-dagger:before {
  content: "\f6cb";
}

.fa-dailymotion:before {
  content: "\e052";
}

.fa-dashcube:before {
  content: "\f210";
}

.fa-database:before {
  content: "\f1c0";
}

.fa-deaf:before {
  content: "\f2a4";
}

.fa-debug:before {
  content: "\f7f9";
}

.fa-deer:before {
  content: "\f78e";
}

.fa-deer-rudolph:before {
  content: "\f78f";
}

.fa-deezer:before {
  content: "\e077";
}

.fa-delicious:before {
  content: "\f1a5";
}

.fa-democrat:before {
  content: "\f747";
}

.fa-deploydog:before {
  content: "\f38e";
}

.fa-deskpro:before {
  content: "\f38f";
}

.fa-desktop:before {
  content: "\f108";
}

.fa-desktop-alt:before {
  content: "\f390";
}

.fa-dev:before {
  content: "\f6cc";
}

.fa-deviantart:before {
  content: "\f1bd";
}

.fa-dewpoint:before {
  content: "\f748";
}

.fa-dharmachakra:before {
  content: "\f655";
}

.fa-dhl:before {
  content: "\f790";
}

.fa-diagnoses:before {
  content: "\f470";
}

.fa-diamond:before {
  content: "\f219";
}

.fa-diaspora:before {
  content: "\f791";
}

.fa-dice:before {
  content: "\f522";
}

.fa-dice-d10:before {
  content: "\f6cd";
}

.fa-dice-d12:before {
  content: "\f6ce";
}

.fa-dice-d20:before {
  content: "\f6cf";
}

.fa-dice-d4:before {
  content: "\f6d0";
}

.fa-dice-d6:before {
  content: "\f6d1";
}

.fa-dice-d8:before {
  content: "\f6d2";
}

.fa-dice-five:before {
  content: "\f523";
}

.fa-dice-four:before {
  content: "\f524";
}

.fa-dice-one:before {
  content: "\f525";
}

.fa-dice-six:before {
  content: "\f526";
}

.fa-dice-three:before {
  content: "\f527";
}

.fa-dice-two:before {
  content: "\f528";
}

.fa-digg:before {
  content: "\f1a6";
}

.fa-digging:before {
  content: "\f85e";
}

.fa-digital-ocean:before {
  content: "\f391";
}

.fa-digital-tachograph:before {
  content: "\f566";
}

.fa-diploma:before {
  content: "\f5ea";
}

.fa-directions:before {
  content: "\f5eb";
}

.fa-disc-drive:before {
  content: "\f8b5";
}

.fa-discord:before {
  content: "\f392";
}

.fa-discourse:before {
  content: "\f393";
}

.fa-disease:before {
  content: "\f7fa";
}

.fa-divide:before {
  content: "\f529";
}

.fa-dizzy:before {
  content: "\f567";
}

.fa-dna:before {
  content: "\f471";
}

.fa-do-not-enter:before {
  content: "\f5ec";
}

.fa-dochub:before {
  content: "\f394";
}

.fa-docker:before {
  content: "\f395";
}

.fa-dog:before {
  content: "\f6d3";
}

.fa-dog-leashed:before {
  content: "\f6d4";
}

.fa-dollar-sign:before {
  content: "\f155";
}

.fa-dolly:before {
  content: "\f472";
}

.fa-dolly-empty:before {
  content: "\f473";
}

.fa-dolly-flatbed:before {
  content: "\f474";
}

.fa-dolly-flatbed-alt:before {
  content: "\f475";
}

.fa-dolly-flatbed-empty:before {
  content: "\f476";
}

.fa-donate:before {
  content: "\f4b9";
}

.fa-door-closed:before {
  content: "\f52a";
}

.fa-door-open:before {
  content: "\f52b";
}

.fa-dot-circle:before {
  content: "\f192";
}

.fa-dove:before {
  content: "\f4ba";
}

.fa-download:before {
  content: "\f019";
}

.fa-draft2digital:before {
  content: "\f396";
}

.fa-drafting-compass:before {
  content: "\f568";
}

.fa-dragon:before {
  content: "\f6d5";
}

.fa-draw-circle:before {
  content: "\f5ed";
}

.fa-draw-polygon:before {
  content: "\f5ee";
}

.fa-draw-square:before {
  content: "\f5ef";
}

.fa-dreidel:before {
  content: "\f792";
}

.fa-dribbble:before {
  content: "\f17d";
}

.fa-dribbble-square:before {
  content: "\f397";
}

.fa-drone:before {
  content: "\f85f";
}

.fa-drone-alt:before {
  content: "\f860";
}

.fa-dropbox:before {
  content: "\f16b";
}

.fa-drum:before {
  content: "\f569";
}

.fa-drum-steelpan:before {
  content: "\f56a";
}

.fa-drumstick:before {
  content: "\f6d6";
}

.fa-drumstick-bite:before {
  content: "\f6d7";
}

.fa-drupal:before {
  content: "\f1a9";
}

.fa-dryer:before {
  content: "\f861";
}

.fa-dryer-alt:before {
  content: "\f862";
}

.fa-duck:before {
  content: "\f6d8";
}

.fa-dumbbell:before {
  content: "\f44b";
}

.fa-dumpster:before {
  content: "\f793";
}

.fa-dumpster-fire:before {
  content: "\f794";
}

.fa-dungeon:before {
  content: "\f6d9";
}

.fa-dyalog:before {
  content: "\f399";
}

.fa-ear:before {
  content: "\f5f0";
}

.fa-ear-muffs:before {
  content: "\f795";
}

.fa-earlybirds:before {
  content: "\f39a";
}

.fa-ebay:before {
  content: "\f4f4";
}

.fa-eclipse:before {
  content: "\f749";
}

.fa-eclipse-alt:before {
  content: "\f74a";
}

.fa-edge:before {
  content: "\f282";
}

.fa-edge-legacy:before {
  content: "\e078";
}

.fa-edit:before {
  content: "\f044";
}

.fa-egg:before {
  content: "\f7fb";
}

.fa-egg-fried:before {
  content: "\f7fc";
}

.fa-eject:before {
  content: "\f052";
}

.fa-elementor:before {
  content: "\f430";
}

.fa-elephant:before {
  content: "\f6da";
}

.fa-ellipsis-h:before {
  content: "\f141";
}

.fa-ellipsis-h-alt:before {
  content: "\f39b";
}

.fa-ellipsis-v:before {
  content: "\f142";
}

.fa-ellipsis-v-alt:before {
  content: "\f39c";
}

.fa-ello:before {
  content: "\f5f1";
}

.fa-ember:before {
  content: "\f423";
}

.fa-empire:before {
  content: "\f1d1";
}

.fa-empty-set:before {
  content: "\f656";
}

.fa-engine-warning:before {
  content: "\f5f2";
}

.fa-envelope:before {
  content: "\f0e0";
}

.fa-envelope-open:before {
  content: "\f2b6";
}

.fa-envelope-open-dollar:before {
  content: "\f657";
}

.fa-envelope-open-text:before {
  content: "\f658";
}

.fa-envelope-square:before {
  content: "\f199";
}

.fa-envira:before {
  content: "\f299";
}

.fa-equals:before {
  content: "\f52c";
}

.fa-eraser:before {
  content: "\f12d";
}

.fa-erlang:before {
  content: "\f39d";
}

.fa-ethereum:before {
  content: "\f42e";
}

.fa-ethernet:before {
  content: "\f796";
}

.fa-etsy:before {
  content: "\f2d7";
}

.fa-euro-sign:before {
  content: "\f153";
}

.fa-evernote:before {
  content: "\f839";
}

.fa-exchange:before {
  content: "\f0ec";
}

.fa-exchange-alt:before {
  content: "\f362";
}

.fa-exclamation:before {
  content: "\f12a";
}

.fa-exclamation-circle:before {
  content: "\f06a";
}

.fa-exclamation-square:before {
  content: "\f321";
}

.fa-exclamation-triangle:before {
  content: "\f071";
}

.fa-expand:before {
  content: "\f065";
}

.fa-expand-alt:before {
  content: "\f424";
}

.fa-expand-arrows:before {
  content: "\f31d";
}

.fa-expand-arrows-alt:before {
  content: "\f31e";
}

.fa-expand-wide:before {
  content: "\f320";
}

.fa-expeditedssl:before {
  content: "\f23e";
}

.fa-external-link:before {
  content: "\f08e";
}

.fa-external-link-alt:before {
  content: "\f35d";
}

.fa-external-link-square:before {
  content: "\f14c";
}

.fa-external-link-square-alt:before {
  content: "\f360";
}

.fa-eye:before {
  content: "\f06e";
}

.fa-eye-dropper:before {
  content: "\f1fb";
}

.fa-eye-evil:before {
  content: "\f6db";
}

.fa-eye-slash:before {
  content: "\f070";
}

.fa-facebook:before {
  content: "\f09a";
}

.fa-facebook-f:before {
  content: "\f39e";
}

.fa-facebook-messenger:before {
  content: "\f39f";
}

.fa-facebook-square:before {
  content: "\f082";
}

.fa-fan:before {
  content: "\f863";
}

.fa-fan-table:before {
  content: "\e004";
}

.fa-fantasy-flight-games:before {
  content: "\f6dc";
}

.fa-farm:before {
  content: "\f864";
}

.fa-fast-backward:before {
  content: "\f049";
}

.fa-fast-forward:before {
  content: "\f050";
}

.fa-faucet:before {
  content: "\e005";
}

.fa-faucet-drip:before {
  content: "\e006";
}

.fa-fax:before {
  content: "\f1ac";
}

.fa-feather:before {
  content: "\f52d";
}

.fa-feather-alt:before {
  content: "\f56b";
}

.fa-fedex:before {
  content: "\f797";
}

.fa-fedora:before {
  content: "\f798";
}

.fa-female:before {
  content: "\f182";
}

.fa-field-hockey:before {
  content: "\f44c";
}

.fa-fighter-jet:before {
  content: "\f0fb";
}

.fa-figma:before {
  content: "\f799";
}

.fa-file:before {
  content: "\f15b";
}

.fa-file-alt:before {
  content: "\f15c";
}

.fa-file-archive:before {
  content: "\f1c6";
}

.fa-file-audio:before {
  content: "\f1c7";
}

.fa-file-certificate:before {
  content: "\f5f3";
}

.fa-file-chart-line:before {
  content: "\f659";
}

.fa-file-chart-pie:before {
  content: "\f65a";
}

.fa-file-check:before {
  content: "\f316";
}

.fa-file-code:before {
  content: "\f1c9";
}

.fa-file-contract:before {
  content: "\f56c";
}

.fa-file-csv:before {
  content: "\f6dd";
}

.fa-file-download:before {
  content: "\f56d";
}

.fa-file-edit:before {
  content: "\f31c";
}

.fa-file-excel:before {
  content: "\f1c3";
}

.fa-file-exclamation:before {
  content: "\f31a";
}

.fa-file-export:before {
  content: "\f56e";
}

.fa-file-image:before {
  content: "\f1c5";
}

.fa-file-import:before {
  content: "\f56f";
}

.fa-file-invoice:before {
  content: "\f570";
}

.fa-file-invoice-dollar:before {
  content: "\f571";
}

.fa-file-medical:before {
  content: "\f477";
}

.fa-file-medical-alt:before {
  content: "\f478";
}

.fa-file-minus:before {
  content: "\f318";
}

.fa-file-music:before {
  content: "\f8b6";
}

.fa-file-pdf:before {
  content: "\f1c1";
}

.fa-file-plus:before {
  content: "\f319";
}

.fa-file-powerpoint:before {
  content: "\f1c4";
}

.fa-file-prescription:before {
  content: "\f572";
}

.fa-file-search:before {
  content: "\f865";
}

.fa-file-signature:before {
  content: "\f573";
}

.fa-file-spreadsheet:before {
  content: "\f65b";
}

.fa-file-times:before {
  content: "\f317";
}

.fa-file-upload:before {
  content: "\f574";
}

.fa-file-user:before {
  content: "\f65c";
}

.fa-file-video:before {
  content: "\f1c8";
}

.fa-file-word:before {
  content: "\f1c2";
}

.fa-files-medical:before {
  content: "\f7fd";
}

.fa-fill:before {
  content: "\f575";
}

.fa-fill-drip:before {
  content: "\f576";
}

.fa-film:before {
  content: "\f008";
}

.fa-film-alt:before {
  content: "\f3a0";
}

.fa-film-canister:before {
  content: "\f8b7";
}

.fa-filter:before {
  content: "\f0b0";
}

.fa-fingerprint:before {
  content: "\f577";
}

.fa-fire:before {
  content: "\f06d";
}

.fa-fire-alt:before {
  content: "\f7e4";
}

.fa-fire-extinguisher:before {
  content: "\f134";
}

.fa-fire-smoke:before {
  content: "\f74b";
}

.fa-firefox:before {
  content: "\f269";
}

.fa-firefox-browser:before {
  content: "\e007";
}

.fa-fireplace:before {
  content: "\f79a";
}

.fa-first-aid:before {
  content: "\f479";
}

.fa-first-order:before {
  content: "\f2b0";
}

.fa-first-order-alt:before {
  content: "\f50a";
}

.fa-firstdraft:before {
  content: "\f3a1";
}

.fa-fish:before {
  content: "\f578";
}

.fa-fish-cooked:before {
  content: "\f7fe";
}

.fa-fist-raised:before {
  content: "\f6de";
}

.fa-flag:before {
  content: "\f024";
}

.fa-flag-alt:before {
  content: "\f74c";
}

.fa-flag-checkered:before {
  content: "\f11e";
}

.fa-flag-usa:before {
  content: "\f74d";
}

.fa-flame:before {
  content: "\f6df";
}

.fa-flashlight:before {
  content: "\f8b8";
}

.fa-flask:before {
  content: "\f0c3";
}

.fa-flask-poison:before {
  content: "\f6e0";
}

.fa-flask-potion:before {
  content: "\f6e1";
}

.fa-flickr:before {
  content: "\f16e";
}

.fa-flipboard:before {
  content: "\f44d";
}

.fa-flower:before {
  content: "\f7ff";
}

.fa-flower-daffodil:before {
  content: "\f800";
}

.fa-flower-tulip:before {
  content: "\f801";
}

.fa-flushed:before {
  content: "\f579";
}

.fa-flute:before {
  content: "\f8b9";
}

.fa-flux-capacitor:before {
  content: "\f8ba";
}

.fa-fly:before {
  content: "\f417";
}

.fa-fog:before {
  content: "\f74e";
}

.fa-folder:before {
  content: "\f07b";
}

.fa-folder-download:before {
  content: "\e053";
}

.fa-folder-minus:before {
  content: "\f65d";
}

.fa-folder-open:before {
  content: "\f07c";
}

.fa-folder-plus:before {
  content: "\f65e";
}

.fa-folder-times:before {
  content: "\f65f";
}

.fa-folder-tree:before {
  content: "\f802";
}

.fa-folder-upload:before {
  content: "\e054";
}

.fa-folders:before {
  content: "\f660";
}

.fa-font:before {
  content: "\f031";
}

.fa-font-awesome:before {
  content: "\f2b4";
}

.fa-font-awesome-alt:before {
  content: "\f35c";
}

.fa-font-awesome-flag:before {
  content: "\f425";
}

.fa-font-awesome-logo-full:before {
  content: "\f4e6";
}

.fa-font-case:before {
  content: "\f866";
}

.fa-fonticons:before {
  content: "\f280";
}

.fa-fonticons-fi:before {
  content: "\f3a2";
}

.fa-football-ball:before {
  content: "\f44e";
}

.fa-football-helmet:before {
  content: "\f44f";
}

.fa-forklift:before {
  content: "\f47a";
}

.fa-fort-awesome:before {
  content: "\f286";
}

.fa-fort-awesome-alt:before {
  content: "\f3a3";
}

.fa-forumbee:before {
  content: "\f211";
}

.fa-forward:before {
  content: "\f04e";
}

.fa-foursquare:before {
  content: "\f180";
}

.fa-fragile:before {
  content: "\f4bb";
}

.fa-free-code-camp:before {
  content: "\f2c5";
}

.fa-freebsd:before {
  content: "\f3a4";
}

.fa-french-fries:before {
  content: "\f803";
}

.fa-frog:before {
  content: "\f52e";
}

.fa-frosty-head:before {
  content: "\f79b";
}

.fa-frown:before {
  content: "\f119";
}

.fa-frown-open:before {
  content: "\f57a";
}

.fa-fulcrum:before {
  content: "\f50b";
}

.fa-function:before {
  content: "\f661";
}

.fa-funnel-dollar:before {
  content: "\f662";
}

.fa-futbol:before {
  content: "\f1e3";
}

.fa-galactic-republic:before {
  content: "\f50c";
}

.fa-galactic-senate:before {
  content: "\f50d";
}

.fa-galaxy:before {
  content: "\e008";
}

.fa-game-board:before {
  content: "\f867";
}

.fa-game-board-alt:before {
  content: "\f868";
}

.fa-game-console-handheld:before {
  content: "\f8bb";
}

.fa-gamepad:before {
  content: "\f11b";
}

.fa-gamepad-alt:before {
  content: "\f8bc";
}

.fa-garage:before {
  content: "\e009";
}

.fa-garage-car:before {
  content: "\e00a";
}

.fa-garage-open:before {
  content: "\e00b";
}

.fa-gas-pump:before {
  content: "\f52f";
}

.fa-gas-pump-slash:before {
  content: "\f5f4";
}

.fa-gavel:before {
  content: "\f0e3";
}

.fa-gem:before {
  content: "\f3a5";
}

.fa-genderless:before {
  content: "\f22d";
}

.fa-get-pocket:before {
  content: "\f265";
}

.fa-gg:before {
  content: "\f260";
}

.fa-gg-circle:before {
  content: "\f261";
}

.fa-ghost:before {
  content: "\f6e2";
}

.fa-gift:before {
  content: "\f06b";
}

.fa-gift-card:before {
  content: "\f663";
}

.fa-gifts:before {
  content: "\f79c";
}

.fa-gingerbread-man:before {
  content: "\f79d";
}

.fa-git:before {
  content: "\f1d3";
}

.fa-git-alt:before {
  content: "\f841";
}

.fa-git-square:before {
  content: "\f1d2";
}

.fa-github:before {
  content: "\f09b";
}

.fa-github-alt:before {
  content: "\f113";
}

.fa-github-square:before {
  content: "\f092";
}

.fa-gitkraken:before {
  content: "\f3a6";
}

.fa-gitlab:before {
  content: "\f296";
}

.fa-gitter:before {
  content: "\f426";
}

.fa-glass:before {
  content: "\f804";
}

.fa-glass-champagne:before {
  content: "\f79e";
}

.fa-glass-cheers:before {
  content: "\f79f";
}

.fa-glass-citrus:before {
  content: "\f869";
}

.fa-glass-martini:before {
  content: "\f000";
}

.fa-glass-martini-alt:before {
  content: "\f57b";
}

.fa-glass-whiskey:before {
  content: "\f7a0";
}

.fa-glass-whiskey-rocks:before {
  content: "\f7a1";
}

.fa-glasses:before {
  content: "\f530";
}

.fa-glasses-alt:before {
  content: "\f5f5";
}

.fa-glide:before {
  content: "\f2a5";
}

.fa-glide-g:before {
  content: "\f2a6";
}

.fa-globe:before {
  content: "\f0ac";
}

.fa-globe-africa:before {
  content: "\f57c";
}

.fa-globe-americas:before {
  content: "\f57d";
}

.fa-globe-asia:before {
  content: "\f57e";
}

.fa-globe-europe:before {
  content: "\f7a2";
}

.fa-globe-snow:before {
  content: "\f7a3";
}

.fa-globe-stand:before {
  content: "\f5f6";
}

.fa-gofore:before {
  content: "\f3a7";
}

.fa-golf-ball:before {
  content: "\f450";
}

.fa-golf-club:before {
  content: "\f451";
}

.fa-goodreads:before {
  content: "\f3a8";
}

.fa-goodreads-g:before {
  content: "\f3a9";
}

.fa-google:before {
  content: "\f1a0";
}

.fa-google-drive:before {
  content: "\f3aa";
}

.fa-google-pay:before {
  content: "\e079";
}

.fa-google-play:before {
  content: "\f3ab";
}

.fa-google-plus:before {
  content: "\f2b3";
}

.fa-google-plus-g:before {
  content: "\f0d5";
}

.fa-google-plus-square:before {
  content: "\f0d4";
}

.fa-google-wallet:before {
  content: "\f1ee";
}

.fa-gopuram:before {
  content: "\f664";
}

.fa-graduation-cap:before {
  content: "\f19d";
}

.fa-gramophone:before {
  content: "\f8bd";
}

.fa-gratipay:before {
  content: "\f184";
}

.fa-grav:before {
  content: "\f2d6";
}

.fa-greater-than:before {
  content: "\f531";
}

.fa-greater-than-equal:before {
  content: "\f532";
}

.fa-grimace:before {
  content: "\f57f";
}

.fa-grin:before {
  content: "\f580";
}

.fa-grin-alt:before {
  content: "\f581";
}

.fa-grin-beam:before {
  content: "\f582";
}

.fa-grin-beam-sweat:before {
  content: "\f583";
}

.fa-grin-hearts:before {
  content: "\f584";
}

.fa-grin-squint:before {
  content: "\f585";
}

.fa-grin-squint-tears:before {
  content: "\f586";
}

.fa-grin-stars:before {
  content: "\f587";
}

.fa-grin-tears:before {
  content: "\f588";
}

.fa-grin-tongue:before {
  content: "\f589";
}

.fa-grin-tongue-squint:before {
  content: "\f58a";
}

.fa-grin-tongue-wink:before {
  content: "\f58b";
}

.fa-grin-wink:before {
  content: "\f58c";
}

.fa-grip-horizontal:before {
  content: "\f58d";
}

.fa-grip-lines:before {
  content: "\f7a4";
}

.fa-grip-lines-vertical:before {
  content: "\f7a5";
}

.fa-grip-vertical:before {
  content: "\f58e";
}

.fa-gripfire:before {
  content: "\f3ac";
}

.fa-grunt:before {
  content: "\f3ad";
}

.fa-guilded:before {
  content: "\e07e";
}

.fa-guitar:before {
  content: "\f7a6";
}

.fa-guitar-electric:before {
  content: "\f8be";
}

.fa-guitars:before {
  content: "\f8bf";
}

.fa-gulp:before {
  content: "\f3ae";
}

.fa-h-square:before {
  content: "\f0fd";
}

.fa-h1:before {
  content: "\f313";
}

.fa-h2:before {
  content: "\f314";
}

.fa-h3:before {
  content: "\f315";
}

.fa-h4:before {
  content: "\f86a";
}

.fa-hacker-news:before {
  content: "\f1d4";
}

.fa-hacker-news-square:before {
  content: "\f3af";
}

.fa-hackerrank:before {
  content: "\f5f7";
}

.fa-hamburger:before {
  content: "\f805";
}

.fa-hammer:before {
  content: "\f6e3";
}

.fa-hammer-war:before {
  content: "\f6e4";
}

.fa-hamsa:before {
  content: "\f665";
}

.fa-hand-heart:before {
  content: "\f4bc";
}

.fa-hand-holding:before {
  content: "\f4bd";
}

.fa-hand-holding-box:before {
  content: "\f47b";
}

.fa-hand-holding-heart:before {
  content: "\f4be";
}

.fa-hand-holding-magic:before {
  content: "\f6e5";
}

.fa-hand-holding-medical:before {
  content: "\e05c";
}

.fa-hand-holding-seedling:before {
  content: "\f4bf";
}

.fa-hand-holding-usd:before {
  content: "\f4c0";
}

.fa-hand-holding-water:before {
  content: "\f4c1";
}

.fa-hand-lizard:before {
  content: "\f258";
}

.fa-hand-middle-finger:before {
  content: "\f806";
}

.fa-hand-paper:before {
  content: "\f256";
}

.fa-hand-peace:before {
  content: "\f25b";
}

.fa-hand-point-down:before {
  content: "\f0a7";
}

.fa-hand-point-left:before {
  content: "\f0a5";
}

.fa-hand-point-right:before {
  content: "\f0a4";
}

.fa-hand-point-up:before {
  content: "\f0a6";
}

.fa-hand-pointer:before {
  content: "\f25a";
}

.fa-hand-receiving:before {
  content: "\f47c";
}

.fa-hand-rock:before {
  content: "\f255";
}

.fa-hand-scissors:before {
  content: "\f257";
}

.fa-hand-sparkles:before {
  content: "\e05d";
}

.fa-hand-spock:before {
  content: "\f259";
}

.fa-hands:before {
  content: "\f4c2";
}

.fa-hands-heart:before {
  content: "\f4c3";
}

.fa-hands-helping:before {
  content: "\f4c4";
}

.fa-hands-usd:before {
  content: "\f4c5";
}

.fa-hands-wash:before {
  content: "\e05e";
}

.fa-handshake:before {
  content: "\f2b5";
}

.fa-handshake-alt:before {
  content: "\f4c6";
}

.fa-handshake-alt-slash:before {
  content: "\e05f";
}

.fa-handshake-slash:before {
  content: "\e060";
}

.fa-hanukiah:before {
  content: "\f6e6";
}

.fa-hard-hat:before {
  content: "\f807";
}

.fa-hashtag:before {
  content: "\f292";
}

.fa-hat-chef:before {
  content: "\f86b";
}

.fa-hat-cowboy:before {
  content: "\f8c0";
}

.fa-hat-cowboy-side:before {
  content: "\f8c1";
}

.fa-hat-santa:before {
  content: "\f7a7";
}

.fa-hat-winter:before {
  content: "\f7a8";
}

.fa-hat-witch:before {
  content: "\f6e7";
}

.fa-hat-wizard:before {
  content: "\f6e8";
}

.fa-hdd:before {
  content: "\f0a0";
}

.fa-head-side:before {
  content: "\f6e9";
}

.fa-head-side-brain:before {
  content: "\f808";
}

.fa-head-side-cough:before {
  content: "\e061";
}

.fa-head-side-cough-slash:before {
  content: "\e062";
}

.fa-head-side-headphones:before {
  content: "\f8c2";
}

.fa-head-side-mask:before {
  content: "\e063";
}

.fa-head-side-medical:before {
  content: "\f809";
}

.fa-head-side-virus:before {
  content: "\e064";
}

.fa-head-vr:before {
  content: "\f6ea";
}

.fa-heading:before {
  content: "\f1dc";
}

.fa-headphones:before {
  content: "\f025";
}

.fa-headphones-alt:before {
  content: "\f58f";
}

.fa-headset:before {
  content: "\f590";
}

.fa-heart:before {
  content: "\f004";
}

.fa-heart-broken:before {
  content: "\f7a9";
}

.fa-heart-circle:before {
  content: "\f4c7";
}

.fa-heart-rate:before {
  content: "\f5f8";
}

.fa-heart-square:before {
  content: "\f4c8";
}

.fa-heartbeat:before {
  content: "\f21e";
}

.fa-heat:before {
  content: "\e00c";
}

.fa-helicopter:before {
  content: "\f533";
}

.fa-helmet-battle:before {
  content: "\f6eb";
}

.fa-hexagon:before {
  content: "\f312";
}

.fa-highlighter:before {
  content: "\f591";
}

.fa-hiking:before {
  content: "\f6ec";
}

.fa-hippo:before {
  content: "\f6ed";
}

.fa-hips:before {
  content: "\f452";
}

.fa-hire-a-helper:before {
  content: "\f3b0";
}

.fa-history:before {
  content: "\f1da";
}

.fa-hive:before {
  content: "\e07f";
}

.fa-hockey-mask:before {
  content: "\f6ee";
}

.fa-hockey-puck:before {
  content: "\f453";
}

.fa-hockey-sticks:before {
  content: "\f454";
}

.fa-holly-berry:before {
  content: "\f7aa";
}

.fa-home:before {
  content: "\f015";
}

.fa-home-alt:before {
  content: "\f80a";
}

.fa-home-heart:before {
  content: "\f4c9";
}

.fa-home-lg:before {
  content: "\f80b";
}

.fa-home-lg-alt:before {
  content: "\f80c";
}

.fa-hood-cloak:before {
  content: "\f6ef";
}

.fa-hooli:before {
  content: "\f427";
}

.fa-horizontal-rule:before {
  content: "\f86c";
}

.fa-hornbill:before {
  content: "\f592";
}

.fa-horse:before {
  content: "\f6f0";
}

.fa-horse-head:before {
  content: "\f7ab";
}

.fa-horse-saddle:before {
  content: "\f8c3";
}

.fa-hospital:before {
  content: "\f0f8";
}

.fa-hospital-alt:before {
  content: "\f47d";
}

.fa-hospital-symbol:before {
  content: "\f47e";
}

.fa-hospital-user:before {
  content: "\f80d";
}

.fa-hospitals:before {
  content: "\f80e";
}

.fa-hot-tub:before {
  content: "\f593";
}

.fa-hotdog:before {
  content: "\f80f";
}

.fa-hotel:before {
  content: "\f594";
}

.fa-hotjar:before {
  content: "\f3b1";
}

.fa-hourglass:before {
  content: "\f254";
}

.fa-hourglass-end:before {
  content: "\f253";
}

.fa-hourglass-half:before {
  content: "\f252";
}

.fa-hourglass-start:before {
  content: "\f251";
}

.fa-house:before {
  content: "\e00d";
}

.fa-house-damage:before {
  content: "\f6f1";
}

.fa-house-day:before {
  content: "\e00e";
}

.fa-house-flood:before {
  content: "\f74f";
}

.fa-house-leave:before {
  content: "\e00f";
}

.fa-house-night:before {
  content: "\e010";
}

.fa-house-return:before {
  content: "\e011";
}

.fa-house-signal:before {
  content: "\e012";
}

.fa-house-user:before {
  content: "\e065";
}

.fa-houzz:before {
  content: "\f27c";
}

.fa-hryvnia:before {
  content: "\f6f2";
}

.fa-html5:before {
  content: "\f13b";
}

.fa-hubspot:before {
  content: "\f3b2";
}

.fa-humidity:before {
  content: "\f750";
}

.fa-hurricane:before {
  content: "\f751";
}

.fa-i-cursor:before {
  content: "\f246";
}

.fa-ice-cream:before {
  content: "\f810";
}

.fa-ice-skate:before {
  content: "\f7ac";
}

.fa-icicles:before {
  content: "\f7ad";
}

.fa-icons:before {
  content: "\f86d";
}

.fa-icons-alt:before {
  content: "\f86e";
}

.fa-id-badge:before {
  content: "\f2c1";
}

.fa-id-card:before {
  content: "\f2c2";
}

.fa-id-card-alt:before {
  content: "\f47f";
}

.fa-ideal:before {
  content: "\e013";
}

.fa-igloo:before {
  content: "\f7ae";
}

.fa-image:before {
  content: "\f03e";
}

.fa-image-polaroid:before {
  content: "\f8c4";
}

.fa-images:before {
  content: "\f302";
}

.fa-imdb:before {
  content: "\f2d8";
}

.fa-inbox:before {
  content: "\f01c";
}

.fa-inbox-in:before {
  content: "\f310";
}

.fa-inbox-out:before {
  content: "\f311";
}

.fa-indent:before {
  content: "\f03c";
}

.fa-industry:before {
  content: "\f275";
}

.fa-industry-alt:before {
  content: "\f3b3";
}

.fa-infinity:before {
  content: "\f534";
}

.fa-info:before {
  content: "\f129";
}

.fa-info-circle:before {
  content: "\f05a";
}

.fa-info-square:before {
  content: "\f30f";
}

.fa-inhaler:before {
  content: "\f5f9";
}

.fa-innosoft:before {
  content: "\e080";
}

.fa-instagram:before {
  content: "\f16d";
}

.fa-instagram-square:before {
  content: "\e055";
}

.fa-instalod:before {
  content: "\e081";
}

.fa-integral:before {
  content: "\f667";
}

.fa-intercom:before {
  content: "\f7af";
}

.fa-internet-explorer:before {
  content: "\f26b";
}

.fa-intersection:before {
  content: "\f668";
}

.fa-inventory:before {
  content: "\f480";
}

.fa-invision:before {
  content: "\f7b0";
}

.fa-ioxhost:before {
  content: "\f208";
}

.fa-island-tropical:before {
  content: "\f811";
}

.fa-italic:before {
  content: "\f033";
}

.fa-itch-io:before {
  content: "\f83a";
}

.fa-itunes:before {
  content: "\f3b4";
}

.fa-itunes-note:before {
  content: "\f3b5";
}

.fa-jack-o-lantern:before {
  content: "\f30e";
}

.fa-java:before {
  content: "\f4e4";
}

.fa-jedi:before {
  content: "\f669";
}

.fa-jedi-order:before {
  content: "\f50e";
}

.fa-jenkins:before {
  content: "\f3b6";
}

.fa-jira:before {
  content: "\f7b1";
}

.fa-joget:before {
  content: "\f3b7";
}

.fa-joint:before {
  content: "\f595";
}

.fa-joomla:before {
  content: "\f1aa";
}

.fa-journal-whills:before {
  content: "\f66a";
}

.fa-joystick:before {
  content: "\f8c5";
}

.fa-js:before {
  content: "\f3b8";
}

.fa-js-square:before {
  content: "\f3b9";
}

.fa-jsfiddle:before {
  content: "\f1cc";
}

.fa-jug:before {
  content: "\f8c6";
}

.fa-kaaba:before {
  content: "\f66b";
}

.fa-kaggle:before {
  content: "\f5fa";
}

.fa-kazoo:before {
  content: "\f8c7";
}

.fa-kerning:before {
  content: "\f86f";
}

.fa-key:before {
  content: "\f084";
}

.fa-key-skeleton:before {
  content: "\f6f3";
}

.fa-keybase:before {
  content: "\f4f5";
}

.fa-keyboard:before {
  content: "\f11c";
}

.fa-keycdn:before {
  content: "\f3ba";
}

.fa-keynote:before {
  content: "\f66c";
}

.fa-khanda:before {
  content: "\f66d";
}

.fa-kickstarter:before {
  content: "\f3bb";
}

.fa-kickstarter-k:before {
  content: "\f3bc";
}

.fa-kidneys:before {
  content: "\f5fb";
}

.fa-kiss:before {
  content: "\f596";
}

.fa-kiss-beam:before {
  content: "\f597";
}

.fa-kiss-wink-heart:before {
  content: "\f598";
}

.fa-kite:before {
  content: "\f6f4";
}

.fa-kiwi-bird:before {
  content: "\f535";
}

.fa-knife-kitchen:before {
  content: "\f6f5";
}

.fa-korvue:before {
  content: "\f42f";
}

.fa-lambda:before {
  content: "\f66e";
}

.fa-lamp:before {
  content: "\f4ca";
}

.fa-lamp-desk:before {
  content: "\e014";
}

.fa-lamp-floor:before {
  content: "\e015";
}

.fa-landmark:before {
  content: "\f66f";
}

.fa-landmark-alt:before {
  content: "\f752";
}

.fa-language:before {
  content: "\f1ab";
}

.fa-laptop:before {
  content: "\f109";
}

.fa-laptop-code:before {
  content: "\f5fc";
}

.fa-laptop-house:before {
  content: "\e066";
}

.fa-laptop-medical:before {
  content: "\f812";
}

.fa-laravel:before {
  content: "\f3bd";
}

.fa-lasso:before {
  content: "\f8c8";
}

.fa-lastfm:before {
  content: "\f202";
}

.fa-lastfm-square:before {
  content: "\f203";
}

.fa-laugh:before {
  content: "\f599";
}

.fa-laugh-beam:before {
  content: "\f59a";
}

.fa-laugh-squint:before {
  content: "\f59b";
}

.fa-laugh-wink:before {
  content: "\f59c";
}

.fa-layer-group:before {
  content: "\f5fd";
}

.fa-layer-minus:before {
  content: "\f5fe";
}

.fa-layer-plus:before {
  content: "\f5ff";
}

.fa-leaf:before {
  content: "\f06c";
}

.fa-leaf-heart:before {
  content: "\f4cb";
}

.fa-leaf-maple:before {
  content: "\f6f6";
}

.fa-leaf-oak:before {
  content: "\f6f7";
}

.fa-leanpub:before {
  content: "\f212";
}

.fa-lemon:before {
  content: "\f094";
}

.fa-less:before {
  content: "\f41d";
}

.fa-less-than:before {
  content: "\f536";
}

.fa-less-than-equal:before {
  content: "\f537";
}

.fa-level-down:before {
  content: "\f149";
}

.fa-level-down-alt:before {
  content: "\f3be";
}

.fa-level-up:before {
  content: "\f148";
}

.fa-level-up-alt:before {
  content: "\f3bf";
}

.fa-life-ring:before {
  content: "\f1cd";
}

.fa-light-ceiling:before {
  content: "\e016";
}

.fa-light-switch:before {
  content: "\e017";
}

.fa-light-switch-off:before {
  content: "\e018";
}

.fa-light-switch-on:before {
  content: "\e019";
}

.fa-lightbulb:before {
  content: "\f0eb";
}

.fa-lightbulb-dollar:before {
  content: "\f670";
}

.fa-lightbulb-exclamation:before {
  content: "\f671";
}

.fa-lightbulb-on:before {
  content: "\f672";
}

.fa-lightbulb-slash:before {
  content: "\f673";
}

.fa-lights-holiday:before {
  content: "\f7b2";
}

.fa-line:before {
  content: "\f3c0";
}

.fa-line-columns:before {
  content: "\f870";
}

.fa-line-height:before {
  content: "\f871";
}

.fa-link:before {
  content: "\f0c1";
}

.fa-linkedin:before {
  content: "\f08c";
}

.fa-linkedin-in:before {
  content: "\f0e1";
}

.fa-linode:before {
  content: "\f2b8";
}

.fa-linux:before {
  content: "\f17c";
}

.fa-lips:before {
  content: "\f600";
}

.fa-lira-sign:before {
  content: "\f195";
}

.fa-list:before {
  content: "\f03a";
}

.fa-list-alt:before {
  content: "\f022";
}

.fa-list-music:before {
  content: "\f8c9";
}

.fa-list-ol:before {
  content: "\f0cb";
}

.fa-list-ul:before {
  content: "\f0ca";
}

.fa-location:before {
  content: "\f601";
}

.fa-location-arrow:before {
  content: "\f124";
}

.fa-location-circle:before {
  content: "\f602";
}

.fa-location-slash:before {
  content: "\f603";
}

.fa-lock:before {
  content: "\f023";
}

.fa-lock-alt:before {
  content: "\f30d";
}

.fa-lock-open:before {
  content: "\f3c1";
}

.fa-lock-open-alt:before {
  content: "\f3c2";
}

.fa-long-arrow-alt-down:before {
  content: "\f309";
}

.fa-long-arrow-alt-left:before {
  content: "\f30a";
}

.fa-long-arrow-alt-right:before {
  content: "\f30b";
}

.fa-long-arrow-alt-up:before {
  content: "\f30c";
}

.fa-long-arrow-down:before {
  content: "\f175";
}

.fa-long-arrow-left:before {
  content: "\f177";
}

.fa-long-arrow-right:before {
  content: "\f178";
}

.fa-long-arrow-up:before {
  content: "\f176";
}

.fa-loveseat:before {
  content: "\f4cc";
}

.fa-low-vision:before {
  content: "\f2a8";
}

.fa-luchador:before {
  content: "\f455";
}

.fa-luggage-cart:before {
  content: "\f59d";
}

.fa-lungs:before {
  content: "\f604";
}

.fa-lungs-virus:before {
  content: "\e067";
}

.fa-lyft:before {
  content: "\f3c3";
}

.fa-mace:before {
  content: "\f6f8";
}

.fa-magento:before {
  content: "\f3c4";
}

.fa-magic:before {
  content: "\f0d0";
}

.fa-magnet:before {
  content: "\f076";
}

.fa-mail-bulk:before {
  content: "\f674";
}

.fa-mailbox:before {
  content: "\f813";
}

.fa-mailchimp:before {
  content: "\f59e";
}

.fa-male:before {
  content: "\f183";
}

.fa-mandalorian:before {
  content: "\f50f";
}

.fa-mandolin:before {
  content: "\f6f9";
}

.fa-map:before {
  content: "\f279";
}

.fa-map-marked:before {
  content: "\f59f";
}

.fa-map-marked-alt:before {
  content: "\f5a0";
}

.fa-map-marker:before {
  content: "\f041";
}

.fa-map-marker-alt:before {
  content: "\f3c5";
}

.fa-map-marker-alt-slash:before {
  content: "\f605";
}

.fa-map-marker-check:before {
  content: "\f606";
}

.fa-map-marker-edit:before {
  content: "\f607";
}

.fa-map-marker-exclamation:before {
  content: "\f608";
}

.fa-map-marker-minus:before {
  content: "\f609";
}

.fa-map-marker-plus:before {
  content: "\f60a";
}

.fa-map-marker-question:before {
  content: "\f60b";
}

.fa-map-marker-slash:before {
  content: "\f60c";
}

.fa-map-marker-smile:before {
  content: "\f60d";
}

.fa-map-marker-times:before {
  content: "\f60e";
}

.fa-map-pin:before {
  content: "\f276";
}

.fa-map-signs:before {
  content: "\f277";
}

.fa-markdown:before {
  content: "\f60f";
}

.fa-marker:before {
  content: "\f5a1";
}

.fa-mars:before {
  content: "\f222";
}

.fa-mars-double:before {
  content: "\f227";
}

.fa-mars-stroke:before {
  content: "\f229";
}

.fa-mars-stroke-h:before {
  content: "\f22b";
}

.fa-mars-stroke-v:before {
  content: "\f22a";
}

.fa-mask:before {
  content: "\f6fa";
}

.fa-mastodon:before {
  content: "\f4f6";
}

.fa-maxcdn:before {
  content: "\f136";
}

.fa-mdb:before {
  content: "\f8ca";
}

.fa-meat:before {
  content: "\f814";
}

.fa-medal:before {
  content: "\f5a2";
}

.fa-medapps:before {
  content: "\f3c6";
}

.fa-medium:before {
  content: "\f23a";
}

.fa-medium-m:before {
  content: "\f3c7";
}

.fa-medkit:before {
  content: "\f0fa";
}

.fa-medrt:before {
  content: "\f3c8";
}

.fa-meetup:before {
  content: "\f2e0";
}

.fa-megaphone:before {
  content: "\f675";
}

.fa-megaport:before {
  content: "\f5a3";
}

.fa-meh:before {
  content: "\f11a";
}

.fa-meh-blank:before {
  content: "\f5a4";
}

.fa-meh-rolling-eyes:before {
  content: "\f5a5";
}

.fa-memory:before {
  content: "\f538";
}

.fa-mendeley:before {
  content: "\f7b3";
}

.fa-menorah:before {
  content: "\f676";
}

.fa-mercury:before {
  content: "\f223";
}

.fa-meteor:before {
  content: "\f753";
}

.fa-microblog:before {
  content: "\e01a";
}

.fa-microchip:before {
  content: "\f2db";
}

.fa-microphone:before {
  content: "\f130";
}

.fa-microphone-alt:before {
  content: "\f3c9";
}

.fa-microphone-alt-slash:before {
  content: "\f539";
}

.fa-microphone-slash:before {
  content: "\f131";
}

.fa-microphone-stand:before {
  content: "\f8cb";
}

.fa-microscope:before {
  content: "\f610";
}

.fa-microsoft:before {
  content: "\f3ca";
}

.fa-microwave:before {
  content: "\e01b";
}

.fa-mind-share:before {
  content: "\f677";
}

.fa-minus:before {
  content: "\f068";
}

.fa-minus-circle:before {
  content: "\f056";
}

.fa-minus-hexagon:before {
  content: "\f307";
}

.fa-minus-octagon:before {
  content: "\f308";
}

.fa-minus-square:before {
  content: "\f146";
}

.fa-mistletoe:before {
  content: "\f7b4";
}

.fa-mitten:before {
  content: "\f7b5";
}

.fa-mix:before {
  content: "\f3cb";
}

.fa-mixcloud:before {
  content: "\f289";
}

.fa-mixer:before {
  content: "\e056";
}

.fa-mizuni:before {
  content: "\f3cc";
}

.fa-mobile:before {
  content: "\f10b";
}

.fa-mobile-alt:before {
  content: "\f3cd";
}

.fa-mobile-android:before {
  content: "\f3ce";
}

.fa-mobile-android-alt:before {
  content: "\f3cf";
}

.fa-modx:before {
  content: "\f285";
}

.fa-monero:before {
  content: "\f3d0";
}

.fa-money-bill:before {
  content: "\f0d6";
}

.fa-money-bill-alt:before {
  content: "\f3d1";
}

.fa-money-bill-wave:before {
  content: "\f53a";
}

.fa-money-bill-wave-alt:before {
  content: "\f53b";
}

.fa-money-check:before {
  content: "\f53c";
}

.fa-money-check-alt:before {
  content: "\f53d";
}

.fa-money-check-edit:before {
  content: "\f872";
}

.fa-money-check-edit-alt:before {
  content: "\f873";
}

.fa-monitor-heart-rate:before {
  content: "\f611";
}

.fa-monkey:before {
  content: "\f6fb";
}

.fa-monument:before {
  content: "\f5a6";
}

.fa-moon:before {
  content: "\f186";
}

.fa-moon-cloud:before {
  content: "\f754";
}

.fa-moon-stars:before {
  content: "\f755";
}

.fa-mortar-pestle:before {
  content: "\f5a7";
}

.fa-mosque:before {
  content: "\f678";
}

.fa-motorcycle:before {
  content: "\f21c";
}

.fa-mountain:before {
  content: "\f6fc";
}

.fa-mountains:before {
  content: "\f6fd";
}

.fa-mouse:before {
  content: "\f8cc";
}

.fa-mouse-alt:before {
  content: "\f8cd";
}

.fa-mouse-pointer:before {
  content: "\f245";
}

.fa-mp3-player:before {
  content: "\f8ce";
}

.fa-mug:before {
  content: "\f874";
}

.fa-mug-hot:before {
  content: "\f7b6";
}

.fa-mug-marshmallows:before {
  content: "\f7b7";
}

.fa-mug-tea:before {
  content: "\f875";
}

.fa-music:before {
  content: "\f001";
}

.fa-music-alt:before {
  content: "\f8cf";
}

.fa-music-alt-slash:before {
  content: "\f8d0";
}

.fa-music-slash:before {
  content: "\f8d1";
}

.fa-napster:before {
  content: "\f3d2";
}

.fa-narwhal:before {
  content: "\f6fe";
}

.fa-neos:before {
  content: "\f612";
}

.fa-network-wired:before {
  content: "\f6ff";
}

.fa-neuter:before {
  content: "\f22c";
}

.fa-newspaper:before {
  content: "\f1ea";
}

.fa-nimblr:before {
  content: "\f5a8";
}

.fa-node:before {
  content: "\f419";
}

.fa-node-js:before {
  content: "\f3d3";
}

.fa-not-equal:before {
  content: "\f53e";
}

.fa-notes-medical:before {
  content: "\f481";
}

.fa-npm:before {
  content: "\f3d4";
}

.fa-ns8:before {
  content: "\f3d5";
}

.fa-nutritionix:before {
  content: "\f3d6";
}

.fa-object-group:before {
  content: "\f247";
}

.fa-object-ungroup:before {
  content: "\f248";
}

.fa-octagon:before {
  content: "\f306";
}

.fa-octopus-deploy:before {
  content: "\e082";
}

.fa-odnoklassniki:before {
  content: "\f263";
}

.fa-odnoklassniki-square:before {
  content: "\f264";
}

.fa-oil-can:before {
  content: "\f613";
}

.fa-oil-temp:before {
  content: "\f614";
}

.fa-old-republic:before {
  content: "\f510";
}

.fa-om:before {
  content: "\f679";
}

.fa-omega:before {
  content: "\f67a";
}

.fa-opencart:before {
  content: "\f23d";
}

.fa-openid:before {
  content: "\f19b";
}

.fa-opera:before {
  content: "\f26a";
}

.fa-optin-monster:before {
  content: "\f23c";
}

.fa-orcid:before {
  content: "\f8d2";
}

.fa-ornament:before {
  content: "\f7b8";
}

.fa-osi:before {
  content: "\f41a";
}

.fa-otter:before {
  content: "\f700";
}

.fa-outdent:before {
  content: "\f03b";
}

.fa-outlet:before {
  content: "\e01c";
}

.fa-oven:before {
  content: "\e01d";
}

.fa-overline:before {
  content: "\f876";
}

.fa-page-break:before {
  content: "\f877";
}

.fa-page4:before {
  content: "\f3d7";
}

.fa-pagelines:before {
  content: "\f18c";
}

.fa-pager:before {
  content: "\f815";
}

.fa-paint-brush:before {
  content: "\f1fc";
}

.fa-paint-brush-alt:before {
  content: "\f5a9";
}

.fa-paint-roller:before {
  content: "\f5aa";
}

.fa-palette:before {
  content: "\f53f";
}

.fa-palfed:before {
  content: "\f3d8";
}

.fa-pallet:before {
  content: "\f482";
}

.fa-pallet-alt:before {
  content: "\f483";
}

.fa-paper-plane:before {
  content: "\f1d8";
}

.fa-paperclip:before {
  content: "\f0c6";
}

.fa-parachute-box:before {
  content: "\f4cd";
}

.fa-paragraph:before {
  content: "\f1dd";
}

.fa-paragraph-rtl:before {
  content: "\f878";
}

.fa-parking:before {
  content: "\f540";
}

.fa-parking-circle:before {
  content: "\f615";
}

.fa-parking-circle-slash:before {
  content: "\f616";
}

.fa-parking-slash:before {
  content: "\f617";
}

.fa-passport:before {
  content: "\f5ab";
}

.fa-pastafarianism:before {
  content: "\f67b";
}

.fa-paste:before {
  content: "\f0ea";
}

.fa-patreon:before {
  content: "\f3d9";
}

.fa-pause:before {
  content: "\f04c";
}

.fa-pause-circle:before {
  content: "\f28b";
}

.fa-paw:before {
  content: "\f1b0";
}

.fa-paw-alt:before {
  content: "\f701";
}

.fa-paw-claws:before {
  content: "\f702";
}

.fa-paypal:before {
  content: "\f1ed";
}

.fa-peace:before {
  content: "\f67c";
}

.fa-pegasus:before {
  content: "\f703";
}

.fa-pen:before {
  content: "\f304";
}

.fa-pen-alt:before {
  content: "\f305";
}

.fa-pen-fancy:before {
  content: "\f5ac";
}

.fa-pen-nib:before {
  content: "\f5ad";
}

.fa-pen-square:before {
  content: "\f14b";
}

.fa-pencil:before {
  content: "\f040";
}

.fa-pencil-alt:before {
  content: "\f303";
}

.fa-pencil-paintbrush:before {
  content: "\f618";
}

.fa-pencil-ruler:before {
  content: "\f5ae";
}

.fa-pennant:before {
  content: "\f456";
}

.fa-penny-arcade:before {
  content: "\f704";
}

.fa-people-arrows:before {
  content: "\e068";
}

.fa-people-carry:before {
  content: "\f4ce";
}

.fa-pepper-hot:before {
  content: "\f816";
}

.fa-perbyte:before {
  content: "\e083";
}

.fa-percent:before {
  content: "\f295";
}

.fa-percentage:before {
  content: "\f541";
}

.fa-periscope:before {
  content: "\f3da";
}

.fa-person-booth:before {
  content: "\f756";
}

.fa-person-carry:before {
  content: "\f4cf";
}

.fa-person-dolly:before {
  content: "\f4d0";
}

.fa-person-dolly-empty:before {
  content: "\f4d1";
}

.fa-person-sign:before {
  content: "\f757";
}

.fa-phabricator:before {
  content: "\f3db";
}

.fa-phoenix-framework:before {
  content: "\f3dc";
}

.fa-phoenix-squadron:before {
  content: "\f511";
}

.fa-phone:before {
  content: "\f095";
}

.fa-phone-alt:before {
  content: "\f879";
}

.fa-phone-laptop:before {
  content: "\f87a";
}

.fa-phone-office:before {
  content: "\f67d";
}

.fa-phone-plus:before {
  content: "\f4d2";
}

.fa-phone-rotary:before {
  content: "\f8d3";
}

.fa-phone-slash:before {
  content: "\f3dd";
}

.fa-phone-square:before {
  content: "\f098";
}

.fa-phone-square-alt:before {
  content: "\f87b";
}

.fa-phone-volume:before {
  content: "\f2a0";
}

.fa-photo-video:before {
  content: "\f87c";
}

.fa-php:before {
  content: "\f457";
}

.fa-pi:before {
  content: "\f67e";
}

.fa-piano:before {
  content: "\f8d4";
}

.fa-piano-keyboard:before {
  content: "\f8d5";
}

.fa-pie:before {
  content: "\f705";
}

.fa-pied-piper:before {
  content: "\f2ae";
}

.fa-pied-piper-alt:before {
  content: "\f1a8";
}

.fa-pied-piper-hat:before {
  content: "\f4e5";
}

.fa-pied-piper-pp:before {
  content: "\f1a7";
}

.fa-pied-piper-square:before {
  content: "\e01e";
}

.fa-pig:before {
  content: "\f706";
}

.fa-piggy-bank:before {
  content: "\f4d3";
}

.fa-pills:before {
  content: "\f484";
}

.fa-pinterest:before {
  content: "\f0d2";
}

.fa-pinterest-p:before {
  content: "\f231";
}

.fa-pinterest-square:before {
  content: "\f0d3";
}

.fa-pizza:before {
  content: "\f817";
}

.fa-pizza-slice:before {
  content: "\f818";
}

.fa-place-of-worship:before {
  content: "\f67f";
}

.fa-plane:before {
  content: "\f072";
}

.fa-plane-alt:before {
  content: "\f3de";
}

.fa-plane-arrival:before {
  content: "\f5af";
}

.fa-plane-departure:before {
  content: "\f5b0";
}

.fa-plane-slash:before {
  content: "\e069";
}

.fa-planet-moon:before {
  content: "\e01f";
}

.fa-planet-ringed:before {
  content: "\e020";
}

.fa-play:before {
  content: "\f04b";
}

.fa-play-circle:before {
  content: "\f144";
}

.fa-playstation:before {
  content: "\f3df";
}

.fa-plug:before {
  content: "\f1e6";
}

.fa-plus:before {
  content: "\f067";
}

.fa-plus-circle:before {
  content: "\f055";
}

.fa-plus-hexagon:before {
  content: "\f300";
}

.fa-plus-octagon:before {
  content: "\f301";
}

.fa-plus-square:before {
  content: "\f0fe";
}

.fa-podcast:before {
  content: "\f2ce";
}

.fa-podium:before {
  content: "\f680";
}

.fa-podium-star:before {
  content: "\f758";
}

.fa-police-box:before {
  content: "\e021";
}

.fa-poll:before {
  content: "\f681";
}

.fa-poll-h:before {
  content: "\f682";
}

.fa-poll-people:before {
  content: "\f759";
}

.fa-poo:before {
  content: "\f2fe";
}

.fa-poo-storm:before {
  content: "\f75a";
}

.fa-poop:before {
  content: "\f619";
}

.fa-popcorn:before {
  content: "\f819";
}

.fa-portal-enter:before {
  content: "\e022";
}

.fa-portal-exit:before {
  content: "\e023";
}

.fa-portrait:before {
  content: "\f3e0";
}

.fa-pound-sign:before {
  content: "\f154";
}

.fa-power-off:before {
  content: "\f011";
}

.fa-pray:before {
  content: "\f683";
}

.fa-praying-hands:before {
  content: "\f684";
}

.fa-prescription:before {
  content: "\f5b1";
}

.fa-prescription-bottle:before {
  content: "\f485";
}

.fa-prescription-bottle-alt:before {
  content: "\f486";
}

.fa-presentation:before {
  content: "\f685";
}

.fa-print:before {
  content: "\f02f";
}

.fa-print-search:before {
  content: "\f81a";
}

.fa-print-slash:before {
  content: "\f686";
}

.fa-procedures:before {
  content: "\f487";
}

.fa-product-hunt:before {
  content: "\f288";
}

.fa-project-diagram:before {
  content: "\f542";
}

.fa-projector:before {
  content: "\f8d6";
}

.fa-pump-medical:before {
  content: "\e06a";
}

.fa-pump-soap:before {
  content: "\e06b";
}

.fa-pumpkin:before {
  content: "\f707";
}

.fa-pushed:before {
  content: "\f3e1";
}

.fa-puzzle-piece:before {
  content: "\f12e";
}

.fa-python:before {
  content: "\f3e2";
}

.fa-qq:before {
  content: "\f1d6";
}

.fa-qrcode:before {
  content: "\f029";
}

.fa-question:before {
  content: "\f128";
}

.fa-question-circle:before {
  content: "\f059";
}

.fa-question-square:before {
  content: "\f2fd";
}

.fa-quidditch:before {
  content: "\f458";
}

.fa-quinscape:before {
  content: "\f459";
}

.fa-quora:before {
  content: "\f2c4";
}

.fa-quote-left:before {
  content: "\f10d";
}

.fa-quote-right:before {
  content: "\f10e";
}

.fa-quran:before {
  content: "\f687";
}

.fa-r-project:before {
  content: "\f4f7";
}

.fa-rabbit:before {
  content: "\f708";
}

.fa-rabbit-fast:before {
  content: "\f709";
}

.fa-racquet:before {
  content: "\f45a";
}

.fa-radar:before {
  content: "\e024";
}

.fa-radiation:before {
  content: "\f7b9";
}

.fa-radiation-alt:before {
  content: "\f7ba";
}

.fa-radio:before {
  content: "\f8d7";
}

.fa-radio-alt:before {
  content: "\f8d8";
}

.fa-rainbow:before {
  content: "\f75b";
}

.fa-raindrops:before {
  content: "\f75c";
}

.fa-ram:before {
  content: "\f70a";
}

.fa-ramp-loading:before {
  content: "\f4d4";
}

.fa-random:before {
  content: "\f074";
}

.fa-raspberry-pi:before {
  content: "\f7bb";
}

.fa-ravelry:before {
  content: "\f2d9";
}

.fa-raygun:before {
  content: "\e025";
}

.fa-react:before {
  content: "\f41b";
}

.fa-reacteurope:before {
  content: "\f75d";
}

.fa-readme:before {
  content: "\f4d5";
}

.fa-rebel:before {
  content: "\f1d0";
}

.fa-receipt:before {
  content: "\f543";
}

.fa-record-vinyl:before {
  content: "\f8d9";
}

.fa-rectangle-landscape:before {
  content: "\f2fa";
}

.fa-rectangle-portrait:before {
  content: "\f2fb";
}

.fa-rectangle-wide:before {
  content: "\f2fc";
}

.fa-recycle:before {
  content: "\f1b8";
}

.fa-red-river:before {
  content: "\f3e3";
}

.fa-reddit:before {
  content: "\f1a1";
}

.fa-reddit-alien:before {
  content: "\f281";
}

.fa-reddit-square:before {
  content: "\f1a2";
}

.fa-redhat:before {
  content: "\f7bc";
}

.fa-redo:before {
  content: "\f01e";
}

.fa-redo-alt:before {
  content: "\f2f9";
}

.fa-refrigerator:before {
  content: "\e026";
}

.fa-registered:before {
  content: "\f25d";
}

.fa-remove-format:before {
  content: "\f87d";
}

.fa-renren:before {
  content: "\f18b";
}

.fa-repeat:before {
  content: "\f363";
}

.fa-repeat-1:before {
  content: "\f365";
}

.fa-repeat-1-alt:before {
  content: "\f366";
}

.fa-repeat-alt:before {
  content: "\f364";
}

.fa-reply:before {
  content: "\f3e5";
}

.fa-reply-all:before {
  content: "\f122";
}

.fa-replyd:before {
  content: "\f3e6";
}

.fa-republican:before {
  content: "\f75e";
}

.fa-researchgate:before {
  content: "\f4f8";
}

.fa-resolving:before {
  content: "\f3e7";
}

.fa-restroom:before {
  content: "\f7bd";
}

.fa-retweet:before {
  content: "\f079";
}

.fa-retweet-alt:before {
  content: "\f361";
}

.fa-rev:before {
  content: "\f5b2";
}

.fa-ribbon:before {
  content: "\f4d6";
}

.fa-ring:before {
  content: "\f70b";
}

.fa-rings-wedding:before {
  content: "\f81b";
}

.fa-road:before {
  content: "\f018";
}

.fa-robot:before {
  content: "\f544";
}

.fa-rocket:before {
  content: "\f135";
}

.fa-rocket-launch:before {
  content: "\e027";
}

.fa-rocketchat:before {
  content: "\f3e8";
}

.fa-rockrms:before {
  content: "\f3e9";
}

.fa-route:before {
  content: "\f4d7";
}

.fa-route-highway:before {
  content: "\f61a";
}

.fa-route-interstate:before {
  content: "\f61b";
}

.fa-router:before {
  content: "\f8da";
}

.fa-rss:before {
  content: "\f09e";
}

.fa-rss-square:before {
  content: "\f143";
}

.fa-ruble-sign:before {
  content: "\f158";
}

.fa-ruler:before {
  content: "\f545";
}

.fa-ruler-combined:before {
  content: "\f546";
}

.fa-ruler-horizontal:before {
  content: "\f547";
}

.fa-ruler-triangle:before {
  content: "\f61c";
}

.fa-ruler-vertical:before {
  content: "\f548";
}

.fa-running:before {
  content: "\f70c";
}

.fa-rupee-sign:before {
  content: "\f156";
}

.fa-rust:before {
  content: "\e07a";
}

.fa-rv:before {
  content: "\f7be";
}

.fa-sack:before {
  content: "\f81c";
}

.fa-sack-dollar:before {
  content: "\f81d";
}

.fa-sad-cry:before {
  content: "\f5b3";
}

.fa-sad-tear:before {
  content: "\f5b4";
}

.fa-safari:before {
  content: "\f267";
}

.fa-salad:before {
  content: "\f81e";
}

.fa-salesforce:before {
  content: "\f83b";
}

.fa-sandwich:before {
  content: "\f81f";
}

.fa-sass:before {
  content: "\f41e";
}

.fa-satellite:before {
  content: "\f7bf";
}

.fa-satellite-dish:before {
  content: "\f7c0";
}

.fa-sausage:before {
  content: "\f820";
}

.fa-save:before {
  content: "\f0c7";
}

.fa-sax-hot:before {
  content: "\f8db";
}

.fa-saxophone:before {
  content: "\f8dc";
}

.fa-scalpel:before {
  content: "\f61d";
}

.fa-scalpel-path:before {
  content: "\f61e";
}

.fa-scanner:before {
  content: "\f488";
}

.fa-scanner-image:before {
  content: "\f8f3";
}

.fa-scanner-keyboard:before {
  content: "\f489";
}

.fa-scanner-touchscreen:before {
  content: "\f48a";
}

.fa-scarecrow:before {
  content: "\f70d";
}

.fa-scarf:before {
  content: "\f7c1";
}

.fa-schlix:before {
  content: "\f3ea";
}

.fa-school:before {
  content: "\f549";
}

.fa-screwdriver:before {
  content: "\f54a";
}

.fa-scribd:before {
  content: "\f28a";
}

.fa-scroll:before {
  content: "\f70e";
}

.fa-scroll-old:before {
  content: "\f70f";
}

.fa-scrubber:before {
  content: "\f2f8";
}

.fa-scythe:before {
  content: "\f710";
}

.fa-sd-card:before {
  content: "\f7c2";
}

.fa-search:before {
  content: "\f002";
}

.fa-search-dollar:before {
  content: "\f688";
}

.fa-search-location:before {
  content: "\f689";
}

.fa-search-minus:before {
  content: "\f010";
}

.fa-search-plus:before {
  content: "\f00e";
}

.fa-searchengin:before {
  content: "\f3eb";
}

.fa-seedling:before {
  content: "\f4d8";
}

.fa-sellcast:before {
  content: "\f2da";
}

.fa-sellsy:before {
  content: "\f213";
}

.fa-send-back:before {
  content: "\f87e";
}

.fa-send-backward:before {
  content: "\f87f";
}

.fa-sensor:before {
  content: "\e028";
}

.fa-sensor-alert:before {
  content: "\e029";
}

.fa-sensor-fire:before {
  content: "\e02a";
}

.fa-sensor-on:before {
  content: "\e02b";
}

.fa-sensor-smoke:before {
  content: "\e02c";
}

.fa-server:before {
  content: "\f233";
}

.fa-servicestack:before {
  content: "\f3ec";
}

.fa-shapes:before {
  content: "\f61f";
}

.fa-share:before {
  content: "\f064";
}

.fa-share-all:before {
  content: "\f367";
}

.fa-share-alt:before {
  content: "\f1e0";
}

.fa-share-alt-square:before {
  content: "\f1e1";
}

.fa-share-square:before {
  content: "\f14d";
}

.fa-sheep:before {
  content: "\f711";
}

.fa-shekel-sign:before {
  content: "\f20b";
}

.fa-shield:before {
  content: "\f132";
}

.fa-shield-alt:before {
  content: "\f3ed";
}

.fa-shield-check:before {
  content: "\f2f7";
}

.fa-shield-cross:before {
  content: "\f712";
}

.fa-shield-virus:before {
  content: "\e06c";
}

.fa-ship:before {
  content: "\f21a";
}

.fa-shipping-fast:before {
  content: "\f48b";
}

.fa-shipping-timed:before {
  content: "\f48c";
}

.fa-shirtsinbulk:before {
  content: "\f214";
}

.fa-shish-kebab:before {
  content: "\f821";
}

.fa-shoe-prints:before {
  content: "\f54b";
}

.fa-shopify:before {
  content: "\e057";
}

.fa-shopping-bag:before {
  content: "\f290";
}

.fa-shopping-basket:before {
  content: "\f291";
}

.fa-shopping-cart:before {
  content: "\f07a";
}

.fa-shopware:before {
  content: "\f5b5";
}

.fa-shovel:before {
  content: "\f713";
}

.fa-shovel-snow:before {
  content: "\f7c3";
}

.fa-shower:before {
  content: "\f2cc";
}

.fa-shredder:before {
  content: "\f68a";
}

.fa-shuttle-van:before {
  content: "\f5b6";
}

.fa-shuttlecock:before {
  content: "\f45b";
}

.fa-sickle:before {
  content: "\f822";
}

.fa-sigma:before {
  content: "\f68b";
}

.fa-sign:before {
  content: "\f4d9";
}

.fa-sign-in:before {
  content: "\f090";
}

.fa-sign-in-alt:before {
  content: "\f2f6";
}

.fa-sign-language:before {
  content: "\f2a7";
}

.fa-sign-out:before {
  content: "\f08b";
}

.fa-sign-out-alt:before {
  content: "\f2f5";
}

.fa-signal:before {
  content: "\f012";
}

.fa-signal-1:before {
  content: "\f68c";
}

.fa-signal-2:before {
  content: "\f68d";
}

.fa-signal-3:before {
  content: "\f68e";
}

.fa-signal-4:before {
  content: "\f68f";
}

.fa-signal-alt:before {
  content: "\f690";
}

.fa-signal-alt-1:before {
  content: "\f691";
}

.fa-signal-alt-2:before {
  content: "\f692";
}

.fa-signal-alt-3:before {
  content: "\f693";
}

.fa-signal-alt-slash:before {
  content: "\f694";
}

.fa-signal-slash:before {
  content: "\f695";
}

.fa-signal-stream:before {
  content: "\f8dd";
}

.fa-signature:before {
  content: "\f5b7";
}

.fa-sim-card:before {
  content: "\f7c4";
}

.fa-simplybuilt:before {
  content: "\f215";
}

.fa-sink:before {
  content: "\e06d";
}

.fa-siren:before {
  content: "\e02d";
}

.fa-siren-on:before {
  content: "\e02e";
}

.fa-sistrix:before {
  content: "\f3ee";
}

.fa-sitemap:before {
  content: "\f0e8";
}

.fa-sith:before {
  content: "\f512";
}

.fa-skating:before {
  content: "\f7c5";
}

.fa-skeleton:before {
  content: "\f620";
}

.fa-sketch:before {
  content: "\f7c6";
}

.fa-ski-jump:before {
  content: "\f7c7";
}

.fa-ski-lift:before {
  content: "\f7c8";
}

.fa-skiing:before {
  content: "\f7c9";
}

.fa-skiing-nordic:before {
  content: "\f7ca";
}

.fa-skull:before {
  content: "\f54c";
}

.fa-skull-cow:before {
  content: "\f8de";
}

.fa-skull-crossbones:before {
  content: "\f714";
}

.fa-skyatlas:before {
  content: "\f216";
}

.fa-skype:before {
  content: "\f17e";
}

.fa-slack:before {
  content: "\f198";
}

.fa-slack-hash:before {
  content: "\f3ef";
}

.fa-slash:before {
  content: "\f715";
}

.fa-sledding:before {
  content: "\f7cb";
}

.fa-sleigh:before {
  content: "\f7cc";
}

.fa-sliders-h:before {
  content: "\f1de";
}

.fa-sliders-h-square:before {
  content: "\f3f0";
}

.fa-sliders-v:before {
  content: "\f3f1";
}

.fa-sliders-v-square:before {
  content: "\f3f2";
}

.fa-slideshare:before {
  content: "\f1e7";
}

.fa-smile:before {
  content: "\f118";
}

.fa-smile-beam:before {
  content: "\f5b8";
}

.fa-smile-plus:before {
  content: "\f5b9";
}

.fa-smile-wink:before {
  content: "\f4da";
}

.fa-smog:before {
  content: "\f75f";
}

.fa-smoke:before {
  content: "\f760";
}

.fa-smoking:before {
  content: "\f48d";
}

.fa-smoking-ban:before {
  content: "\f54d";
}

.fa-sms:before {
  content: "\f7cd";
}

.fa-snake:before {
  content: "\f716";
}

.fa-snapchat:before {
  content: "\f2ab";
}

.fa-snapchat-ghost:before {
  content: "\f2ac";
}

.fa-snapchat-square:before {
  content: "\f2ad";
}

.fa-snooze:before {
  content: "\f880";
}

.fa-snow-blowing:before {
  content: "\f761";
}

.fa-snowboarding:before {
  content: "\f7ce";
}

.fa-snowflake:before {
  content: "\f2dc";
}

.fa-snowflakes:before {
  content: "\f7cf";
}

.fa-snowman:before {
  content: "\f7d0";
}

.fa-snowmobile:before {
  content: "\f7d1";
}

.fa-snowplow:before {
  content: "\f7d2";
}

.fa-soap:before {
  content: "\e06e";
}

.fa-socks:before {
  content: "\f696";
}

.fa-solar-panel:before {
  content: "\f5ba";
}

.fa-solar-system:before {
  content: "\e02f";
}

.fa-sort:before {
  content: "\f0dc";
}

.fa-sort-alpha-down:before {
  content: "\f15d";
}

.fa-sort-alpha-down-alt:before {
  content: "\f881";
}

.fa-sort-alpha-up:before {
  content: "\f15e";
}

.fa-sort-alpha-up-alt:before {
  content: "\f882";
}

.fa-sort-alt:before {
  content: "\f883";
}

.fa-sort-amount-down:before {
  content: "\f160";
}

.fa-sort-amount-down-alt:before {
  content: "\f884";
}

.fa-sort-amount-up:before {
  content: "\f161";
}

.fa-sort-amount-up-alt:before {
  content: "\f885";
}

.fa-sort-circle:before {
  content: "\e030";
}

.fa-sort-circle-down:before {
  content: "\e031";
}

.fa-sort-circle-up:before {
  content: "\e032";
}

.fa-sort-down:before {
  content: "\f0dd";
}

.fa-sort-numeric-down:before {
  content: "\f162";
}

.fa-sort-numeric-down-alt:before {
  content: "\f886";
}

.fa-sort-numeric-up:before {
  content: "\f163";
}

.fa-sort-numeric-up-alt:before {
  content: "\f887";
}

.fa-sort-shapes-down:before {
  content: "\f888";
}

.fa-sort-shapes-down-alt:before {
  content: "\f889";
}

.fa-sort-shapes-up:before {
  content: "\f88a";
}

.fa-sort-shapes-up-alt:before {
  content: "\f88b";
}

.fa-sort-size-down:before {
  content: "\f88c";
}

.fa-sort-size-down-alt:before {
  content: "\f88d";
}

.fa-sort-size-up:before {
  content: "\f88e";
}

.fa-sort-size-up-alt:before {
  content: "\f88f";
}

.fa-sort-up:before {
  content: "\f0de";
}

.fa-soundcloud:before {
  content: "\f1be";
}

.fa-soup:before {
  content: "\f823";
}

.fa-sourcetree:before {
  content: "\f7d3";
}

.fa-spa:before {
  content: "\f5bb";
}

.fa-space-shuttle:before {
  content: "\f197";
}

.fa-space-station-moon:before {
  content: "\e033";
}

.fa-space-station-moon-alt:before {
  content: "\e034";
}

.fa-spade:before {
  content: "\f2f4";
}

.fa-sparkles:before {
  content: "\f890";
}

.fa-speakap:before {
  content: "\f3f3";
}

.fa-speaker:before {
  content: "\f8df";
}

.fa-speaker-deck:before {
  content: "\f83c";
}

.fa-speakers:before {
  content: "\f8e0";
}

.fa-spell-check:before {
  content: "\f891";
}

.fa-spider:before {
  content: "\f717";
}

.fa-spider-black-widow:before {
  content: "\f718";
}

.fa-spider-web:before {
  content: "\f719";
}

.fa-spinner:before {
  content: "\f110";
}

.fa-spinner-third:before {
  content: "\f3f4";
}

.fa-splotch:before {
  content: "\f5bc";
}

.fa-spotify:before {
  content: "\f1bc";
}

.fa-spray-can:before {
  content: "\f5bd";
}

.fa-sprinkler:before {
  content: "\e035";
}

.fa-square:before {
  content: "\f0c8";
}

.fa-square-full:before {
  content: "\f45c";
}

.fa-square-root:before {
  content: "\f697";
}

.fa-square-root-alt:before {
  content: "\f698";
}

.fa-squarespace:before {
  content: "\f5be";
}

.fa-squirrel:before {
  content: "\f71a";
}

.fa-stack-exchange:before {
  content: "\f18d";
}

.fa-stack-overflow:before {
  content: "\f16c";
}

.fa-stackpath:before {
  content: "\f842";
}

.fa-staff:before {
  content: "\f71b";
}

.fa-stamp:before {
  content: "\f5bf";
}

.fa-star:before {
  content: "\f005";
}

.fa-star-and-crescent:before {
  content: "\f699";
}

.fa-star-christmas:before {
  content: "\f7d4";
}

.fa-star-exclamation:before {
  content: "\f2f3";
}

.fa-star-half:before {
  content: "\f089";
}

.fa-star-half-alt:before {
  content: "\f5c0";
}

.fa-star-of-david:before {
  content: "\f69a";
}

.fa-star-of-life:before {
  content: "\f621";
}

.fa-star-shooting:before {
  content: "\e036";
}

.fa-starfighter:before {
  content: "\e037";
}

.fa-starfighter-alt:before {
  content: "\e038";
}

.fa-stars:before {
  content: "\f762";
}

.fa-starship:before {
  content: "\e039";
}

.fa-starship-freighter:before {
  content: "\e03a";
}

.fa-staylinked:before {
  content: "\f3f5";
}

.fa-steak:before {
  content: "\f824";
}

.fa-steam:before {
  content: "\f1b6";
}

.fa-steam-square:before {
  content: "\f1b7";
}

.fa-steam-symbol:before {
  content: "\f3f6";
}

.fa-steering-wheel:before {
  content: "\f622";
}

.fa-step-backward:before {
  content: "\f048";
}

.fa-step-forward:before {
  content: "\f051";
}

.fa-stethoscope:before {
  content: "\f0f1";
}

.fa-sticker-mule:before {
  content: "\f3f7";
}

.fa-sticky-note:before {
  content: "\f249";
}

.fa-stocking:before {
  content: "\f7d5";
}

.fa-stomach:before {
  content: "\f623";
}

.fa-stop:before {
  content: "\f04d";
}

.fa-stop-circle:before {
  content: "\f28d";
}

.fa-stopwatch:before {
  content: "\f2f2";
}

.fa-stopwatch-20:before {
  content: "\e06f";
}

.fa-store:before {
  content: "\f54e";
}

.fa-store-alt:before {
  content: "\f54f";
}

.fa-store-alt-slash:before {
  content: "\e070";
}

.fa-store-slash:before {
  content: "\e071";
}

.fa-strava:before {
  content: "\f428";
}

.fa-stream:before {
  content: "\f550";
}

.fa-street-view:before {
  content: "\f21d";
}

.fa-stretcher:before {
  content: "\f825";
}

.fa-strikethrough:before {
  content: "\f0cc";
}

.fa-stripe:before {
  content: "\f429";
}

.fa-stripe-s:before {
  content: "\f42a";
}

.fa-stroopwafel:before {
  content: "\f551";
}

.fa-studiovinari:before {
  content: "\f3f8";
}

.fa-stumbleupon:before {
  content: "\f1a4";
}

.fa-stumbleupon-circle:before {
  content: "\f1a3";
}

.fa-subscript:before {
  content: "\f12c";
}

.fa-subway:before {
  content: "\f239";
}

.fa-suitcase:before {
  content: "\f0f2";
}

.fa-suitcase-rolling:before {
  content: "\f5c1";
}

.fa-sun:before {
  content: "\f185";
}

.fa-sun-cloud:before {
  content: "\f763";
}

.fa-sun-dust:before {
  content: "\f764";
}

.fa-sun-haze:before {
  content: "\f765";
}

.fa-sunglasses:before {
  content: "\f892";
}

.fa-sunrise:before {
  content: "\f766";
}

.fa-sunset:before {
  content: "\f767";
}

.fa-superpowers:before {
  content: "\f2dd";
}

.fa-superscript:before {
  content: "\f12b";
}

.fa-supple:before {
  content: "\f3f9";
}

.fa-surprise:before {
  content: "\f5c2";
}

.fa-suse:before {
  content: "\f7d6";
}

.fa-swatchbook:before {
  content: "\f5c3";
}

.fa-swift:before {
  content: "\f8e1";
}

.fa-swimmer:before {
  content: "\f5c4";
}

.fa-swimming-pool:before {
  content: "\f5c5";
}

.fa-sword:before {
  content: "\f71c";
}

.fa-sword-laser:before {
  content: "\e03b";
}

.fa-sword-laser-alt:before {
  content: "\e03c";
}

.fa-swords:before {
  content: "\f71d";
}

.fa-swords-laser:before {
  content: "\e03d";
}

.fa-symfony:before {
  content: "\f83d";
}

.fa-synagogue:before {
  content: "\f69b";
}

.fa-sync:before {
  content: "\f021";
}

.fa-sync-alt:before {
  content: "\f2f1";
}

.fa-syringe:before {
  content: "\f48e";
}

.fa-table:before {
  content: "\f0ce";
}

.fa-table-tennis:before {
  content: "\f45d";
}

.fa-tablet:before {
  content: "\f10a";
}

.fa-tablet-alt:before {
  content: "\f3fa";
}

.fa-tablet-android:before {
  content: "\f3fb";
}

.fa-tablet-android-alt:before {
  content: "\f3fc";
}

.fa-tablet-rugged:before {
  content: "\f48f";
}

.fa-tablets:before {
  content: "\f490";
}

.fa-tachometer:before {
  content: "\f0e4";
}

.fa-tachometer-alt:before {
  content: "\f3fd";
}

.fa-tachometer-alt-average:before {
  content: "\f624";
}

.fa-tachometer-alt-fast:before {
  content: "\f625";
}

.fa-tachometer-alt-fastest:before {
  content: "\f626";
}

.fa-tachometer-alt-slow:before {
  content: "\f627";
}

.fa-tachometer-alt-slowest:before {
  content: "\f628";
}

.fa-tachometer-average:before {
  content: "\f629";
}

.fa-tachometer-fast:before {
  content: "\f62a";
}

.fa-tachometer-fastest:before {
  content: "\f62b";
}

.fa-tachometer-slow:before {
  content: "\f62c";
}

.fa-tachometer-slowest:before {
  content: "\f62d";
}

.fa-taco:before {
  content: "\f826";
}

.fa-tag:before {
  content: "\f02b";
}

.fa-tags:before {
  content: "\f02c";
}

.fa-tally:before {
  content: "\f69c";
}

.fa-tanakh:before {
  content: "\f827";
}

.fa-tape:before {
  content: "\f4db";
}

.fa-tasks:before {
  content: "\f0ae";
}

.fa-tasks-alt:before {
  content: "\f828";
}

.fa-taxi:before {
  content: "\f1ba";
}

.fa-teamspeak:before {
  content: "\f4f9";
}

.fa-teeth:before {
  content: "\f62e";
}

.fa-teeth-open:before {
  content: "\f62f";
}

.fa-telegram:before {
  content: "\f2c6";
}

.fa-telegram-plane:before {
  content: "\f3fe";
}

.fa-telescope:before {
  content: "\e03e";
}

.fa-temperature-down:before {
  content: "\e03f";
}

.fa-temperature-frigid:before {
  content: "\f768";
}

.fa-temperature-high:before {
  content: "\f769";
}

.fa-temperature-hot:before {
  content: "\f76a";
}

.fa-temperature-low:before {
  content: "\f76b";
}

.fa-temperature-up:before {
  content: "\e040";
}

.fa-tencent-weibo:before {
  content: "\f1d5";
}

.fa-tenge:before {
  content: "\f7d7";
}

.fa-tennis-ball:before {
  content: "\f45e";
}

.fa-terminal:before {
  content: "\f120";
}

.fa-text:before {
  content: "\f893";
}

.fa-text-height:before {
  content: "\f034";
}

.fa-text-size:before {
  content: "\f894";
}

.fa-text-width:before {
  content: "\f035";
}

.fa-th:before {
  content: "\f00a";
}

.fa-th-large:before {
  content: "\f009";
}

.fa-th-list:before {
  content: "\f00b";
}

.fa-the-red-yeti:before {
  content: "\f69d";
}

.fa-theater-masks:before {
  content: "\f630";
}

.fa-themeco:before {
  content: "\f5c6";
}

.fa-themeisle:before {
  content: "\f2b2";
}

.fa-thermometer:before {
  content: "\f491";
}

.fa-thermometer-empty:before {
  content: "\f2cb";
}

.fa-thermometer-full:before {
  content: "\f2c7";
}

.fa-thermometer-half:before {
  content: "\f2c9";
}

.fa-thermometer-quarter:before {
  content: "\f2ca";
}

.fa-thermometer-three-quarters:before {
  content: "\f2c8";
}

.fa-theta:before {
  content: "\f69e";
}

.fa-think-peaks:before {
  content: "\f731";
}

.fa-thumbs-down:before {
  content: "\f165";
}

.fa-thumbs-up:before {
  content: "\f164";
}

.fa-thumbtack:before {
  content: "\f08d";
}

.fa-thunderstorm:before {
  content: "\f76c";
}

.fa-thunderstorm-moon:before {
  content: "\f76d";
}

.fa-thunderstorm-sun:before {
  content: "\f76e";
}

.fa-ticket:before {
  content: "\f145";
}

.fa-ticket-alt:before {
  content: "\f3ff";
}

.fa-tiktok:before {
  content: "\e07b";
}

.fa-tilde:before {
  content: "\f69f";
}

.fa-times:before {
  content: "\f00d";
}

.fa-times-circle:before {
  content: "\f057";
}

.fa-times-hexagon:before {
  content: "\f2ee";
}

.fa-times-octagon:before {
  content: "\f2f0";
}

.fa-times-square:before {
  content: "\f2d3";
}

.fa-tint:before {
  content: "\f043";
}

.fa-tint-slash:before {
  content: "\f5c7";
}

.fa-tire:before {
  content: "\f631";
}

.fa-tire-flat:before {
  content: "\f632";
}

.fa-tire-pressure-warning:before {
  content: "\f633";
}

.fa-tire-rugged:before {
  content: "\f634";
}

.fa-tired:before {
  content: "\f5c8";
}

.fa-toggle-off:before {
  content: "\f204";
}

.fa-toggle-on:before {
  content: "\f205";
}

.fa-toilet:before {
  content: "\f7d8";
}

.fa-toilet-paper:before {
  content: "\f71e";
}

.fa-toilet-paper-alt:before {
  content: "\f71f";
}

.fa-toilet-paper-slash:before {
  content: "\e072";
}

.fa-tombstone:before {
  content: "\f720";
}

.fa-tombstone-alt:before {
  content: "\f721";
}

.fa-toolbox:before {
  content: "\f552";
}

.fa-tools:before {
  content: "\f7d9";
}

.fa-tooth:before {
  content: "\f5c9";
}

.fa-toothbrush:before {
  content: "\f635";
}

.fa-torah:before {
  content: "\f6a0";
}

.fa-torii-gate:before {
  content: "\f6a1";
}

.fa-tornado:before {
  content: "\f76f";
}

.fa-tractor:before {
  content: "\f722";
}

.fa-trade-federation:before {
  content: "\f513";
}

.fa-trademark:before {
  content: "\f25c";
}

.fa-traffic-cone:before {
  content: "\f636";
}

.fa-traffic-light:before {
  content: "\f637";
}

.fa-traffic-light-go:before {
  content: "\f638";
}

.fa-traffic-light-slow:before {
  content: "\f639";
}

.fa-traffic-light-stop:before {
  content: "\f63a";
}

.fa-trailer:before {
  content: "\e041";
}

.fa-train:before {
  content: "\f238";
}

.fa-tram:before {
  content: "\f7da";
}

.fa-transgender:before {
  content: "\f224";
}

.fa-transgender-alt:before {
  content: "\f225";
}

.fa-transporter:before {
  content: "\e042";
}

.fa-transporter-1:before {
  content: "\e043";
}

.fa-transporter-2:before {
  content: "\e044";
}

.fa-transporter-3:before {
  content: "\e045";
}

.fa-transporter-empty:before {
  content: "\e046";
}

.fa-trash:before {
  content: "\f1f8";
}

.fa-trash-alt:before {
  content: "\f2ed";
}

.fa-trash-restore:before {
  content: "\f829";
}

.fa-trash-restore-alt:before {
  content: "\f82a";
}

.fa-trash-undo:before {
  content: "\f895";
}

.fa-trash-undo-alt:before {
  content: "\f896";
}

.fa-treasure-chest:before {
  content: "\f723";
}

.fa-tree:before {
  content: "\f1bb";
}

.fa-tree-alt:before {
  content: "\f400";
}

.fa-tree-christmas:before {
  content: "\f7db";
}

.fa-tree-decorated:before {
  content: "\f7dc";
}

.fa-tree-large:before {
  content: "\f7dd";
}

.fa-tree-palm:before {
  content: "\f82b";
}

.fa-trees:before {
  content: "\f724";
}

.fa-trello:before {
  content: "\f181";
}

.fa-triangle:before {
  content: "\f2ec";
}

.fa-triangle-music:before {
  content: "\f8e2";
}

.fa-tripadvisor:before {
  content: "\f262";
}

.fa-trophy:before {
  content: "\f091";
}

.fa-trophy-alt:before {
  content: "\f2eb";
}

.fa-truck:before {
  content: "\f0d1";
}

.fa-truck-container:before {
  content: "\f4dc";
}

.fa-truck-couch:before {
  content: "\f4dd";
}

.fa-truck-loading:before {
  content: "\f4de";
}

.fa-truck-monster:before {
  content: "\f63b";
}

.fa-truck-moving:before {
  content: "\f4df";
}

.fa-truck-pickup:before {
  content: "\f63c";
}

.fa-truck-plow:before {
  content: "\f7de";
}

.fa-truck-ramp:before {
  content: "\f4e0";
}

.fa-trumpet:before {
  content: "\f8e3";
}

.fa-tshirt:before {
  content: "\f553";
}

.fa-tty:before {
  content: "\f1e4";
}

.fa-tumblr:before {
  content: "\f173";
}

.fa-tumblr-square:before {
  content: "\f174";
}

.fa-turkey:before {
  content: "\f725";
}

.fa-turntable:before {
  content: "\f8e4";
}

.fa-turtle:before {
  content: "\f726";
}

.fa-tv:before {
  content: "\f26c";
}

.fa-tv-alt:before {
  content: "\f8e5";
}

.fa-tv-music:before {
  content: "\f8e6";
}

.fa-tv-retro:before {
  content: "\f401";
}

.fa-twitch:before {
  content: "\f1e8";
}

.fa-twitter:before {
  content: "\f099";
}

.fa-twitter-square:before {
  content: "\f081";
}

.fa-typewriter:before {
  content: "\f8e7";
}

.fa-typo3:before {
  content: "\f42b";
}

.fa-uber:before {
  content: "\f402";
}

.fa-ubuntu:before {
  content: "\f7df";
}

.fa-ufo:before {
  content: "\e047";
}

.fa-ufo-beam:before {
  content: "\e048";
}

.fa-uikit:before {
  content: "\f403";
}

.fa-umbraco:before {
  content: "\f8e8";
}

.fa-umbrella:before {
  content: "\f0e9";
}

.fa-umbrella-beach:before {
  content: "\f5ca";
}

.fa-uncharted:before {
  content: "\e084";
}

.fa-underline:before {
  content: "\f0cd";
}

.fa-undo:before {
  content: "\f0e2";
}

.fa-undo-alt:before {
  content: "\f2ea";
}

.fa-unicorn:before {
  content: "\f727";
}

.fa-union:before {
  content: "\f6a2";
}

.fa-uniregistry:before {
  content: "\f404";
}

.fa-unity:before {
  content: "\e049";
}

.fa-universal-access:before {
  content: "\f29a";
}

.fa-university:before {
  content: "\f19c";
}

.fa-unlink:before {
  content: "\f127";
}

.fa-unlock:before {
  content: "\f09c";
}

.fa-unlock-alt:before {
  content: "\f13e";
}

.fa-unsplash:before {
  content: "\e07c";
}

.fa-untappd:before {
  content: "\f405";
}

.fa-upload:before {
  content: "\f093";
}

.fa-ups:before {
  content: "\f7e0";
}

.fa-usb:before {
  content: "\f287";
}

.fa-usb-drive:before {
  content: "\f8e9";
}

.fa-usd-circle:before {
  content: "\f2e8";
}

.fa-usd-square:before {
  content: "\f2e9";
}

.fa-user:before {
  content: "\f007";
}

.fa-user-alien:before {
  content: "\e04a";
}

.fa-user-alt:before {
  content: "\f406";
}

.fa-user-alt-slash:before {
  content: "\f4fa";
}

.fa-user-astronaut:before {
  content: "\f4fb";
}

.fa-user-chart:before {
  content: "\f6a3";
}

.fa-user-check:before {
  content: "\f4fc";
}

.fa-user-circle:before {
  content: "\f2bd";
}

.fa-user-clock:before {
  content: "\f4fd";
}

.fa-user-cog:before {
  content: "\f4fe";
}

.fa-user-cowboy:before {
  content: "\f8ea";
}

.fa-user-crown:before {
  content: "\f6a4";
}

.fa-user-edit:before {
  content: "\f4ff";
}

.fa-user-friends:before {
  content: "\f500";
}

.fa-user-graduate:before {
  content: "\f501";
}

.fa-user-hard-hat:before {
  content: "\f82c";
}

.fa-user-headset:before {
  content: "\f82d";
}

.fa-user-injured:before {
  content: "\f728";
}

.fa-user-lock:before {
  content: "\f502";
}

.fa-user-md:before {
  content: "\f0f0";
}

.fa-user-md-chat:before {
  content: "\f82e";
}

.fa-user-minus:before {
  content: "\f503";
}

.fa-user-music:before {
  content: "\f8eb";
}

.fa-user-ninja:before {
  content: "\f504";
}

.fa-user-nurse:before {
  content: "\f82f";
}

.fa-user-plus:before {
  content: "\f234";
}

.fa-user-robot:before {
  content: "\e04b";
}

.fa-user-secret:before {
  content: "\f21b";
}

.fa-user-shield:before {
  content: "\f505";
}

.fa-user-slash:before {
  content: "\f506";
}

.fa-user-tag:before {
  content: "\f507";
}

.fa-user-tie:before {
  content: "\f508";
}

.fa-user-times:before {
  content: "\f235";
}

.fa-user-unlock:before {
  content: "\e058";
}

.fa-user-visor:before {
  content: "\e04c";
}

.fa-users:before {
  content: "\f0c0";
}

.fa-users-class:before {
  content: "\f63d";
}

.fa-users-cog:before {
  content: "\f509";
}

.fa-users-crown:before {
  content: "\f6a5";
}

.fa-users-medical:before {
  content: "\f830";
}

.fa-users-slash:before {
  content: "\e073";
}

.fa-usps:before {
  content: "\f7e1";
}

.fa-ussunnah:before {
  content: "\f407";
}

.fa-utensil-fork:before {
  content: "\f2e3";
}

.fa-utensil-knife:before {
  content: "\f2e4";
}

.fa-utensil-spoon:before {
  content: "\f2e5";
}

.fa-utensils:before {
  content: "\f2e7";
}

.fa-utensils-alt:before {
  content: "\f2e6";
}

.fa-vaadin:before {
  content: "\f408";
}

.fa-vacuum:before {
  content: "\e04d";
}

.fa-vacuum-robot:before {
  content: "\e04e";
}

.fa-value-absolute:before {
  content: "\f6a6";
}

.fa-vector-square:before {
  content: "\f5cb";
}

.fa-venus:before {
  content: "\f221";
}

.fa-venus-double:before {
  content: "\f226";
}

.fa-venus-mars:before {
  content: "\f228";
}

.fa-vest:before {
  content: "\e085";
}

.fa-vest-patches:before {
  content: "\e086";
}

.fa-vhs:before {
  content: "\f8ec";
}

.fa-viacoin:before {
  content: "\f237";
}

.fa-viadeo:before {
  content: "\f2a9";
}

.fa-viadeo-square:before {
  content: "\f2aa";
}

.fa-vial:before {
  content: "\f492";
}

.fa-vials:before {
  content: "\f493";
}

.fa-viber:before {
  content: "\f409";
}

.fa-video:before {
  content: "\f03d";
}

.fa-video-plus:before {
  content: "\f4e1";
}

.fa-video-slash:before {
  content: "\f4e2";
}

.fa-vihara:before {
  content: "\f6a7";
}

.fa-vimeo:before {
  content: "\f40a";
}

.fa-vimeo-square:before {
  content: "\f194";
}

.fa-vimeo-v:before {
  content: "\f27d";
}

.fa-vine:before {
  content: "\f1ca";
}

.fa-violin:before {
  content: "\f8ed";
}

.fa-virus:before {
  content: "\e074";
}

.fa-virus-slash:before {
  content: "\e075";
}

.fa-viruses:before {
  content: "\e076";
}

.fa-vk:before {
  content: "\f189";
}

.fa-vnv:before {
  content: "\f40b";
}

.fa-voicemail:before {
  content: "\f897";
}

.fa-volcano:before {
  content: "\f770";
}

.fa-volleyball-ball:before {
  content: "\f45f";
}

.fa-volume:before {
  content: "\f6a8";
}

.fa-volume-down:before {
  content: "\f027";
}

.fa-volume-mute:before {
  content: "\f6a9";
}

.fa-volume-off:before {
  content: "\f026";
}

.fa-volume-slash:before {
  content: "\f2e2";
}

.fa-volume-up:before {
  content: "\f028";
}

.fa-vote-nay:before {
  content: "\f771";
}

.fa-vote-yea:before {
  content: "\f772";
}

.fa-vr-cardboard:before {
  content: "\f729";
}

.fa-vuejs:before {
  content: "\f41f";
}

.fa-wagon-covered:before {
  content: "\f8ee";
}

.fa-walker:before {
  content: "\f831";
}

.fa-walkie-talkie:before {
  content: "\f8ef";
}

.fa-walking:before {
  content: "\f554";
}

.fa-wallet:before {
  content: "\f555";
}

.fa-wand:before {
  content: "\f72a";
}

.fa-wand-magic:before {
  content: "\f72b";
}

.fa-warehouse:before {
  content: "\f494";
}

.fa-warehouse-alt:before {
  content: "\f495";
}

.fa-washer:before {
  content: "\f898";
}

.fa-watch:before {
  content: "\f2e1";
}

.fa-watch-calculator:before {
  content: "\f8f0";
}

.fa-watch-fitness:before {
  content: "\f63e";
}

.fa-watchman-monitoring:before {
  content: "\e087";
}

.fa-water:before {
  content: "\f773";
}

.fa-water-lower:before {
  content: "\f774";
}

.fa-water-rise:before {
  content: "\f775";
}

.fa-wave-sine:before {
  content: "\f899";
}

.fa-wave-square:before {
  content: "\f83e";
}

.fa-wave-triangle:before {
  content: "\f89a";
}

.fa-waveform:before {
  content: "\f8f1";
}

.fa-waveform-path:before {
  content: "\f8f2";
}

.fa-waze:before {
  content: "\f83f";
}

.fa-webcam:before {
  content: "\f832";
}

.fa-webcam-slash:before {
  content: "\f833";
}

.fa-weebly:before {
  content: "\f5cc";
}

.fa-weibo:before {
  content: "\f18a";
}

.fa-weight:before {
  content: "\f496";
}

.fa-weight-hanging:before {
  content: "\f5cd";
}

.fa-weixin:before {
  content: "\f1d7";
}

.fa-whale:before {
  content: "\f72c";
}

.fa-whatsapp:before {
  content: "\f232";
}

.fa-whatsapp-square:before {
  content: "\f40c";
}

.fa-wheat:before {
  content: "\f72d";
}

.fa-wheelchair:before {
  content: "\f193";
}

.fa-whistle:before {
  content: "\f460";
}

.fa-whmcs:before {
  content: "\f40d";
}

.fa-wifi:before {
  content: "\f1eb";
}

.fa-wifi-1:before {
  content: "\f6aa";
}

.fa-wifi-2:before {
  content: "\f6ab";
}

.fa-wifi-slash:before {
  content: "\f6ac";
}

.fa-wikipedia-w:before {
  content: "\f266";
}

.fa-wind:before {
  content: "\f72e";
}

.fa-wind-turbine:before {
  content: "\f89b";
}

.fa-wind-warning:before {
  content: "\f776";
}

.fa-window:before {
  content: "\f40e";
}

.fa-window-alt:before {
  content: "\f40f";
}

.fa-window-close:before {
  content: "\f410";
}

.fa-window-frame:before {
  content: "\e04f";
}

.fa-window-frame-open:before {
  content: "\e050";
}

.fa-window-maximize:before {
  content: "\f2d0";
}

.fa-window-minimize:before {
  content: "\f2d1";
}

.fa-window-restore:before {
  content: "\f2d2";
}

.fa-windows:before {
  content: "\f17a";
}

.fa-windsock:before {
  content: "\f777";
}

.fa-wine-bottle:before {
  content: "\f72f";
}

.fa-wine-glass:before {
  content: "\f4e3";
}

.fa-wine-glass-alt:before {
  content: "\f5ce";
}

.fa-wix:before {
  content: "\f5cf";
}

.fa-wizards-of-the-coast:before {
  content: "\f730";
}

.fa-wodu:before {
  content: "\e088";
}

.fa-wolf-pack-battalion:before {
  content: "\f514";
}

.fa-won-sign:before {
  content: "\f159";
}

.fa-wordpress:before {
  content: "\f19a";
}

.fa-wordpress-simple:before {
  content: "\f411";
}

.fa-wpbeginner:before {
  content: "\f297";
}

.fa-wpexplorer:before {
  content: "\f2de";
}

.fa-wpforms:before {
  content: "\f298";
}

.fa-wpressr:before {
  content: "\f3e4";
}

.fa-wreath:before {
  content: "\f7e2";
}

.fa-wrench:before {
  content: "\f0ad";
}

.fa-x-ray:before {
  content: "\f497";
}

.fa-xbox:before {
  content: "\f412";
}

.fa-xing:before {
  content: "\f168";
}

.fa-xing-square:before {
  content: "\f169";
}

.fa-y-combinator:before {
  content: "\f23b";
}

.fa-yahoo:before {
  content: "\f19e";
}

.fa-yammer:before {
  content: "\f840";
}

.fa-yandex:before {
  content: "\f413";
}

.fa-yandex-international:before {
  content: "\f414";
}

.fa-yarn:before {
  content: "\f7e3";
}

.fa-yelp:before {
  content: "\f1e9";
}

.fa-yen-sign:before {
  content: "\f157";
}

.fa-yin-yang:before {
  content: "\f6ad";
}

.fa-yoast:before {
  content: "\f2b1";
}

.fa-youtube:before {
  content: "\f167";
}

.fa-youtube-square:before {
  content: "\f431";
}

.fa-zhihu:before {
  content: "\f63f";
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

/*!
 * Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/FontAwesome/fa-brands-400.eot");
  src: url("../fonts/FontAwesome/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("../fonts/FontAwesome/fa-brands-400.woff2") format("woff2"), url("../fonts/FontAwesome/fa-brands-400.woff") format("woff"), url("../fonts/FontAwesome/fa-brands-400.ttf") format("truetype"), url("../fonts/FontAwesome/fa-brands-400.svg#fontawesome") format("svg");
}

.fab {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

/*!
 * Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("../fonts/FontAwesome/fa-light-300.eot");
  src: url("../fonts/FontAwesome/fa-light-300.eot?#iefix") format("embedded-opentype"), url("../fonts/FontAwesome/fa-light-300.woff2") format("woff2"), url("../fonts/FontAwesome/fa-light-300.woff") format("woff"), url("../fonts/FontAwesome/fa-light-300.ttf") format("truetype"), url("../fonts/FontAwesome/fa-light-300.svg#fontawesome") format("svg");
}

.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
}

/*!
 * Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/FontAwesome/fa-regular-400.eot");
  src: url("../fonts/FontAwesome/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("../fonts/FontAwesome/fa-regular-400.woff2") format("woff2"), url("../fonts/FontAwesome/fa-regular-400.woff") format("woff"), url("../fonts/FontAwesome/fa-regular-400.ttf") format("truetype"), url("../fonts/FontAwesome/fa-regular-400.svg#fontawesome") format("svg");
}

.far {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

/*!
 * Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../fonts/FontAwesome/fa-solid-900.eot");
  src: url("../fonts/FontAwesome/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../fonts/FontAwesome/fa-solid-900.woff2") format("woff2"), url("../fonts/FontAwesome/fa-solid-900.woff") format("woff"), url("../fonts/FontAwesome/fa-solid-900.ttf") format("truetype"), url("../fonts/FontAwesome/fa-solid-900.svg#fontawesome") format("svg");
}

.fa,
.fas {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
}

/*!
 * Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
.fa.fa-glass:before {
  content: "\f000";
}

.fa.fa-meetup {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-star-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-star-o:before {
  content: "\f005";
}

.fa.fa-remove:before {
  content: "\f00d";
}

.fa.fa-close:before {
  content: "\f00d";
}

.fa.fa-gear:before {
  content: "\f013";
}

.fa.fa-trash-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-trash-o:before {
  content: "\f2ed";
}

.fa.fa-file-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-file-o:before {
  content: "\f15b";
}

.fa.fa-clock-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-clock-o:before {
  content: "\f017";
}

.fa.fa-arrow-circle-o-down {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-arrow-circle-o-down:before {
  content: "\f358";
}

.fa.fa-arrow-circle-o-up {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-arrow-circle-o-up:before {
  content: "\f35b";
}

.fa.fa-play-circle-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-play-circle-o:before {
  content: "\f144";
}

.fa.fa-repeat:before {
  content: "\f01e";
}

.fa.fa-rotate-right:before {
  content: "\f01e";
}

.fa.fa-refresh:before {
  content: "\f021";
}

.fa.fa-list-alt {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-dedent:before {
  content: "\f03b";
}

.fa.fa-video-camera:before {
  content: "\f03d";
}

.fa.fa-picture-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-picture-o:before {
  content: "\f03e";
}

.fa.fa-photo {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-photo:before {
  content: "\f03e";
}

.fa.fa-image {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-image:before {
  content: "\f03e";
}

.fa.fa-pencil:before {
  content: "\f303";
}

.fa.fa-map-marker:before {
  content: "\f3c5";
}

.fa.fa-pencil-square-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-pencil-square-o:before {
  content: "\f044";
}

.fa.fa-share-square-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-share-square-o:before {
  content: "\f14d";
}

.fa.fa-check-square-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-check-square-o:before {
  content: "\f14a";
}

.fa.fa-arrows:before {
  content: "\f0b2";
}

.fa.fa-times-circle-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-times-circle-o:before {
  content: "\f057";
}

.fa.fa-check-circle-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-check-circle-o:before {
  content: "\f058";
}

.fa.fa-mail-forward:before {
  content: "\f064";
}

.fa.fa-expand:before {
  content: "\f424";
}

.fa.fa-compress:before {
  content: "\f422";
}

.fa.fa-eye {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-eye-slash {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-warning:before {
  content: "\f071";
}

.fa.fa-calendar:before {
  content: "\f073";
}

.fa.fa-arrows-v:before {
  content: "\f338";
}

.fa.fa-arrows-h:before {
  content: "\f337";
}

.fa.fa-bar-chart {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-bar-chart:before {
  content: "\f080";
}

.fa.fa-bar-chart-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-bar-chart-o:before {
  content: "\f080";
}

.fa.fa-twitter-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-facebook-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-gears:before {
  content: "\f085";
}

.fa.fa-thumbs-o-up {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-thumbs-o-up:before {
  content: "\f164";
}

.fa.fa-thumbs-o-down {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-thumbs-o-down:before {
  content: "\f165";
}

.fa.fa-heart-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-heart-o:before {
  content: "\f004";
}

.fa.fa-sign-out:before {
  content: "\f2f5";
}

.fa.fa-linkedin-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-linkedin-square:before {
  content: "\f08c";
}

.fa.fa-thumb-tack:before {
  content: "\f08d";
}

.fa.fa-external-link:before {
  content: "\f35d";
}

.fa.fa-sign-in:before {
  content: "\f2f6";
}

.fa.fa-github-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-lemon-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-lemon-o:before {
  content: "\f094";
}

.fa.fa-square-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-square-o:before {
  content: "\f0c8";
}

.fa.fa-bookmark-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-bookmark-o:before {
  content: "\f02e";
}

.fa.fa-twitter {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-facebook {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-facebook:before {
  content: "\f39e";
}

.fa.fa-facebook-f {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-facebook-f:before {
  content: "\f39e";
}

.fa.fa-github {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-credit-card {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-feed:before {
  content: "\f09e";
}

.fa.fa-hdd-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-hdd-o:before {
  content: "\f0a0";
}

.fa.fa-hand-o-right {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-hand-o-right:before {
  content: "\f0a4";
}

.fa.fa-hand-o-left {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-hand-o-left:before {
  content: "\f0a5";
}

.fa.fa-hand-o-up {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-hand-o-up:before {
  content: "\f0a6";
}

.fa.fa-hand-o-down {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-hand-o-down:before {
  content: "\f0a7";
}

.fa.fa-arrows-alt:before {
  content: "\f31e";
}

.fa.fa-group:before {
  content: "\f0c0";
}

.fa.fa-chain:before {
  content: "\f0c1";
}

.fa.fa-scissors:before {
  content: "\f0c4";
}

.fa.fa-files-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-files-o:before {
  content: "\f0c5";
}

.fa.fa-floppy-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-floppy-o:before {
  content: "\f0c7";
}

.fa.fa-navicon:before {
  content: "\f0c9";
}

.fa.fa-reorder:before {
  content: "\f0c9";
}

.fa.fa-pinterest {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-pinterest-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-google-plus-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-google-plus {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-google-plus:before {
  content: "\f0d5";
}

.fa.fa-money {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-money:before {
  content: "\f3d1";
}

.fa.fa-unsorted:before {
  content: "\f0dc";
}

.fa.fa-sort-desc:before {
  content: "\f0dd";
}

.fa.fa-sort-asc:before {
  content: "\f0de";
}

.fa.fa-linkedin {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-linkedin:before {
  content: "\f0e1";
}

.fa.fa-rotate-left:before {
  content: "\f0e2";
}

.fa.fa-legal:before {
  content: "\f0e3";
}

.fa.fa-tachometer:before {
  content: "\f3fd";
}

.fa.fa-dashboard:before {
  content: "\f3fd";
}

.fa.fa-comment-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-comment-o:before {
  content: "\f075";
}

.fa.fa-comments-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-comments-o:before {
  content: "\f086";
}

.fa.fa-flash:before {
  content: "\f0e7";
}

.fa.fa-clipboard {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-paste {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-paste:before {
  content: "\f328";
}

.fa.fa-lightbulb-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-lightbulb-o:before {
  content: "\f0eb";
}

.fa.fa-exchange:before {
  content: "\f362";
}

.fa.fa-cloud-download:before {
  content: "\f381";
}

.fa.fa-cloud-upload:before {
  content: "\f382";
}

.fa.fa-bell-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-bell-o:before {
  content: "\f0f3";
}

.fa.fa-cutlery:before {
  content: "\f2e7";
}

.fa.fa-file-text-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-file-text-o:before {
  content: "\f15c";
}

.fa.fa-building-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-building-o:before {
  content: "\f1ad";
}

.fa.fa-hospital-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-hospital-o:before {
  content: "\f0f8";
}

.fa.fa-tablet:before {
  content: "\f3fa";
}

.fa.fa-mobile:before {
  content: "\f3cd";
}

.fa.fa-mobile-phone:before {
  content: "\f3cd";
}

.fa.fa-circle-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-circle-o:before {
  content: "\f111";
}

.fa.fa-mail-reply:before {
  content: "\f3e5";
}

.fa.fa-github-alt {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-folder-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-folder-o:before {
  content: "\f07b";
}

.fa.fa-folder-open-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-folder-open-o:before {
  content: "\f07c";
}

.fa.fa-smile-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-smile-o:before {
  content: "\f118";
}

.fa.fa-frown-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-frown-o:before {
  content: "\f119";
}

.fa.fa-meh-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-meh-o:before {
  content: "\f11a";
}

.fa.fa-keyboard-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-keyboard-o:before {
  content: "\f11c";
}

.fa.fa-flag-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-flag-o:before {
  content: "\f024";
}

.fa.fa-mail-reply-all:before {
  content: "\f122";
}

.fa.fa-star-half-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-star-half-o:before {
  content: "\f089";
}

.fa.fa-star-half-empty {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-star-half-empty:before {
  content: "\f089";
}

.fa.fa-star-half-full {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-star-half-full:before {
  content: "\f089";
}

.fa.fa-code-fork:before {
  content: "\f126";
}

.fa.fa-chain-broken:before {
  content: "\f127";
}

.fa.fa-shield:before {
  content: "\f3ed";
}

.fa.fa-calendar-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-calendar-o:before {
  content: "\f133";
}

.fa.fa-maxcdn {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-html5 {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-css3 {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-ticket:before {
  content: "\f3ff";
}

.fa.fa-minus-square-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-minus-square-o:before {
  content: "\f146";
}

.fa.fa-level-up:before {
  content: "\f3bf";
}

.fa.fa-level-down:before {
  content: "\f3be";
}

.fa.fa-pencil-square:before {
  content: "\f14b";
}

.fa.fa-external-link-square:before {
  content: "\f360";
}

.fa.fa-compass {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-caret-square-o-down {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-caret-square-o-down:before {
  content: "\f150";
}

.fa.fa-toggle-down {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-toggle-down:before {
  content: "\f150";
}

.fa.fa-caret-square-o-up {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-caret-square-o-up:before {
  content: "\f151";
}

.fa.fa-toggle-up {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-toggle-up:before {
  content: "\f151";
}

.fa.fa-caret-square-o-right {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-caret-square-o-right:before {
  content: "\f152";
}

.fa.fa-toggle-right {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-toggle-right:before {
  content: "\f152";
}

.fa.fa-eur:before {
  content: "\f153";
}

.fa.fa-euro:before {
  content: "\f153";
}

.fa.fa-gbp:before {
  content: "\f154";
}

.fa.fa-usd:before {
  content: "\f155";
}

.fa.fa-dollar:before {
  content: "\f155";
}

.fa.fa-inr:before {
  content: "\f156";
}

.fa.fa-rupee:before {
  content: "\f156";
}

.fa.fa-jpy:before {
  content: "\f157";
}

.fa.fa-cny:before {
  content: "\f157";
}

.fa.fa-rmb:before {
  content: "\f157";
}

.fa.fa-yen:before {
  content: "\f157";
}

.fa.fa-rub:before {
  content: "\f158";
}

.fa.fa-ruble:before {
  content: "\f158";
}

.fa.fa-rouble:before {
  content: "\f158";
}

.fa.fa-krw:before {
  content: "\f159";
}

.fa.fa-won:before {
  content: "\f159";
}

.fa.fa-btc {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-bitcoin {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-bitcoin:before {
  content: "\f15a";
}

.fa.fa-file-text:before {
  content: "\f15c";
}

.fa.fa-sort-alpha-asc:before {
  content: "\f15d";
}

.fa.fa-sort-alpha-desc:before {
  content: "\f881";
}

.fa.fa-sort-amount-asc:before {
  content: "\f160";
}

.fa.fa-sort-amount-desc:before {
  content: "\f884";
}

.fa.fa-sort-numeric-asc:before {
  content: "\f162";
}

.fa.fa-sort-numeric-desc:before {
  content: "\f886";
}

.fa.fa-youtube-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-youtube {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-xing {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-xing-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-youtube-play {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-youtube-play:before {
  content: "\f167";
}

.fa.fa-dropbox {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-stack-overflow {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-instagram {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-flickr {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-adn {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-bitbucket {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-bitbucket-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-bitbucket-square:before {
  content: "\f171";
}

.fa.fa-tumblr {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-tumblr-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-long-arrow-down:before {
  content: "\f309";
}

.fa.fa-long-arrow-up:before {
  content: "\f30c";
}

.fa.fa-long-arrow-left:before {
  content: "\f30a";
}

.fa.fa-long-arrow-right:before {
  content: "\f30b";
}

.fa.fa-apple {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-windows {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-android {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-linux {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-dribbble {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-skype {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-foursquare {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-trello {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-gratipay {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-gittip {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-gittip:before {
  content: "\f184";
}

.fa.fa-sun-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-sun-o:before {
  content: "\f185";
}

.fa.fa-moon-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-moon-o:before {
  content: "\f186";
}

.fa.fa-vk {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-weibo {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-renren {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-pagelines {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-stack-exchange {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-arrow-circle-o-right {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-arrow-circle-o-right:before {
  content: "\f35a";
}

.fa.fa-arrow-circle-o-left {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-arrow-circle-o-left:before {
  content: "\f359";
}

.fa.fa-caret-square-o-left {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-caret-square-o-left:before {
  content: "\f191";
}

.fa.fa-toggle-left {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-toggle-left:before {
  content: "\f191";
}

.fa.fa-dot-circle-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-dot-circle-o:before {
  content: "\f192";
}

.fa.fa-vimeo-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-try:before {
  content: "\f195";
}

.fa.fa-turkish-lira:before {
  content: "\f195";
}

.fa.fa-plus-square-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-plus-square-o:before {
  content: "\f0fe";
}

.fa.fa-slack {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-wordpress {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-openid {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-institution:before {
  content: "\f19c";
}

.fa.fa-bank:before {
  content: "\f19c";
}

.fa.fa-mortar-board:before {
  content: "\f19d";
}

.fa.fa-yahoo {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-google {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-reddit {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-reddit-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-stumbleupon-circle {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-stumbleupon {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-delicious {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-digg {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-pied-piper-pp {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-pied-piper-alt {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-drupal {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-joomla {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-spoon:before {
  content: "\f2e5";
}

.fa.fa-behance {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-behance-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-steam {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-steam-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-automobile:before {
  content: "\f1b9";
}

.fa.fa-envelope-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-envelope-o:before {
  content: "\f0e0";
}

.fa.fa-spotify {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-deviantart {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-soundcloud {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-file-pdf-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-file-pdf-o:before {
  content: "\f1c1";
}

.fa.fa-file-word-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-file-word-o:before {
  content: "\f1c2";
}

.fa.fa-file-excel-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-file-excel-o:before {
  content: "\f1c3";
}

.fa.fa-file-powerpoint-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-file-powerpoint-o:before {
  content: "\f1c4";
}

.fa.fa-file-image-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-file-image-o:before {
  content: "\f1c5";
}

.fa.fa-file-photo-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-file-photo-o:before {
  content: "\f1c5";
}

.fa.fa-file-picture-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-file-picture-o:before {
  content: "\f1c5";
}

.fa.fa-file-archive-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-file-archive-o:before {
  content: "\f1c6";
}

.fa.fa-file-zip-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-file-zip-o:before {
  content: "\f1c6";
}

.fa.fa-file-audio-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-file-audio-o:before {
  content: "\f1c7";
}

.fa.fa-file-sound-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-file-sound-o:before {
  content: "\f1c7";
}

.fa.fa-file-video-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-file-video-o:before {
  content: "\f1c8";
}

.fa.fa-file-movie-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-file-movie-o:before {
  content: "\f1c8";
}

.fa.fa-file-code-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-file-code-o:before {
  content: "\f1c9";
}

.fa.fa-vine {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-codepen {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-jsfiddle {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-life-ring {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-life-bouy {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-life-bouy:before {
  content: "\f1cd";
}

.fa.fa-life-buoy {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-life-buoy:before {
  content: "\f1cd";
}

.fa.fa-life-saver {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-life-saver:before {
  content: "\f1cd";
}

.fa.fa-support {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-support:before {
  content: "\f1cd";
}

.fa.fa-circle-o-notch:before {
  content: "\f1ce";
}

.fa.fa-rebel {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-ra {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-ra:before {
  content: "\f1d0";
}

.fa.fa-resistance {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-resistance:before {
  content: "\f1d0";
}

.fa.fa-empire {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-ge {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-ge:before {
  content: "\f1d1";
}

.fa.fa-git-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-git {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-hacker-news {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-y-combinator-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-y-combinator-square:before {
  content: "\f1d4";
}

.fa.fa-yc-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-yc-square:before {
  content: "\f1d4";
}

.fa.fa-tencent-weibo {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-qq {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-weixin {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-wechat {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-wechat:before {
  content: "\f1d7";
}

.fa.fa-send:before {
  content: "\f1d8";
}

.fa.fa-paper-plane-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-paper-plane-o:before {
  content: "\f1d8";
}

.fa.fa-send-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-send-o:before {
  content: "\f1d8";
}

.fa.fa-circle-thin {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-circle-thin:before {
  content: "\f111";
}

.fa.fa-header:before {
  content: "\f1dc";
}

.fa.fa-sliders:before {
  content: "\f1de";
}

.fa.fa-futbol-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-futbol-o:before {
  content: "\f1e3";
}

.fa.fa-soccer-ball-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-soccer-ball-o:before {
  content: "\f1e3";
}

.fa.fa-slideshare {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-twitch {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-yelp {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-newspaper-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-newspaper-o:before {
  content: "\f1ea";
}

.fa.fa-paypal {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-google-wallet {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-cc-visa {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-cc-mastercard {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-cc-discover {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-cc-amex {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-cc-paypal {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-cc-stripe {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-bell-slash-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-bell-slash-o:before {
  content: "\f1f6";
}

.fa.fa-trash:before {
  content: "\f2ed";
}

.fa.fa-copyright {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-eyedropper:before {
  content: "\f1fb";
}

.fa.fa-area-chart:before {
  content: "\f1fe";
}

.fa.fa-pie-chart:before {
  content: "\f200";
}

.fa.fa-line-chart:before {
  content: "\f201";
}

.fa.fa-lastfm {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-lastfm-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-ioxhost {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-angellist {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-cc {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-cc:before {
  content: "\f20a";
}

.fa.fa-ils:before {
  content: "\f20b";
}

.fa.fa-shekel:before {
  content: "\f20b";
}

.fa.fa-sheqel:before {
  content: "\f20b";
}

.fa.fa-meanpath {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-meanpath:before {
  content: "\f2b4";
}

.fa.fa-buysellads {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-connectdevelop {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-dashcube {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-forumbee {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-leanpub {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-sellsy {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-shirtsinbulk {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-simplybuilt {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-skyatlas {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-diamond {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-diamond:before {
  content: "\f3a5";
}

.fa.fa-intersex:before {
  content: "\f224";
}

.fa.fa-facebook-official {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-facebook-official:before {
  content: "\f09a";
}

.fa.fa-pinterest-p {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-whatsapp {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-hotel:before {
  content: "\f236";
}

.fa.fa-viacoin {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-medium {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-y-combinator {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-yc {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-yc:before {
  content: "\f23b";
}

.fa.fa-optin-monster {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-opencart {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-expeditedssl {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-battery-4:before {
  content: "\f240";
}

.fa.fa-battery:before {
  content: "\f240";
}

.fa.fa-battery-3:before {
  content: "\f241";
}

.fa.fa-battery-2:before {
  content: "\f242";
}

.fa.fa-battery-1:before {
  content: "\f243";
}

.fa.fa-battery-0:before {
  content: "\f244";
}

.fa.fa-object-group {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-object-ungroup {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-sticky-note-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-sticky-note-o:before {
  content: "\f249";
}

.fa.fa-cc-jcb {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-cc-diners-club {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-clone {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-hourglass-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-hourglass-o:before {
  content: "\f254";
}

.fa.fa-hourglass-1:before {
  content: "\f251";
}

.fa.fa-hourglass-2:before {
  content: "\f252";
}

.fa.fa-hourglass-3:before {
  content: "\f253";
}

.fa.fa-hand-rock-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-hand-rock-o:before {
  content: "\f255";
}

.fa.fa-hand-grab-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-hand-grab-o:before {
  content: "\f255";
}

.fa.fa-hand-paper-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-hand-paper-o:before {
  content: "\f256";
}

.fa.fa-hand-stop-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-hand-stop-o:before {
  content: "\f256";
}

.fa.fa-hand-scissors-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-hand-scissors-o:before {
  content: "\f257";
}

.fa.fa-hand-lizard-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-hand-lizard-o:before {
  content: "\f258";
}

.fa.fa-hand-spock-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-hand-spock-o:before {
  content: "\f259";
}

.fa.fa-hand-pointer-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-hand-pointer-o:before {
  content: "\f25a";
}

.fa.fa-hand-peace-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-hand-peace-o:before {
  content: "\f25b";
}

.fa.fa-registered {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-creative-commons {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-gg {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-gg-circle {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-tripadvisor {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-odnoklassniki {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-odnoklassniki-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-get-pocket {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-wikipedia-w {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-safari {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-chrome {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-firefox {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-opera {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-internet-explorer {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-television:before {
  content: "\f26c";
}

.fa.fa-contao {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-500px {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-amazon {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-calendar-plus-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-calendar-plus-o:before {
  content: "\f271";
}

.fa.fa-calendar-minus-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-calendar-minus-o:before {
  content: "\f272";
}

.fa.fa-calendar-times-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-calendar-times-o:before {
  content: "\f273";
}

.fa.fa-calendar-check-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-calendar-check-o:before {
  content: "\f274";
}

.fa.fa-map-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-map-o:before {
  content: "\f279";
}

.fa.fa-commenting:before {
  content: "\f4ad";
}

.fa.fa-commenting-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-commenting-o:before {
  content: "\f4ad";
}

.fa.fa-houzz {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-vimeo {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-vimeo:before {
  content: "\f27d";
}

.fa.fa-black-tie {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-fonticons {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-reddit-alien {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-edge {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-credit-card-alt:before {
  content: "\f09d";
}

.fa.fa-codiepie {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-modx {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-fort-awesome {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-usb {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-product-hunt {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-mixcloud {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-scribd {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-pause-circle-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-pause-circle-o:before {
  content: "\f28b";
}

.fa.fa-stop-circle-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-stop-circle-o:before {
  content: "\f28d";
}

.fa.fa-bluetooth {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-bluetooth-b {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-gitlab {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-wpbeginner {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-wpforms {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-envira {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-wheelchair-alt {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-wheelchair-alt:before {
  content: "\f368";
}

.fa.fa-question-circle-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-question-circle-o:before {
  content: "\f059";
}

.fa.fa-volume-control-phone:before {
  content: "\f2a0";
}

.fa.fa-asl-interpreting:before {
  content: "\f2a3";
}

.fa.fa-deafness:before {
  content: "\f2a4";
}

.fa.fa-hard-of-hearing:before {
  content: "\f2a4";
}

.fa.fa-glide {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-glide-g {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-signing:before {
  content: "\f2a7";
}

.fa.fa-viadeo {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-viadeo-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-snapchat {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-snapchat-ghost {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-snapchat-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-pied-piper {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-first-order {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-yoast {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-themeisle {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-google-plus-official {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-google-plus-official:before {
  content: "\f2b3";
}

.fa.fa-google-plus-circle {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-google-plus-circle:before {
  content: "\f2b3";
}

.fa.fa-font-awesome {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-fa {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-fa:before {
  content: "\f2b4";
}

.fa.fa-handshake-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-handshake-o:before {
  content: "\f2b5";
}

.fa.fa-envelope-open-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-envelope-open-o:before {
  content: "\f2b6";
}

.fa.fa-linode {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-address-book-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-address-book-o:before {
  content: "\f2b9";
}

.fa.fa-vcard:before {
  content: "\f2bb";
}

.fa.fa-address-card-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-address-card-o:before {
  content: "\f2bb";
}

.fa.fa-vcard-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-vcard-o:before {
  content: "\f2bb";
}

.fa.fa-user-circle-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-user-circle-o:before {
  content: "\f2bd";
}

.fa.fa-user-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-user-o:before {
  content: "\f007";
}

.fa.fa-id-badge {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-drivers-license:before {
  content: "\f2c2";
}

.fa.fa-id-card-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-id-card-o:before {
  content: "\f2c2";
}

.fa.fa-drivers-license-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-drivers-license-o:before {
  content: "\f2c2";
}

.fa.fa-quora {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-free-code-camp {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-telegram {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-thermometer-4:before {
  content: "\f2c7";
}

.fa.fa-thermometer:before {
  content: "\f2c7";
}

.fa.fa-thermometer-3:before {
  content: "\f2c8";
}

.fa.fa-thermometer-2:before {
  content: "\f2c9";
}

.fa.fa-thermometer-1:before {
  content: "\f2ca";
}

.fa.fa-thermometer-0:before {
  content: "\f2cb";
}

.fa.fa-bathtub:before {
  content: "\f2cd";
}

.fa.fa-s15:before {
  content: "\f2cd";
}

.fa.fa-window-maximize {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-window-restore {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-times-rectangle:before {
  content: "\f410";
}

.fa.fa-window-close-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-window-close-o:before {
  content: "\f410";
}

.fa.fa-times-rectangle-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-times-rectangle-o:before {
  content: "\f410";
}

.fa.fa-bandcamp {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-grav {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-etsy {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-imdb {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-ravelry {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-eercast {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-eercast:before {
  content: "\f2da";
}

.fa.fa-snowflake-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-snowflake-o:before {
  content: "\f2dc";
}

.fa.fa-superpowers {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-wpexplorer {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-cab:before {
  content: "\f1ba";
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Gotham Book", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.4;
  color: #333333;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: none !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #51917a;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #366051;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 1rem;
  font-family: "Gotham", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: bold;
  line-height: 1.1;
  color: inherit;
}

h1, .h1 {
  font-size: 2.05rem;
}

h2, .h2 {
  font-size: 1.22rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: normal;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 5px;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #868e96;
}

.blockquote-footer::before {
  content: "\2014 \00A0";
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}

@media (min-width: 576px) {
  .container {
    max-width: 546px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
}

.btn {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  border-radius: 0.25rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn:focus, .btn:hover {
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 3px rgba(81, 145, 122, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: .65;
}

.btn:active, .btn.active {
  background-image: none;
}

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #51917a;
  border-color: #51917a;
}

.btn-primary:hover {
  color: #fff;
  background-color: #437865;
  border-color: #3f705e;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 3px rgba(81, 145, 122, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  background-color: #51917a;
  border-color: #51917a;
}

.btn-primary:active, .btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  background-color: #437865;
  background-image: none;
  border-color: #3f705e;
}

.btn-secondary {
  color: #fff;
  background-color: #f16464;
  border-color: #f16464;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #ee4141;
  border-color: #ed3535;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 3px rgba(241, 100, 100, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  background-color: #f16464;
  border-color: #f16464;
}

.btn-secondary:active, .btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  background-color: #ee4141;
  background-image: none;
  border-color: #ed3535;
}

.btn-red {
  color: #fff;
  background-color: #f16464;
  border-color: #f16464;
}

.btn-red:hover {
  color: #fff;
  background-color: #ee4141;
  border-color: #ed3535;
}

.btn-red:focus, .btn-red.focus {
  box-shadow: 0 0 0 3px rgba(241, 100, 100, 0.5);
}

.btn-red.disabled, .btn-red:disabled {
  background-color: #f16464;
  border-color: #f16464;
}

.btn-red:active, .btn-red.active,
.show > .btn-red.dropdown-toggle {
  background-color: #ee4141;
  background-image: none;
  border-color: #ed3535;
}

.btn-red-light {
  color: #111;
  background-color: #f59289;
  border-color: #f59289;
}

.btn-red-light:hover {
  color: #111;
  background-color: #f27166;
  border-color: #f1675a;
}

.btn-red-light:focus, .btn-red-light.focus {
  box-shadow: 0 0 0 3px rgba(245, 146, 137, 0.5);
}

.btn-red-light.disabled, .btn-red-light:disabled {
  background-color: #f59289;
  border-color: #f59289;
}

.btn-red-light:active, .btn-red-light.active,
.show > .btn-red-light.dropdown-toggle {
  background-color: #f27166;
  background-image: none;
  border-color: #f1675a;
}

.btn-green {
  color: #fff;
  background-color: #51917a;
  border-color: #51917a;
}

.btn-green:hover {
  color: #fff;
  background-color: #437865;
  border-color: #3f705e;
}

.btn-green:focus, .btn-green.focus {
  box-shadow: 0 0 0 3px rgba(81, 145, 122, 0.5);
}

.btn-green.disabled, .btn-green:disabled {
  background-color: #51917a;
  border-color: #51917a;
}

.btn-green:active, .btn-green.active,
.show > .btn-green.dropdown-toggle {
  background-color: #437865;
  background-image: none;
  border-color: #3f705e;
}

.btn-green-light {
  color: #111;
  background-color: #7cab97;
  border-color: #7cab97;
}

.btn-green-light:hover {
  color: #111;
  background-color: #659c84;
  border-color: #5f957e;
}

.btn-green-light:focus, .btn-green-light.focus {
  box-shadow: 0 0 0 3px rgba(124, 171, 151, 0.5);
}

.btn-green-light.disabled, .btn-green-light:disabled {
  background-color: #7cab97;
  border-color: #7cab97;
}

.btn-green-light:active, .btn-green-light.active,
.show > .btn-green-light.dropdown-toggle {
  background-color: #659c84;
  background-image: none;
  border-color: #5f957e;
}

.btn-pink {
  color: #111;
  background-color: #f37f87;
  border-color: #f37f87;
}

.btn-pink:hover {
  color: #111;
  background-color: #f05c66;
  border-color: #ef505b;
}

.btn-pink:focus, .btn-pink.focus {
  box-shadow: 0 0 0 3px rgba(243, 127, 135, 0.5);
}

.btn-pink.disabled, .btn-pink:disabled {
  background-color: #f37f87;
  border-color: #f37f87;
}

.btn-pink:active, .btn-pink.active,
.show > .btn-pink.dropdown-toggle {
  background-color: #f05c66;
  background-image: none;
  border-color: #ef505b;
}

.btn-pink-light {
  color: #111;
  background-color: #eec6b8;
  border-color: #eec6b8;
}

.btn-pink-light:hover {
  color: #111;
  background-color: #e7ad99;
  border-color: #e4a58f;
}

.btn-pink-light:focus, .btn-pink-light.focus {
  box-shadow: 0 0 0 3px rgba(238, 198, 184, 0.5);
}

.btn-pink-light.disabled, .btn-pink-light:disabled {
  background-color: #eec6b8;
  border-color: #eec6b8;
}

.btn-pink-light:active, .btn-pink-light.active,
.show > .btn-pink-light.dropdown-toggle {
  background-color: #e7ad99;
  background-image: none;
  border-color: #e4a58f;
}

.btn-blue {
  color: #fff;
  background-color: #31667e;
  border-color: #31667e;
}

.btn-blue:hover {
  color: #fff;
  background-color: #265062;
  border-color: #234859;
}

.btn-blue:focus, .btn-blue.focus {
  box-shadow: 0 0 0 3px rgba(49, 102, 126, 0.5);
}

.btn-blue.disabled, .btn-blue:disabled {
  background-color: #31667e;
  border-color: #31667e;
}

.btn-blue:active, .btn-blue.active,
.show > .btn-blue.dropdown-toggle {
  background-color: #265062;
  background-image: none;
  border-color: #234859;
}

.btn-turquoise-dark {
  color: #fff;
  background-color: #0d3932;
  border-color: #0d3932;
}

.btn-turquoise-dark:hover {
  color: #fff;
  background-color: #061a17;
  border-color: #040f0e;
}

.btn-turquoise-dark:focus, .btn-turquoise-dark.focus {
  box-shadow: 0 0 0 3px rgba(13, 57, 50, 0.5);
}

.btn-turquoise-dark.disabled, .btn-turquoise-dark:disabled {
  background-color: #0d3932;
  border-color: #0d3932;
}

.btn-turquoise-dark:active, .btn-turquoise-dark.active,
.show > .btn-turquoise-dark.dropdown-toggle {
  background-color: #061a17;
  background-image: none;
  border-color: #040f0e;
}

.btn-turquoise-light {
  color: #111;
  background-color: #abc8ca;
  border-color: #abc8ca;
}

.btn-turquoise-light:hover {
  color: #111;
  background-color: #94b9bb;
  border-color: #8cb4b6;
}

.btn-turquoise-light:focus, .btn-turquoise-light.focus {
  box-shadow: 0 0 0 3px rgba(171, 200, 202, 0.5);
}

.btn-turquoise-light.disabled, .btn-turquoise-light:disabled {
  background-color: #abc8ca;
  border-color: #abc8ca;
}

.btn-turquoise-light:active, .btn-turquoise-light.active,
.show > .btn-turquoise-light.dropdown-toggle {
  background-color: #94b9bb;
  background-image: none;
  border-color: #8cb4b6;
}

.btn-turquoise-lighter {
  color: #111;
  background-color: #cfd8d9;
  border-color: #cfd8d9;
}

.btn-turquoise-lighter:hover {
  color: #111;
  background-color: #bac7c8;
  border-color: #b3c1c2;
}

.btn-turquoise-lighter:focus, .btn-turquoise-lighter.focus {
  box-shadow: 0 0 0 3px rgba(207, 216, 217, 0.5);
}

.btn-turquoise-lighter.disabled, .btn-turquoise-lighter:disabled {
  background-color: #cfd8d9;
  border-color: #cfd8d9;
}

.btn-turquoise-lighter:active, .btn-turquoise-lighter.active,
.show > .btn-turquoise-lighter.dropdown-toggle {
  background-color: #bac7c8;
  background-image: none;
  border-color: #b3c1c2;
}

.btn-turquoise-lightest {
  color: #111;
  background-color: #edeae1;
  border-color: #edeae1;
}

.btn-turquoise-lightest:hover {
  color: #111;
  background-color: #dfd9c9;
  border-color: #dad4c1;
}

.btn-turquoise-lightest:focus, .btn-turquoise-lightest.focus {
  box-shadow: 0 0 0 3px rgba(237, 234, 225, 0.5);
}

.btn-turquoise-lightest.disabled, .btn-turquoise-lightest:disabled {
  background-color: #edeae1;
  border-color: #edeae1;
}

.btn-turquoise-lightest:active, .btn-turquoise-lightest.active,
.show > .btn-turquoise-lightest.dropdown-toggle {
  background-color: #dfd9c9;
  background-image: none;
  border-color: #dad4c1;
}

.btn-success {
  color: #fff;
  background-color: #51917a;
  border-color: #51917a;
}

.btn-success:hover {
  color: #fff;
  background-color: #437865;
  border-color: #3f705e;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 3px rgba(81, 145, 122, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  background-color: #51917a;
  border-color: #51917a;
}

.btn-success:active, .btn-success.active,
.show > .btn-success.dropdown-toggle {
  background-color: #437865;
  background-image: none;
  border-color: #3f705e;
}

.btn-info {
  color: #fff;
  background-color: #31667e;
  border-color: #31667e;
}

.btn-info:hover {
  color: #fff;
  background-color: #265062;
  border-color: #234859;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 3px rgba(49, 102, 126, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  background-color: #31667e;
  border-color: #31667e;
}

.btn-info:active, .btn-info.active,
.show > .btn-info.dropdown-toggle {
  background-color: #265062;
  background-image: none;
  border-color: #234859;
}

.btn-warning {
  color: #111;
  background-color: #f37f87;
  border-color: #f37f87;
}

.btn-warning:hover {
  color: #111;
  background-color: #f05c66;
  border-color: #ef505b;
}

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 3px rgba(243, 127, 135, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  background-color: #f37f87;
  border-color: #f37f87;
}

.btn-warning:active, .btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  background-color: #f05c66;
  background-image: none;
  border-color: #ef505b;
}

.btn-danger {
  color: #fff;
  background-color: #f16464;
  border-color: #f16464;
}

.btn-danger:hover {
  color: #fff;
  background-color: #ee4141;
  border-color: #ed3535;
}

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 3px rgba(241, 100, 100, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  background-color: #f16464;
  border-color: #f16464;
}

.btn-danger:active, .btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  background-color: #ee4141;
  background-image: none;
  border-color: #ed3535;
}

.btn-light {
  color: #111;
  background-color: #edeae1;
  border-color: #edeae1;
}

.btn-light:hover {
  color: #111;
  background-color: #dfd9c9;
  border-color: #dad4c1;
}

.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 3px rgba(237, 234, 225, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  background-color: #edeae1;
  border-color: #edeae1;
}

.btn-light:active, .btn-light.active,
.show > .btn-light.dropdown-toggle {
  background-color: #dfd9c9;
  background-image: none;
  border-color: #dad4c1;
}

.btn-dark {
  color: #fff;
  background-color: #0d3932;
  border-color: #0d3932;
}

.btn-dark:hover {
  color: #fff;
  background-color: #061a17;
  border-color: #040f0e;
}

.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 3px rgba(13, 57, 50, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  background-color: #0d3932;
  border-color: #0d3932;
}

.btn-dark:active, .btn-dark.active,
.show > .btn-dark.dropdown-toggle {
  background-color: #061a17;
  background-image: none;
  border-color: #040f0e;
}

.btn-outline-primary {
  color: #51917a;
  background-color: transparent;
  background-image: none;
  border-color: #51917a;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #51917a;
  border-color: #51917a;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 3px rgba(81, 145, 122, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #51917a;
  background-color: transparent;
}

.btn-outline-primary:active, .btn-outline-primary.active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #51917a;
  border-color: #51917a;
}

.btn-outline-secondary {
  color: #f16464;
  background-color: transparent;
  background-image: none;
  border-color: #f16464;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #f16464;
  border-color: #f16464;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 3px rgba(241, 100, 100, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #f16464;
  background-color: transparent;
}

.btn-outline-secondary:active, .btn-outline-secondary.active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #f16464;
  border-color: #f16464;
}

.btn-outline-red {
  color: #f16464;
  background-color: transparent;
  background-image: none;
  border-color: #f16464;
}

.btn-outline-red:hover {
  color: #fff;
  background-color: #f16464;
  border-color: #f16464;
}

.btn-outline-red:focus, .btn-outline-red.focus {
  box-shadow: 0 0 0 3px rgba(241, 100, 100, 0.5);
}

.btn-outline-red.disabled, .btn-outline-red:disabled {
  color: #f16464;
  background-color: transparent;
}

.btn-outline-red:active, .btn-outline-red.active,
.show > .btn-outline-red.dropdown-toggle {
  color: #fff;
  background-color: #f16464;
  border-color: #f16464;
}

.btn-outline-red-light {
  color: #f59289;
  background-color: transparent;
  background-image: none;
  border-color: #f59289;
}

.btn-outline-red-light:hover {
  color: #fff;
  background-color: #f59289;
  border-color: #f59289;
}

.btn-outline-red-light:focus, .btn-outline-red-light.focus {
  box-shadow: 0 0 0 3px rgba(245, 146, 137, 0.5);
}

.btn-outline-red-light.disabled, .btn-outline-red-light:disabled {
  color: #f59289;
  background-color: transparent;
}

.btn-outline-red-light:active, .btn-outline-red-light.active,
.show > .btn-outline-red-light.dropdown-toggle {
  color: #fff;
  background-color: #f59289;
  border-color: #f59289;
}

.btn-outline-green {
  color: #51917a;
  background-color: transparent;
  background-image: none;
  border-color: #51917a;
}

.btn-outline-green:hover {
  color: #fff;
  background-color: #51917a;
  border-color: #51917a;
}

.btn-outline-green:focus, .btn-outline-green.focus {
  box-shadow: 0 0 0 3px rgba(81, 145, 122, 0.5);
}

.btn-outline-green.disabled, .btn-outline-green:disabled {
  color: #51917a;
  background-color: transparent;
}

.btn-outline-green:active, .btn-outline-green.active,
.show > .btn-outline-green.dropdown-toggle {
  color: #fff;
  background-color: #51917a;
  border-color: #51917a;
}

.btn-outline-green-light {
  color: #7cab97;
  background-color: transparent;
  background-image: none;
  border-color: #7cab97;
}

.btn-outline-green-light:hover {
  color: #fff;
  background-color: #7cab97;
  border-color: #7cab97;
}

.btn-outline-green-light:focus, .btn-outline-green-light.focus {
  box-shadow: 0 0 0 3px rgba(124, 171, 151, 0.5);
}

.btn-outline-green-light.disabled, .btn-outline-green-light:disabled {
  color: #7cab97;
  background-color: transparent;
}

.btn-outline-green-light:active, .btn-outline-green-light.active,
.show > .btn-outline-green-light.dropdown-toggle {
  color: #fff;
  background-color: #7cab97;
  border-color: #7cab97;
}

.btn-outline-pink {
  color: #f37f87;
  background-color: transparent;
  background-image: none;
  border-color: #f37f87;
}

.btn-outline-pink:hover {
  color: #fff;
  background-color: #f37f87;
  border-color: #f37f87;
}

.btn-outline-pink:focus, .btn-outline-pink.focus {
  box-shadow: 0 0 0 3px rgba(243, 127, 135, 0.5);
}

.btn-outline-pink.disabled, .btn-outline-pink:disabled {
  color: #f37f87;
  background-color: transparent;
}

.btn-outline-pink:active, .btn-outline-pink.active,
.show > .btn-outline-pink.dropdown-toggle {
  color: #fff;
  background-color: #f37f87;
  border-color: #f37f87;
}

.btn-outline-pink-light {
  color: #eec6b8;
  background-color: transparent;
  background-image: none;
  border-color: #eec6b8;
}

.btn-outline-pink-light:hover {
  color: #fff;
  background-color: #eec6b8;
  border-color: #eec6b8;
}

.btn-outline-pink-light:focus, .btn-outline-pink-light.focus {
  box-shadow: 0 0 0 3px rgba(238, 198, 184, 0.5);
}

.btn-outline-pink-light.disabled, .btn-outline-pink-light:disabled {
  color: #eec6b8;
  background-color: transparent;
}

.btn-outline-pink-light:active, .btn-outline-pink-light.active,
.show > .btn-outline-pink-light.dropdown-toggle {
  color: #fff;
  background-color: #eec6b8;
  border-color: #eec6b8;
}

.btn-outline-blue {
  color: #31667e;
  background-color: transparent;
  background-image: none;
  border-color: #31667e;
}

.btn-outline-blue:hover {
  color: #fff;
  background-color: #31667e;
  border-color: #31667e;
}

.btn-outline-blue:focus, .btn-outline-blue.focus {
  box-shadow: 0 0 0 3px rgba(49, 102, 126, 0.5);
}

.btn-outline-blue.disabled, .btn-outline-blue:disabled {
  color: #31667e;
  background-color: transparent;
}

.btn-outline-blue:active, .btn-outline-blue.active,
.show > .btn-outline-blue.dropdown-toggle {
  color: #fff;
  background-color: #31667e;
  border-color: #31667e;
}

.btn-outline-turquoise-dark {
  color: #0d3932;
  background-color: transparent;
  background-image: none;
  border-color: #0d3932;
}

.btn-outline-turquoise-dark:hover {
  color: #fff;
  background-color: #0d3932;
  border-color: #0d3932;
}

.btn-outline-turquoise-dark:focus, .btn-outline-turquoise-dark.focus {
  box-shadow: 0 0 0 3px rgba(13, 57, 50, 0.5);
}

.btn-outline-turquoise-dark.disabled, .btn-outline-turquoise-dark:disabled {
  color: #0d3932;
  background-color: transparent;
}

.btn-outline-turquoise-dark:active, .btn-outline-turquoise-dark.active,
.show > .btn-outline-turquoise-dark.dropdown-toggle {
  color: #fff;
  background-color: #0d3932;
  border-color: #0d3932;
}

.btn-outline-turquoise-light {
  color: #abc8ca;
  background-color: transparent;
  background-image: none;
  border-color: #abc8ca;
}

.btn-outline-turquoise-light:hover {
  color: #fff;
  background-color: #abc8ca;
  border-color: #abc8ca;
}

.btn-outline-turquoise-light:focus, .btn-outline-turquoise-light.focus {
  box-shadow: 0 0 0 3px rgba(171, 200, 202, 0.5);
}

.btn-outline-turquoise-light.disabled, .btn-outline-turquoise-light:disabled {
  color: #abc8ca;
  background-color: transparent;
}

.btn-outline-turquoise-light:active, .btn-outline-turquoise-light.active,
.show > .btn-outline-turquoise-light.dropdown-toggle {
  color: #fff;
  background-color: #abc8ca;
  border-color: #abc8ca;
}

.btn-outline-turquoise-lighter {
  color: #cfd8d9;
  background-color: transparent;
  background-image: none;
  border-color: #cfd8d9;
}

.btn-outline-turquoise-lighter:hover {
  color: #fff;
  background-color: #cfd8d9;
  border-color: #cfd8d9;
}

.btn-outline-turquoise-lighter:focus, .btn-outline-turquoise-lighter.focus {
  box-shadow: 0 0 0 3px rgba(207, 216, 217, 0.5);
}

.btn-outline-turquoise-lighter.disabled, .btn-outline-turquoise-lighter:disabled {
  color: #cfd8d9;
  background-color: transparent;
}

.btn-outline-turquoise-lighter:active, .btn-outline-turquoise-lighter.active,
.show > .btn-outline-turquoise-lighter.dropdown-toggle {
  color: #fff;
  background-color: #cfd8d9;
  border-color: #cfd8d9;
}

.btn-outline-turquoise-lightest {
  color: #edeae1;
  background-color: transparent;
  background-image: none;
  border-color: #edeae1;
}

.btn-outline-turquoise-lightest:hover {
  color: #fff;
  background-color: #edeae1;
  border-color: #edeae1;
}

.btn-outline-turquoise-lightest:focus, .btn-outline-turquoise-lightest.focus {
  box-shadow: 0 0 0 3px rgba(237, 234, 225, 0.5);
}

.btn-outline-turquoise-lightest.disabled, .btn-outline-turquoise-lightest:disabled {
  color: #edeae1;
  background-color: transparent;
}

.btn-outline-turquoise-lightest:active, .btn-outline-turquoise-lightest.active,
.show > .btn-outline-turquoise-lightest.dropdown-toggle {
  color: #fff;
  background-color: #edeae1;
  border-color: #edeae1;
}

.btn-outline-success {
  color: #51917a;
  background-color: transparent;
  background-image: none;
  border-color: #51917a;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #51917a;
  border-color: #51917a;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 3px rgba(81, 145, 122, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #51917a;
  background-color: transparent;
}

.btn-outline-success:active, .btn-outline-success.active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #51917a;
  border-color: #51917a;
}

.btn-outline-info {
  color: #31667e;
  background-color: transparent;
  background-image: none;
  border-color: #31667e;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #31667e;
  border-color: #31667e;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 3px rgba(49, 102, 126, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #31667e;
  background-color: transparent;
}

.btn-outline-info:active, .btn-outline-info.active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #31667e;
  border-color: #31667e;
}

.btn-outline-warning {
  color: #f37f87;
  background-color: transparent;
  background-image: none;
  border-color: #f37f87;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #f37f87;
  border-color: #f37f87;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 3px rgba(243, 127, 135, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #f37f87;
  background-color: transparent;
}

.btn-outline-warning:active, .btn-outline-warning.active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #f37f87;
  border-color: #f37f87;
}

.btn-outline-danger {
  color: #f16464;
  background-color: transparent;
  background-image: none;
  border-color: #f16464;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #f16464;
  border-color: #f16464;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 3px rgba(241, 100, 100, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #f16464;
  background-color: transparent;
}

.btn-outline-danger:active, .btn-outline-danger.active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #f16464;
  border-color: #f16464;
}

.btn-outline-light {
  color: #edeae1;
  background-color: transparent;
  background-image: none;
  border-color: #edeae1;
}

.btn-outline-light:hover {
  color: #fff;
  background-color: #edeae1;
  border-color: #edeae1;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 3px rgba(237, 234, 225, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #edeae1;
  background-color: transparent;
}

.btn-outline-light:active, .btn-outline-light.active,
.show > .btn-outline-light.dropdown-toggle {
  color: #fff;
  background-color: #edeae1;
  border-color: #edeae1;
}

.btn-outline-dark {
  color: #0d3932;
  background-color: transparent;
  background-image: none;
  border-color: #0d3932;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #0d3932;
  border-color: #0d3932;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 3px rgba(13, 57, 50, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #0d3932;
  background-color: transparent;
}

.btn-outline-dark:active, .btn-outline-dark.active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #0d3932;
  border-color: #0d3932;
}

.btn-link {
  font-weight: normal;
  color: #51917a;
  border-radius: 0;
}

.btn-link, .btn-link:active, .btn-link.active, .btn-link:disabled {
  background-color: transparent;
}

.btn-link, .btn-link:focus, .btn-link:active {
  border-color: transparent;
  box-shadow: none;
}

.btn-link:hover {
  border-color: transparent;
}

.btn-link:focus, .btn-link:hover {
  color: #366051;
  text-decoration: underline;
  background-color: transparent;
}

.btn-link:disabled {
  color: #868e96;
}

.btn-link:disabled:focus, .btn-link:disabled:hover {
  text-decoration: none;
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}

.fade.show {
  opacity: 1;
}

.collapse {
  display: none;
}

.collapse.show {
  display: block;
}

tr.collapse.show {
  display: table-row;
}

tbody.collapse.show {
  display: table-row-group;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 2;
  color: #fff;
  background-color: #51917a;
  border-color: #51917a;
}

.page-item.disabled .page-link {
  color: #868e96;
  pointer-events: none;
  background-color: #fff;
  border-color: #ddd;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #51917a;
  background-color: #fff;
  border: 1px solid #ddd;
}

.page-link:focus, .page-link:hover {
  color: #366051;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #ddd;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.125rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #51917a !important;
}

a.bg-primary:focus, a.bg-primary:hover {
  background-color: #3f705e !important;
}

.bg-secondary {
  background-color: #f16464 !important;
}

a.bg-secondary:focus, a.bg-secondary:hover {
  background-color: #ed3535 !important;
}

.bg-red {
  background-color: #f16464 !important;
}

a.bg-red:focus, a.bg-red:hover {
  background-color: #ed3535 !important;
}

.bg-red-light {
  background-color: #f59289 !important;
}

a.bg-red-light:focus, a.bg-red-light:hover {
  background-color: #f1675a !important;
}

.bg-green {
  background-color: #51917a !important;
}

a.bg-green:focus, a.bg-green:hover {
  background-color: #3f705e !important;
}

.bg-green-light {
  background-color: #7cab97 !important;
}

a.bg-green-light:focus, a.bg-green-light:hover {
  background-color: #5f957e !important;
}

.bg-pink {
  background-color: #f37f87 !important;
}

a.bg-pink:focus, a.bg-pink:hover {
  background-color: #ef505b !important;
}

.bg-pink-light {
  background-color: #eec6b8 !important;
}

a.bg-pink-light:focus, a.bg-pink-light:hover {
  background-color: #e4a58f !important;
}

.bg-blue {
  background-color: #31667e !important;
}

a.bg-blue:focus, a.bg-blue:hover {
  background-color: #234859 !important;
}

.bg-turquoise-dark {
  background-color: #0d3932 !important;
}

a.bg-turquoise-dark:focus, a.bg-turquoise-dark:hover {
  background-color: #040f0e !important;
}

.bg-turquoise-light {
  background-color: #abc8ca !important;
}

a.bg-turquoise-light:focus, a.bg-turquoise-light:hover {
  background-color: #8cb4b6 !important;
}

.bg-turquoise-lighter {
  background-color: #cfd8d9 !important;
}

a.bg-turquoise-lighter:focus, a.bg-turquoise-lighter:hover {
  background-color: #b3c1c2 !important;
}

.bg-turquoise-lightest {
  background-color: #edeae1 !important;
}

a.bg-turquoise-lightest:focus, a.bg-turquoise-lightest:hover {
  background-color: #dad4c1 !important;
}

.bg-success {
  background-color: #51917a !important;
}

a.bg-success:focus, a.bg-success:hover {
  background-color: #3f705e !important;
}

.bg-info {
  background-color: #31667e !important;
}

a.bg-info:focus, a.bg-info:hover {
  background-color: #234859 !important;
}

.bg-warning {
  background-color: #f37f87 !important;
}

a.bg-warning:focus, a.bg-warning:hover {
  background-color: #ef505b !important;
}

.bg-danger {
  background-color: #f16464 !important;
}

a.bg-danger:focus, a.bg-danger:hover {
  background-color: #ed3535 !important;
}

.bg-light {
  background-color: #edeae1 !important;
}

a.bg-light:focus, a.bg-light:hover {
  background-color: #dad4c1 !important;
}

.bg-dark {
  background-color: #0d3932 !important;
}

a.bg-dark:focus, a.bg-dark:hover {
  background-color: #040f0e !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #e9ecef !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #51917a !important;
}

.border-secondary {
  border-color: #f16464 !important;
}

.border-red {
  border-color: #f16464 !important;
}

.border-red-light {
  border-color: #f59289 !important;
}

.border-green {
  border-color: #51917a !important;
}

.border-green-light {
  border-color: #7cab97 !important;
}

.border-pink {
  border-color: #f37f87 !important;
}

.border-pink-light {
  border-color: #eec6b8 !important;
}

.border-blue {
  border-color: #31667e !important;
}

.border-turquoise-dark {
  border-color: #0d3932 !important;
}

.border-turquoise-light {
  border-color: #abc8ca !important;
}

.border-turquoise-lighter {
  border-color: #cfd8d9 !important;
}

.border-turquoise-lightest {
  border-color: #edeae1 !important;
}

.border-success {
  border-color: #51917a !important;
}

.border-info {
  border-color: #31667e !important;
}

.border-warning {
  border-color: #f37f87 !important;
}

.border-danger {
  border-color: #f16464 !important;
}

.border-light {
  border-color: #edeae1 !important;
}

.border-dark {
  border-color: #0d3932 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50%;
}

.rounded-0 {
  border-radius: 0;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

.d-print-block {
  display: none !important;
}

@media print {
  .d-print-block {
    display: block !important;
  }
}

.d-print-inline {
  display: none !important;
}

@media print {
  .d-print-inline {
    display: inline !important;
  }
}

.d-print-inline-block {
  display: none !important;
}

@media print {
  .d-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: normal !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #51917a !important;
}

a.text-primary:focus, a.text-primary:hover {
  color: #3f705e !important;
}

.text-secondary {
  color: #f16464 !important;
}

a.text-secondary:focus, a.text-secondary:hover {
  color: #ed3535 !important;
}

.text-red {
  color: #f16464 !important;
}

a.text-red:focus, a.text-red:hover {
  color: #ed3535 !important;
}

.text-red-light {
  color: #f59289 !important;
}

a.text-red-light:focus, a.text-red-light:hover {
  color: #f1675a !important;
}

.text-green {
  color: #51917a !important;
}

a.text-green:focus, a.text-green:hover {
  color: #3f705e !important;
}

.text-green-light {
  color: #7cab97 !important;
}

a.text-green-light:focus, a.text-green-light:hover {
  color: #5f957e !important;
}

.text-pink {
  color: #f37f87 !important;
}

a.text-pink:focus, a.text-pink:hover {
  color: #ef505b !important;
}

.text-pink-light {
  color: #eec6b8 !important;
}

a.text-pink-light:focus, a.text-pink-light:hover {
  color: #e4a58f !important;
}

.text-blue {
  color: #31667e !important;
}

a.text-blue:focus, a.text-blue:hover {
  color: #234859 !important;
}

.text-turquoise-dark {
  color: #0d3932 !important;
}

a.text-turquoise-dark:focus, a.text-turquoise-dark:hover {
  color: #040f0e !important;
}

.text-turquoise-light {
  color: #abc8ca !important;
}

a.text-turquoise-light:focus, a.text-turquoise-light:hover {
  color: #8cb4b6 !important;
}

.text-turquoise-lighter {
  color: #cfd8d9 !important;
}

a.text-turquoise-lighter:focus, a.text-turquoise-lighter:hover {
  color: #b3c1c2 !important;
}

.text-turquoise-lightest {
  color: #edeae1 !important;
}

a.text-turquoise-lightest:focus, a.text-turquoise-lightest:hover {
  color: #dad4c1 !important;
}

.text-success {
  color: #51917a !important;
}

a.text-success:focus, a.text-success:hover {
  color: #3f705e !important;
}

.text-info {
  color: #31667e !important;
}

a.text-info:focus, a.text-info:hover {
  color: #234859 !important;
}

.text-warning {
  color: #f37f87 !important;
}

a.text-warning:focus, a.text-warning:hover {
  color: #ef505b !important;
}

.text-danger {
  color: #f16464 !important;
}

a.text-danger:focus, a.text-danger:hover {
  color: #ed3535 !important;
}

.text-light {
  color: #edeae1 !important;
}

a.text-light:focus, a.text-light:hover {
  color: #dad4c1 !important;
}

.text-dark {
  color: #0d3932 !important;
}

a.text-dark:focus, a.text-dark:hover {
  color: #040f0e !important;
}

.text-muted {
  color: #868e96 !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

body {
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  font-family: inherit;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

img {
  max-width: 100%;
}

.region-content:not(.paragraphs-item-wysiwyg) img {
  height: auto;
  width: 100%;
}

.field-type-text-long img.media-element {
  height: auto;
}

@font-face {
  font-family: Montserrat;
  font-weight: 300;
  src: url("../fonts/montserrat-light-webfont.woff2");
}

.form-item {
  margin: 0 0 1rem;
  border-radius: 0;
}

.form-item label {
  display: block;
  margin: 0 0 5px;
}

.form-item input:focus {
  outline: #f59289 auto 1px;
}

.form-item .error {
  border: 1px solid #D30200;
}

.form-item .description {
  color: #495057;
  font-size: 0.875rem;
  margin: 4px 0 0;
}

.form-text,
.form-textarea,
.form-select {
  background: #fbf2ef;
  border: 0;
  border-radius: 0;
  padding: .75rem;
  width: 100%;
}

.form-type-radio,
.form-type-checkbox {
  display: flex;
}

input[type="radio"],
input[type="checkbox"] {
  width: 30px;
  margin-top: 1px;
}

input[type='radio'] ~ label,
input[type='checkbox'] ~ label {
  position: relative;
  width: calc(100% - 20px);
  cursor: pointer;
}

input[type='radio'] ~ label::before,
input[type='checkbox'] ~ label::before {
  font-family: "Font Awesome 5 Pro";
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  z-index: 1;
  top: 0;
  left: -30px;
  background: #fbf2ef;
  line-height: 20px;
  text-align: center;
}

input[type='radio'] ~ label:hover::before,
input[type='checkbox'] ~ label:hover::before {
  color: #adb5bd;
}

input[type='radio'] ~ label::before {
  border-radius: 50%;
}

input[type='radio']:checked ~ label::before {
  content: '\f111';
  font-weight: bold;
}

input[type='checkbox']:checked ~ label::before {
  content: '\f00c';
  font-weight: normal;
}

.form-textarea-wrapper .grippie {
  display: none;
}

.webform-component-select {
  position: relative;
}

.webform-component-select::after {
  content: "v";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 2.9rem;
  height: 2.9rem;
  display: block;
  background: #f16464;
  color: #fbf2ef;
  font-weight: bold;
  line-height: 2.9rem;
  text-align: center;
  pointer-events: none;
}

.webform-component-select .form-select {
  appearance: none;
  cursor: pointer;
}

.captcha {
  margin-bottom: 1rem;
}

.form-submit {
  border: 0;
  border-radius: 0;
  background: #f16464;
  color: #fff;
  padding: .75rem;
  transition: background .35s ease;
  width: 100%;
}

.form-submit:hover {
  background: #fbf2ef;
  color: #f16464;
  cursor: pointer;
}

fieldset {
  border: 0;
  margin: 0 0 40px;
  padding: 0;
}

fieldset legend {
  margin: 0 0 15px;
  font-size: 1.8rem;
  line-height: 1.8rem;
  text-align: left;
}

.webform-client-form .form-actions {
  position: relative;
}

.webform-client-form .form-actions::after {
  content: ">";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 2.9rem;
  height: 2.9rem;
  display: block;
  background: #fbf2ef;
  color: #f16464;
  font-weight: bold;
  line-height: 2.9rem;
  text-align: center;
  pointer-events: none;
}

.webform-client-form .form-actions .form-submit {
  text-align: left;
}

.webform-client-form .form-actions .form-submit:first-of-type {
  padding-right: calc(.75rem + 2.9rem);
}

.webform-confirmation {
  background: #fff;
  padding: 3rem 1.5rem 2rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 576px) {
  .webform-confirmation {
    padding: 4rem 1.5rem 3rem;
  }
}

@media (min-width: 768px) {
  .webform-confirmation {
    margin-bottom: 4rem;
  }
}

h1 {
  margin-bottom: 3rem;
}

@media (max-width: 767px) {
  h1 {
    font-size: 2.5rem;
    word-break: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }
}

a {
  color: inherit;
  text-decoration: underline;
}

a:hover, a:focus {
  color: inherit;
  text-decoration: none;
}

a,
button,
.btn {
  transition: all 0.2s ease-in-out;
}

.more__link,
.back__link {
  font-family: "Gotham", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1.125rem;
  font-weight: bold;
  text-decoration: underline;
  text-transform: lowercase;
}

.more__link:hover, .more__link:focus,
.back__link:hover,
.back__link:focus {
  text-decoration: none;
}

.more__link.--small,
.back__link.--small {
  font-size: 1rem;
}

@media (min-width: 992px) {
  .block--feature-homepage--toerismewerkt-cta-links {
    width: 100%;
    flex: 0 0 100%;
    order: 10;
  }
}

@media (min-width: 992px) {
  .block--feature-homepage--toerismewerkt-cta-links .cta-links {
    display: flex;
    flex-direction: row;
  }
}

.block--feature-homepage--toerismewerkt-cta-links .cta-links a {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  padding: 10px 30px;
  color: #fff;
  font-family: "Gotham", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: bold;
  line-height: 1.1;
  text-decoration: none;
}

@media (min-width: 992px) {
  .block--feature-homepage--toerismewerkt-cta-links .cta-links a {
    font-size: 1.125rem;
    padding: 15px 30px;
  }
}

@media (min-width: 1200px) {
  .block--feature-homepage--toerismewerkt-cta-links .cta-links a {
    font-size: 1.25rem;
    padding: 20px 60px;
  }
}

.block--feature-homepage--toerismewerkt-cta-links .cta-links a .image {
  flex: 0 0 30px;
  margin-right: 15px;
  text-align: center;
  width: 30px;
}

.block--feature-homepage--toerismewerkt-cta-links .cta-links a .image img {
  width: 100%;
  height: auto;
}

@media (min-width: 992px) {
  .block--feature-homepage--toerismewerkt-cta-links .cta-links a .image {
    flex: 0 0 50px;
    margin-right: 30px;
    width: 50px;
  }
}

@media (min-width: 1200px) {
  .block--feature-homepage--toerismewerkt-cta-links .cta-links a .image {
    margin-right: 30px;
  }
}

.region-sidebar-second .views-exposed-widgets {
  padding: 20px;
  background: #FFF;
}

.region-sidebar-second .views-exposed-widgets label {
  margin: 0 0 10px;
  display: block;
  text-align: left;
  font-size: 1.4rem;
  font-weight: 300;
  text-transform: uppercase;
}

.region-sidebar-second .views-exposed-widgets .form-type-bef-link {
  margin: 0 0 10px;
}

.region-sidebar-second .views-exposed-widgets .form-type-bef-link a {
  display: block;
}

.region-sidebar-second .views-exposed-widgets .form-type-bef-link a:hover {
  text-decoration: underline;
}

.region-sidebar-second .views-exposed-widgets .form-type-bef-link a.active {
  font-weight: 400;
}

.block-facetapi {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
  text-align: center;
}

.block-facetapi.block--facetapi--field-tags {
  text-align: center;
}

.block-facetapi.block--facetapi--field-tags h6 {
  margin: 0;
}

.block-facetapi:last-child {
  margin-bottom: 5rem;
}

@media (min-width: 768px) {
  .block-facetapi {
    margin-bottom: .5rem;
  }
}

.block-facetapi h6 {
  color: #51917a;
  width: 100%;
}

.block-facetapi .item-list {
  display: inline-block;
}

.block-facetapi .item-list ul.expanded {
  padding-left: 30px;
}

.block-facetapi .item-list a {
  text-decoration: none;
}

.block-facetapi .item-list .facetapi-facetapi-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.block-facetapi .item-list .facetapi-facetapi-links li {
  display: inline-block;
  margin: 0 1rem 10px 0;
}

.block-facetapi .item-list .facetapi-facetapi-links li .facetapi-item--active {
  background: #51917a;
  color: #fff;
  display: inline-block;
  padding: .5rem 54px .5rem 1rem;
  position: relative;
}

.block-facetapi .item-list .facetapi-facetapi-links li .facetapi-item--active .facetapi-active {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  color: #51917a;
  font-family: "Gotham", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: bold;
  height: 38px;
  position: absolute;
  top: 0;
  right: 0;
  width: 38px;
}

.block-facetapi .item-list .facetapi-facetapi-links li .facetapi-item--active .facetapi-active:hover, .block-facetapi .item-list .facetapi-facetapi-links li .facetapi-item--active .facetapi-active:focus {
  color: #f16464;
}

.block-facetapi .item-list .facetapi-facetapi-links li .facetapi-item--inactive .facetapi-inactive {
  color: #51917a;
  display: block;
  background: rgba(255, 255, 255, 0.5);
  padding: .5rem 1rem;
}

.block-facetapi .item-list .facetapi-facetapi-links li .facetapi-item--inactive .facetapi-inactive:hover, .block-facetapi .item-list .facetapi-facetapi-links li .facetapi-item--inactive .facetapi-inactive:focus {
  background: #51917a;
  color: #fff;
}

.block--feature-homepage--toerismewerkt-footer-text .content p {
  display: flex;
  justify-content: space-evenly;
}

.block--feature-homepage--toerismewerkt-footer-text .content img {
  width: auto;
}

@media (min-width: 992px) {
  .block--feature-homepage--toerismewerkt-header-links {
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;
  }
}

@media (min-width: 992px) {
  .block--feature-homepage--toerismewerkt-header-links .header-links {
    display: flex;
  }
}

@media (min-width: 992px) {
  .block--feature-homepage--toerismewerkt-header-links .header-links .header-link {
    display: flex;
    align-items: center;
  }
  .block--feature-homepage--toerismewerkt-header-links .header-links .header-link::after {
    content: "|";
    color: #dee2e6;
    display: inline-block;
  }
  .block--feature-homepage--toerismewerkt-header-links .header-links .header-link:last-child::after {
    display: none;
  }
}

.block--feature-homepage--toerismewerkt-header-links .header-links .header-link a {
  display: flex;
  align-items: center;
  padding: 15px 30px;
  border-bottom: 2px solid #dee2e6;
  font-size: 0.875rem;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
}

@media (min-width: 992px) {
  .block--feature-homepage--toerismewerkt-header-links .header-links .header-link a {
    padding: 15px;
    border-bottom: 0;
    font-size: .5rem;
  }
}

.block--feature-homepage--toerismewerkt-header-links .header-links .header-link a br {
  content: " ";
}

.block--feature-homepage--toerismewerkt-header-links .header-links .header-link a br::after {
  content: " ";
}

@media (min-width: 992px) {
  .block--feature-homepage--toerismewerkt-header-links .header-links .header-link a br {
    content: initial;
  }
  .block--feature-homepage--toerismewerkt-header-links .header-links .header-link a br::after {
    display: none;
  }
}

.block--feature-homepage--toerismewerkt-header-links .header-links .header-link a .image {
  width: 25px;
  flex: 0 0 25px;
  margin-right: 15px;
}

@media (min-width: 992px) {
  .block--feature-homepage--toerismewerkt-header-links .header-links .header-link a .image {
    margin-right: 10px;
  }
}

.block--mailchimp-signup--newsletter-toerisme-limburg .right {
  padding: 0 1.5rem;
}

.block--mailchimp-signup--newsletter-toerisme-limburg .image {
  margin: 0 0 15px;
  padding: 30px;
  background: #f16464;
  text-align: center;
}

@media (min-width: 768px) {
  .block--mailchimp-signup--newsletter-toerisme-limburg .image {
    max-width: 200px;
    margin-left: auto;
  }
}

.block--mailchimp-signup--newsletter-toerisme-limburg h2 {
  color: #f16464;
  font-size: 1rem;
}

@media (min-width: 992px) {
  .block--menu-block--main-menu-block {
    display: flex;
    align-items: center;
  }
}

.search-header__trigger {
  border: none;
  background: transparent;
  color: #333333;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Gotham", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: bold;
  height: 46px;
  padding: 0 30px;
  position: relative;
  transition: all 0.2s ease-in-out;
  width: 100%;
}

.search-header__trigger::before {
  background: #fff;
  content: "\f002";
  color: inherit;
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
  margin-right: 8px;
}

.search-header__trigger:hover, .search-header__trigger:focus {
  color: #f16464;
}

@media (min-width: 992px) {
  .search-header__trigger {
    height: auto;
    padding: 0 20px;
    overflow: hidden;
    text-indent: 99999em;
    width: 46px;
  }
  .search-header__trigger::before {
    text-indent: 0;
  }
  .search-header__trigger::after {
    content: "|";
    color: #dee2e6;
    display: inline-block;
    font-family: "Gotham Book", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: normal;
    height: 18px;
    position: absolute;
    top: 0;
    left: 0;
    text-indent: 0;
    width: 4px;
  }
}

.search-header__overlay {
  display: block;
  z-index: 999;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  height: 100vh;
  background: #51917a;
  transition: all 0.2s ease-in-out;
}

.search-header__overlay .logo {
  margin-bottom: 2rem;
  width: 10rem;
}

@media (min-width: 992px) {
  .search-header__overlay {
    position: absolute;
    height: 100%;
  }
  .search-header__overlay .logo {
    margin-bottom: 0;
  }
}

.search-header__overlay .content {
  position: relative;
  padding: 1.2rem 1rem 1rem;
}

@media (min-width: 992px) {
  .search-header__overlay .content {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.search-header__overlay .content > form {
  max-width: 100%;
  position: relative;
  width: 100%;
}

.search-header__overlay .content > form .views-exposed-widget {
  display: inline-block;
}

.search-header__overlay .content > form .views-widget-filter-search_api_views_fulltext {
  margin-bottom: 0;
  position: relative;
  width: calc(100% - 53px);
}

.search-header__overlay .content > form .views-widget-filter-search_api_views_fulltext .js-clear-field-button {
  background: url("../images/svg/close-white.svg") center center no-repeat;
  background-color: #51917a;
}

.search-header__overlay .content > form .views-widget-filter-search_api_views_fulltext .js-clear-field-button:hover, .search-header__overlay .content > form .views-widget-filter-search_api_views_fulltext .js-clear-field-button:focus {
  background: url("../images/svg/close.svg") center center no-repeat;
  background-color: #51917a;
}

.search-header__overlay .content > form .form-text {
  background: transparent;
  border-bottom: 2px solid #7cab97;
  color: #fff;
  font-family: "Gotham", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: bold;
}

.search-header__overlay .content > form .form-text:focus {
  border-bottom: 2px solid #fff;
  outline: #51917a auto 2px;
}

.search-header__overlay .content > form input:-internal-autofill-previewed,
.search-header__overlay .content > form input:-internal-autofill-selected {
  background: rgba(255, 255, 255, 0.1) !important;
}

.search-header__overlay .content > form .form-submit {
  background: transparent;
  height: 48px;
  overflow: hidden;
  padding: 0;
  position: relative;
  text-indent: 101%;
  width: 48px;
  z-index: 1;
}

.search-header__overlay .content > form .views-submit-button {
  height: 48px;
  position: relative;
  transition: all 0.2s ease-in-out;
  width: 48px;
}

.search-header__overlay .content > form .views-submit-button::after {
  content: "\f002";
  color: #fff;
  font-family: "Font Awesome 5 Pro";
  font-size: 1.25rem;
  font-weight: 700;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.search-header__overlay .content > form .views-submit-button:hover, .search-header__overlay .content > form .views-submit-button:focus {
  opacity: .5;
}

@media (min-width: 992px) {
  .search-header__overlay .content > form {
    width: 550px;
    margin: 0 0 0 5rem;
  }
}

.search-header__overlay .search-header__close {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: .75rem;
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 46px;
  height: 46px;
  padding: .7rem 0 0;
}

.search-header__overlay .search-header__close::before {
  background: url("../images/svg/close-white.svg") center center no-repeat;
  background-size: contain;
  content: "";
  display: inline-block;
  transition: all 0.2s ease-in-out;
  height: 16px;
  margin-right: .1rem;
  width: 16px;
}

.search-header__overlay .search-header__close::before {
  margin-right: 0;
}

.search-header__overlay .search-header__close:hover, .search-header__overlay .search-header__close:focus {
  opacity: .5;
}

@media (min-width: 768px) {
  .search-header__overlay .search-header__close {
    right: 2.5rem;
  }
}

.block-search-header {
  align-self: center;
}

.block-search-header .search-header__overlay {
  display: none;
}

#sliding-popup.sliding-popup-bottom {
  background: #7cab97;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}

#sliding-popup.sliding-popup-bottom .popup-content {
  max-width: 800px;
  padding: 24px 0;
  width: 100%;
}

#sliding-popup.sliding-popup-bottom .popup-content #popup-text,
#sliding-popup.sliding-popup-bottom .popup-content #popup-buttons {
  display: block;
  float: none;
  max-width: none;
  padding: 0 20px;
}

#sliding-popup.sliding-popup-bottom .popup-content #popup-text {
  font-weight: 300;
  margin: 0;
  text-align: center;
}

#sliding-popup.sliding-popup-bottom .popup-content #popup-text p {
  font-size: 14px;
  font-weight: 300;
  margin: 0 0 10px 0;
}

#sliding-popup.sliding-popup-bottom .popup-content #popup-text p .external-link {
  color: #FFF !important;
  font-weight: 600;
  text-decoration: none;
}

#sliding-popup.sliding-popup-bottom .popup-content #popup-text p .external-link:hover {
  text-decoration: underline;
}

#sliding-popup.sliding-popup-bottom .popup-content #popup-buttons {
  margin: 20px 0 0 0;
}

#sliding-popup.sliding-popup-bottom .popup-content #popup-buttons button {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  color: #FFF;
  font-family: Montserrat, Sans-Serif;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
  padding: 12px;
  text-shadow: none;
  transition: all 200ms ease-in;
  width: 100%;
}

#sliding-popup.sliding-popup-bottom .popup-content #popup-buttons button.agree-button {
  background: #444;
  border: 1px solid #444;
  margin-bottom: 15px;
}

#sliding-popup.sliding-popup-bottom .popup-content #popup-buttons button.agree-button:hover {
  background: #FFF;
  border-color: #FFF;
  color: #7cab97;
}

#sliding-popup.sliding-popup-bottom .popup-content #popup-buttons button.find-more-button {
  background: transparent;
  border: 1px solid transparent;
}

#sliding-popup.sliding-popup-bottom .popup-content #popup-buttons button.find-more-button:hover {
  border-color: #FFF;
}

@media (min-width: 768px) {
  #sliding-popup.sliding-popup-bottom .popup-content #popup-buttons::after {
    display: block;
    clear: both;
    content: "";
  }
  #sliding-popup.sliding-popup-bottom .popup-content #popup-buttons button {
    float: left;
    width: 49%;
  }
  #sliding-popup.sliding-popup-bottom .popup-content #popup-buttons button.agree-button {
    margin-right: 2%;
    margin-bottom: 0;
  }
}

.block--feature-homepage--toerismewerkt-social-links {
  padding: 15px 30px;
}

@media (min-width: 992px) {
  .block--feature-homepage--toerismewerkt-social-links {
    display: flex;
    align-items: center;
    padding: 30px;
    padding-left: 15px;
  }
}

.block--feature-homepage--toerismewerkt-social-links .social-links a {
  display: inline-block;
  margin-right: 10px;
  text-decoration: none;
}

.block--feature-homepage--toerismewerkt-social-links .social-links a:last-of-type {
  margin-right: 0;
}

.block--feature-homepage--toerismewerkt-sponsor-logos {
  display: flex;
  align-items: center;
  margin: 0 auto;
  border-bottom: 2px solid #dee2e6;
  border-top: 2px solid #dee2e6;
  padding: .9375rem 0;
}

@media (min-width: 992px) {
  .block--feature-homepage--toerismewerkt-sponsor-logos {
    border: 0;
    padding: 0;
  }
}

.sup-logos {
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.sup-link {
  margin: 0 .625rem;
}

.sup-image {
  width: 75px;
  height: auto;
}

.sup-image.efro, .sup-image.province {
  width: 100px;
}

.block-feature-testimonial {
  margin-bottom: 30px;
}

.block-feature-testimonial .field-name-field-quote {
  margin-top: 15px;
}

.block-feature-testimonial .node-teaser .container > a {
  display: block;
}

.block-feature-testimonial .node-teaser .container > a:hover, .block-feature-testimonial .node-teaser .container > a:focus {
  text-decoration: underline;
}

.block-feature-testimonial .node-teaser .container > a img {
  width: 100%;
}

.block--views---exp-events-overview-page,
.block--views---exp-inspiration-overview-page {
  max-width: 690px;
  margin: 2rem auto;
}

.block--views---exp-events-overview-page form,
.block--views---exp-inspiration-overview-page form {
  position: relative;
}

.block--views---exp-events-overview-page form label,
.block--views---exp-inspiration-overview-page form label {
  color: #abc8ca;
  font-family: "Gotham", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: bold;
  margin-bottom: 0;
  pointer-events: none;
  position: absolute;
  top: .75rem;
  left: .75rem;
  transition: all 0.2s ease-in-out;
}

.block--views---exp-events-overview-page form .floating-label label,
.block--views---exp-inspiration-overview-page form .floating-label label {
  top: -1.65rem;
}

.block--views---exp-events-overview-page form .js-clear-field-button,
.block--views---exp-inspiration-overview-page form .js-clear-field-button {
  position: absolute;
  top: 1px;
  right: 59px;
  width: 44px;
  height: 44px;
  background-size: 16px;
  background-color: rgba(237, 234, 225, 0.9);
}

.block--views---exp-events-overview-page form .form-text,
.block--views---exp-inspiration-overview-page form .form-text {
  background: rgba(237, 234, 225, 0.9);
}

.block--views---exp-events-overview-page form .form-text:focus,
.block--views---exp-inspiration-overview-page form .form-text:focus {
  outline: #7cab97 auto 1px;
}

.block--views---exp-events-overview-page form .views-submit-button,
.block--views---exp-inspiration-overview-page form .views-submit-button {
  position: absolute;
  right: 0;
  bottom: 0;
}

.block--views---exp-events-overview-page form .views-submit-button::after,
.block--views---exp-inspiration-overview-page form .views-submit-button::after {
  background: #fff;
  content: "\f002";
  color: #abc8ca;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Font Awesome 5 Pro";
  height: 46px;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.2s ease-in-out;
  width: 46px;
}

.block--views---exp-events-overview-page form .views-submit-button:hover::after,
.block--views---exp-inspiration-overview-page form .views-submit-button:hover::after {
  background: #51917a;
  color: #fff;
}

.block--views---exp-events-overview-page form .form-submit,
.block--views---exp-inspiration-overview-page form .form-submit {
  background: transparent;
  color: #333333;
  height: 46px;
  overflow: hidden;
  position: relative;
  text-indent: 99999em;
  width: 46px;
  z-index: 2;
}

.region-content .block-views,
.region-content-bottom .block-views {
  margin-top: 5rem;
}

@media (min-width: 768px) {
  .region-content .block-views,
  .region-content-bottom .block-views {
    margin-top: 7rem;
  }
}

.region-content .block-views > h2,
.region-content-bottom .block-views > h2 {
  margin-bottom: 3rem;
}

@media (max-width: 575px) {
  .region-content .block-views > h2,
  .region-content-bottom .block-views > h2 {
    margin-bottom: 2rem;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.block--views--inspiration-home-promoted-block + .block--views--inspiration-home-recent-block {
  margin-top: 2rem;
}

.block--views--inspiration-home-promoted-block + .block--views--inspiration-home-recent-block > h2 {
  display: none;
}

.block--views--events-promoted-block + .block--views--events-homepage-upcoming-block > h2 {
  display: none;
}

.field-name-field-components > .field-items > .field-item {
  margin: 0 0 5rem;
}

@media (min-width: 768px) {
  .field-name-field-components > .field-items > .field-item {
    margin-bottom: 7rem;
  }
}

.subscription-link {
  display: inline-block;
  padding: 10px 20px;
  background: #85a732;
  color: #FFF;
}

.subscription-link:hover, .subscription-link:focus {
  background: #444;
  color: #FFF;
}

.field-name-field-theme {
  font-weight: 400;
}

.menu-name-main-menu > .menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

@media (min-width: 992px) {
  .menu-name-main-menu > .menu {
    display: flex;
  }
}

@media (min-width: 992px) {
  .menu-name-main-menu > .menu > li::after {
    content: "|";
    color: #dee2e6;
    display: inline-block;
  }
  .menu-name-main-menu > .menu > li:last-child::after {
    display: none;
  }
}

.menu-name-main-menu > .menu > li a {
  border-bottom: 2px solid #dee2e6;
  display: block;
  font-family: "Gotham", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: bold;
  padding: 15px 30px;
  text-decoration: none;
}

@media (min-width: 992px) {
  .menu-name-main-menu > .menu > li a {
    border: 0;
    display: inline-block;
    padding: 15px;
  }
}

.menu-name-main-menu > .menu > li a:hover, .menu-name-main-menu > .menu > li a:focus, .menu-name-main-menu > .menu > li a.active, .menu-name-main-menu > .menu > li a.active-trail {
  color: #f16464;
}

.menu-name-main-menu > .menu .menu {
  display: none;
}

.js-clear-field-button {
  position: absolute;
  right: 13px;
  top: 13px;
  width: 20px;
  height: 20px;
  z-index: 100;
  text-indent: -999rem;
  border: none;
  background: url("../images/svg/close.svg") center center no-repeat;
  background-size: 12px;
  background-color: #fff;
  cursor: pointer;
  padding: 0;
}

.mailchimp-signup-subscribe-form .mailchimp-signup-subscribe-form-description {
  margin-bottom: 15px;
  font-size: .6rem;
}

.mailchimp-signup-subscribe-form .mailchimp-signup-subscribe-form-description a {
  font-weight: bold;
}

.mailchimp-signup-subscribe-form .form-actions {
  position: relative;
  padding: 0;
}

.mailchimp-signup-subscribe-form .form-actions::after {
  content: ">";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 2.9rem;
  height: 2.9rem;
  display: block;
  background: #fbf2ef;
  color: #f16464;
  font-weight: bold;
  line-height: 2.9rem;
  text-align: center;
  pointer-events: none;
}

.mailchimp-signup-subscribe-form .form-actions .form-submit {
  text-align: left;
}

.mailchimp-signup-subscribe-form .form-actions .form-submit:first-of-type {
  padding-right: calc(.75rem + 2.9rem);
}

.messages {
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #31667e;
  color: #31667e;
  font-family: "Gotham", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: bold;
  margin: 3rem 0;
  padding: 1rem 1.5rem;
}

.messages em {
  color: #333333;
  font-style: normal;
}

.pager {
  list-style-type: none;
  padding: 0;
}

.sharethis-buttons span {
  margin-left: 10px;
  cursor: pointer;
}

.sharethis-buttons span.st_facebook_custom::after {
  content: "\f39e";
  font-family: "Font Awesome 5 Brands";
}

.sharethis-buttons span.st_email_custom::after {
  content: "\f0e0";
  font-family: "Font Awesome 5 Pro";
}

.sharethis-buttons span.st_twitter_custom::after {
  content: "\f099";
  font-family: "Font Awesome 5 Brands";
}

.sharethis-buttons span.st_linkedin_custom::after {
  content: "\f0e1";
  font-family: "Font Awesome 5 Brands";
}

/* Slider */
.slick {
  position: relative;
}

.slick-track {
  display: flex;
}

.slick-track .slide {
  display: flex;
  height: auto;
}

.slick-track .slide .slide__content {
  height: 100%;
  width: 100%;
}

.slick-track .slide .slide__content > div {
  height: 100%;
}

/* Arrows */
.slick-prev,
.slick-next {
  background: #abc8ca;
  position: absolute;
  display: block;
  height: 40px;
  width: 40px;
  line-height: 0;
  font-size: 0;
  color: transparent;
  top: 20%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  color: transparent;
}

.slick-prev:hover,
.slick-next:hover {
  background: #51917a;
}

.slick-prev:not(.slick-disabled),
.slick-next:not(.slick-disabled) {
  cursor: pointer;
}

.slick-prev.slick-disabled,
.slick-next.slick-disabled {
  opacity: 0;
}

.slick-prev::before,
.slick-next::before {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Gotham", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 576px) {
  .slick-prev,
  .slick-next {
    top: 50%;
  }
}

.slick-prev {
  left: -10px;
}

@media (min-width: 768px) {
  .slick-prev {
    left: -30px;
  }
}

@media only screen and (min-width: 1300px) {
  .slick-prev {
    left: -80px;
  }
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -15px;
}

.slick-prev::before {
  content: "<";
}

[dir="rtl"] .slick-prev::before {
  content: ">";
}

.slick-next {
  right: -10px;
}

@media (min-width: 768px) {
  .slick-next {
    right: -30px;
  }
}

@media only screen and (min-width: 1300px) {
  .slick-next {
    right: -80px;
  }
}

[dir="rtl"] .slick-next {
  left: -15px;
  right: auto;
}

.slick-next::before {
  content: ">";
}

[dir="rtl"] .slick-next::before {
  content: "<";
}

.slick--optionset--slides .slick-prev {
  left: 0;
}

.slick--optionset--slides .slick-next {
  right: 0;
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 52px;
}

.slick-dots {
  position: absolute;
  bottom: -52px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 1rem 0;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 1;
  font-size: 18px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover::before, .slick-dots li button:focus::before {
  opacity: 1;
}

.slick-dots li button::before {
  background: #eec6b8;
  position: absolute;
  top: 5px;
  left: 5px;
  content: "";
  width: 10px;
  height: 10px;
  text-align: center;
  color: black;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button::before {
  background: #f16464;
  opacity: 1;
}

.tabs__wrapper {
  position: fixed;
  left: 2rem;
  top: 14rem;
  z-index: 998;
}

.tabs__wrapper .tabs__toggle {
  background: #51917a;
  border: 0;
  border-radius: 50%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.125rem;
  height: 3rem;
  margin: 0;
  padding: 0;
  width: 3rem;
}

.tabs__wrapper .tabs__toggle:hover, .tabs__wrapper .tabs__toggle:focus {
  background: #7cab97;
}

.tabs__wrapper .tabs__toggle .fa-pen {
  display: inline-block;
}

.tabs__wrapper .tabs__toggle .fa-times {
  display: none;
}

.tabs__wrapper .tabs__toggle.toggle--close .fa-pen {
  display: none;
}

.tabs__wrapper .tabs__toggle.toggle--close .fa-times {
  display: inline-block;
}

.tabs__wrapper .tabs {
  background: #7cab97;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
  color: #fff;
  display: none;
  list-style: none;
  margin: 1rem 0;
  padding: 0;
}

.tabs__wrapper .tabs a {
  display: block;
  font-family: "Gotham", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: bold;
  padding: .75rem 1rem;
  text-decoration: none;
}

.tabs__wrapper .tabs a:hover, .tabs__wrapper .tabs a:focus {
  background: #51917a;
}

.tabs__wrapper .tabs.tabs--open {
  display: block;
}

.not-logged-in .tabs__wrapper {
  display: none;
}

.ellip {
  display: block;
  height: 100%;
}

.ellip-line {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

.ellip,
.ellip-line {
  position: relative;
  overflow: hidden;
  max-width: 100%;
}

.page-user .region-content form {
  background: #fff;
  padding: 2rem 1rem;
}

@media (min-width: 576px) {
  .page-user .region-content form {
    padding: 2rem;
  }
}

@media (min-width: 992px) {
  .page-user .region-content form {
    padding: 6rem;
  }
  .page-user .region-content form > div {
    margin: 0 auto;
    max-width: 576px;
  }
  .page-user .region-content form .form-submit {
    width: auto;
  }
}

.event .event__description {
  margin-bottom: 1rem;
}

.event .event__date {
  font-family: "Gotham", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1.625rem;
  font-weight: bold;
  line-height: 1;
}

.event .event__date .d {
  display: block;
  font-size: 4rem;
  line-height: 1;
}

@media (min-width: 576px) {
  .event .event__date {
    font-size: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .event .event__date .d {
    font-size: 6rem;
    line-height: .85;
    margin-right: 0;
  }
}

@media (min-width: 768px) {
  .event .event__date {
    font-size: 1.875rem;
  }
  .event .event__date .d {
    font-size: 8rem;
  }
}

@media (min-width: 992px) {
  .event .event__date {
    font-size: 2.625rem;
  }
  .event .event__date .d {
    font-size: 9.75rem;
  }
}

.event.node-full .col--right {
  position: relative;
  padding: 30px;
  background: #fff;
}

@media (min-width: 768px) {
  .event.node-full .col--right {
    padding-top: 5rem;
  }
  .event.node-full .col--right::before {
    background: #fff;
    content: "";
    display: inline-block;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    margin-left: -8rem;
    width: 8rem;
  }
}

@media (min-width: 992px) {
  .event.node-full .col--right {
    padding-top: 7rem;
  }
}

@media (min-width: 992px) {
  .event.node-full .col--right {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

.event.node-full .event__info {
  padding: 1.5rem;
  background: #51917a;
  color: #fff;
  position: relative;
  z-index: 1;
}

@media (min-width: 576px) {
  .event.node-full .event__info {
    padding-bottom: 7rem;
  }
}

@media (min-width: 768px) {
  .event.node-full .event__info {
    padding-bottom: 10rem;
    margin-top: 4rem;
  }
}

@media (min-width: 992px) {
  .event.node-full .event__info {
    margin-top: 5rem;
  }
}

.event.node-full .event__location {
  font-size: 1.125rem;
  line-height: 1.2;
  width: calc(100% - 70px);
  margin-top: .5rem;
}

.event.node-full .event__icon {
  background: #fff;
  display: flex;
  padding: 15px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.event.node-full .event__ical {
  padding: 1rem 1rem 2rem;
  position: relative;
  background: #fff;
  z-index: 1;
}

@media (min-width: 576px) {
  .event.node-full .event__ical {
    padding: 2rem 0;
  }
}

.event.node-full .event__ical .ical-link a {
  color: #51917a;
  display: inline-block;
  padding-left: 40px;
  padding-top: 13px;
  position: relative;
  text-decoration: none;
}

.event.node-full .event__ical .ical-link a::before {
  background: url("../images/svg/ical-icon.svg") center center no-repeat;
  background-size: contain;
  content: "";
  display: inline-block;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
}

.event.node-full .event__ical .ical-link a:hover, .event.node-full .event__ical .ical-link a:focus {
  text-decoration: underline;
}

.event.node-full .event__back_link,
.event.node-full .inspiration__sharing {
  padding: 0 45px;
}

@media (min-width: 768px) {
  .event.node-full .event__back_link,
  .event.node-full .inspiration__sharing {
    padding: 0 15px;
  }
}

.event.node-teaser, .event.node-teaser-without-image, .event.node-teaser--mini {
  position: relative;
  color: #fff;
}

.event.node-teaser .col--left, .event.node-teaser-without-image .col--left {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  .event.node-teaser .col--left, .event.node-teaser-without-image .col--left {
    padding: 1.75rem 2.5rem;
  }
}

.event.node-teaser .col--left, .event.node-teaser-without-image .col--left {
  background-color: #51917a;
}

.event.node-teaser .event__info, .event.node-teaser-without-image .event__info {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.event.node-teaser-without-image, .event.node-teaser--mini {
  position: relative;
  color: #fff;
}

.event.node-teaser .event__info {
  flex-direction: column;
  align-items: flex-start;
  padding-left: 15px;
  padding-right: 15px;
}

.event.node-teaser .event__info > div,
.event.node-teaser .event__info > h3 {
  width: 100%;
}

.event.node-teaser .event__title {
  font-size: 1.125rem;
  line-height: 1.2;
  margin: 1rem 0 .5rem;
}

.event.node-teaser .event__location {
  margin: 0 0 1rem;
}

.event.node-teaser .more__link {
  display: block;
  margin-top: .5rem;
}

.event.node-teaser .node__wrapper {
  margin-bottom: 4rem;
  margin-top: 6rem;
}

@media (min-width: 992px) {
  .event.node-teaser .node__wrapper {
    margin-bottom: 8rem;
    margin-top: 8rem;
  }
}

.event.node-teaser .col--left {
  z-index: 1;
}

.event.node-teaser .col--right {
  height: calc(100% + 4rem);
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;
  position: absolute;
  top: -2rem;
  right: 0;
  z-index: 0;
}

.event.node-teaser .col--right .field-name-field-image,
.event.node-teaser .col--right .field-items,
.event.node-teaser .col--right .field-item {
  height: 100%;
}

.event.node-teaser .col--right img {
  height: 100%;
  max-width: none;
  width: auto;
}

.event.node-teaser .col--right .event__icon {
  background: #51917a;
  height: 60px;
  padding: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 60px;
}

.event.node-teaser .col--right .event__icon svg {
  height: auto;
  width: 100%;
}

@media (min-width: 992px) {
  .event.node-teaser .col--right {
    height: calc(100% + 7.6rem);
    top: -3.8rem;
  }
}

@media (min-width: 1200px) {
  .event.node-teaser .col--right img {
    height: auto;
    max-width: 100%;
    width: 100%;
  }
}

.event.node-teaser--mini {
  text-align: center;
}

.event.node-teaser--mini .event__info {
  padding: 2rem 1.5rem 0;
}

@media (min-width: 768px) {
  .event.node-teaser--mini .event__info {
    padding: 3.5rem 2rem 0;
  }
}

.event.node-teaser--mini .event__title {
  font-family: "Gotham Book", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1.25rem;
  font-weight: normal;
  line-height: 1.2;
  margin: 1.5rem 0 1rem;
}

.event.node-teaser--mini .event__location {
  margin-bottom: 2rem;
}

.event.node-teaser--mini .more__link {
  display: block;
  margin-top: auto;
  padding: 0 1.5rem 2rem;
}

.slick-track .event.node-teaser--mini {
  display: flex;
  flex-direction: column;
}

.event.node-teaser-without-image .col--right {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  .event.node-teaser-without-image .col--right {
    padding: 1.75rem 2.5rem;
  }
}

.event.node-teaser-without-image .event__info {
  align-items: center;
  justify-content: center;
}

.event.node-teaser-without-image .event__info .event__date {
  text-align: center;
}

@media (max-width: 575px) {
  .node-full:not(.node-webform):not(.node-event):not(.node-homepage) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.node-full .sharing .title {
  display: inline-block;
  font-size: 0.875rem;
}

.node-full .sharing .sharethis-buttons {
  display: inline-block;
}

.node-homepage-full {
  position: relative;
}

@media (min-width: 992px) {
  .node-type-homepage .region-header .block--feature-homepage--toerismewerkt-cta-links {
    display: none;
  }
}

.inspiration video {
  width: 100%;
}

.inspiration.node-teaser, .inspiration.node-teaser--mini, .inspiration.node-teaser--vertical, .inspiration.node-promoted, .inspiration.node-promoted--vertical {
  position: relative;
  color: #fff;
}

.inspiration.node-teaser {
  color: #fff;
}

@media (min-width: 576px) {
  .inspiration.node-teaser .node__wrapper,
  .inspiration.node-teaser .node__wrapper .col--left,
  .inspiration.node-teaser .node__wrapper .col--right {
    min-height: 320px;
  }
}

@media (min-width: 768px) {
  .inspiration.node-teaser .node__wrapper,
  .inspiration.node-teaser .node__wrapper .col--left,
  .inspiration.node-teaser .node__wrapper .col--right {
    min-height: 328px;
  }
}

@media (min-width: 992px) {
  .inspiration.node-teaser .node__wrapper,
  .inspiration.node-teaser .node__wrapper .col--left,
  .inspiration.node-teaser .node__wrapper .col--right {
    min-height: 271px;
  }
}

@media (min-width: 1200px) {
  .inspiration.node-teaser .node__wrapper,
  .inspiration.node-teaser .node__wrapper .col--left,
  .inspiration.node-teaser .node__wrapper .col--right {
    min-height: 249px;
  }
}

.inspiration.node-teaser .col--right {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  .inspiration.node-teaser .col--right {
    padding: 1.75rem 2.5rem;
  }
}

@media (max-width: 575px) {
  .inspiration.node-teaser .col--left {
    padding-left: 0;
    padding-right: 0;
  }
}

.inspiration.node-teaser .inspiration__info .more__link {
  display: block;
  margin-top: 1rem;
}

@media (min-width: 576px) {
  .inspiration.node-teaser .inspiration__info {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    height: 100%;
  }
  .inspiration.node-teaser .inspiration__info .more__link {
    margin-top: auto;
  }
}

.inspiration.node-teaser .field-name-field-image {
  max-height: 220px;
  overflow: hidden;
}

.inspiration.node-teaser .field-name-field-image img {
  height: auto;
  max-width: 100%;
  width: 100%;
}

@media (min-width: 576px) {
  .inspiration.node-teaser .field-name-field-image {
    height: 100%;
    max-height: 100%;
  }
  .inspiration.node-teaser .field-name-field-image .field-items,
  .inspiration.node-teaser .field-name-field-image .field-item {
    height: 100%;
  }
  .inspiration.node-teaser .field-name-field-image img {
    height: 100%;
    max-width: none;
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
  }
}

@media (min-width: 1200px) {
  .inspiration.node-teaser .field-name-field-image img {
    height: auto;
    max-width: 100%;
    width: 100%;
  }
}

.inspiration.node-teaser.inspiration--video .col--left {
  position: relative;
}

.inspiration.node-teaser.inspiration--video .col--left::before {
  border: 4px solid #fff;
  border-radius: 50%;
  content: "";
  display: block;
  height: 70px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.2s ease-in-out;
  width: 70px;
  z-index: 1;
}

.inspiration.node-teaser.inspiration--video .col--left::after {
  color: #fff;
  content: "\f04b";
  display: block;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  font-size: 26px;
  height: 36px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-45%, -50%);
  text-align: center;
  width: 36px;
  z-index: 2;
}

@media (min-width: 768px) {
  .inspiration.node-teaser--mini {
    height: 100%;
  }
  .inspiration.node-teaser--mini .node__wrapper {
    height: 100%;
  }
}

.inspiration.node-teaser--mini .inspiration__image {
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.inspiration.node-teaser--mini .inspiration__image .field,
.inspiration.node-teaser--mini .inspiration__image .field-items,
.inspiration.node-teaser--mini .inspiration__image .field-item {
  height: 100%;
}

.inspiration.node-teaser--mini .inspiration__image img {
  height: 100%;
  max-width: none;
  width: auto;
}

@media (min-width: 992px) {
  .inspiration.node-teaser--mini .inspiration__image img {
    height: auto;
    max-width: 100%;
    width: 100%;
  }
}

.inspiration.node-teaser--mini .col--right {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
}

.inspiration.node-teaser--mini .col--right .more__link {
  margin-top: auto;
}

.inspiration.node-teaser--vertical {
  position: relative;
}

@media (min-width: 768px) {
  .inspiration.node-teaser--vertical {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

@media screen and (-ms-high-contrast: none) and (min-width: 768px), (-ms-high-contrast: active) and (min-width: 768px) {
  .inspiration.node-teaser--vertical .teaser__link:hover + .teaser__overlay, .inspiration.node-teaser--vertical .teaser__link:focus + .teaser__overlay {
    height: 100%;
    opacity: 1;
  }
}

@media screen and (hover: hover) and (min-width: 768px) {
  .inspiration.node-teaser--vertical .teaser__link:hover + .teaser__overlay, .inspiration.node-teaser--vertical .teaser__link:focus + .teaser__overlay {
    height: 100%;
    opacity: 1;
  }
}

.inspiration.node-teaser--vertical .teaser__overlay {
  display: none;
  flex-direction: column;
  height: calc(100% - 172px);
  overflow: hidden;
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: height 0.35s ease;
  width: 100%;
  z-index: 1;
}

.inspiration.node-teaser--vertical .teaser__overlay .overlay__wrapper {
  height: 100%;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
}

.inspiration.node-teaser--vertical .teaser__overlay .overlay__wrapper .more__link {
  margin-top: auto;
}

@media (min-width: 768px) {
  .inspiration.node-teaser--vertical .teaser__overlay {
    display: flex;
  }
}

@media (min-width: 992px) {
  .inspiration.node-teaser--vertical .teaser__overlay {
    height: calc(100% - 237px);
  }
}

@media (min-width: 1200px) {
  .inspiration.node-teaser--vertical .teaser__overlay {
    height: calc(100% - 286px);
  }
}

.inspiration.node-teaser--vertical .inspiration__image img {
  height: auto;
  max-width: 100%;
  width: 100%;
}

@media (max-width: 991px) {
  .inspiration.node-teaser--vertical .inspiration__title {
    font-size: 1.5rem;
  }
}

.inspiration.node-teaser--vertical .inspiration__teaser {
  margin-top: -5rem;
  padding: 2rem 1rem;
  position: relative;
}

@media (min-width: 576px) {
  .inspiration.node-teaser--vertical .inspiration__teaser {
    margin-top: -8rem;
  }
}

@media (min-width: 768px) {
  .inspiration.node-teaser--vertical .inspiration__teaser {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 0;
  }
  .inspiration.node-teaser--vertical .inspiration__teaser .more__link {
    margin-top: auto;
  }
}

.inspiration.node-promoted .teaser__overlay, .inspiration.node-promoted--vertical .teaser__overlay {
  height: 100%;
  overflow: hidden;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: opacity 0.2s ease-in-out, height 0.35s ease;
}

@media (min-width: 992px) {
  .inspiration.node-promoted .teaser__overlay, .inspiration.node-promoted--vertical .teaser__overlay {
    height: 0;
    opacity: 0;
  }
}

.inspiration.node-promoted .teaser__overlay .overlay__wrapper, .inspiration.node-promoted--vertical .teaser__overlay .overlay__wrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem;
}

@media (min-width: 992px) {
  .inspiration.node-promoted .teaser__overlay .overlay__wrapper, .inspiration.node-promoted--vertical .teaser__overlay .overlay__wrapper {
    padding: 2rem;
  }
}

@media screen and (-ms-high-contrast: none) and (min-width: 992px), (-ms-high-contrast: active) and (min-width: 992px) {
  .inspiration.node-promoted .teaser__link:hover ~ .teaser__overlay, .inspiration.node-promoted .teaser__link:focus ~ .teaser__overlay, .inspiration.node-promoted--vertical .teaser__link:hover ~ .teaser__overlay, .inspiration.node-promoted--vertical .teaser__link:focus ~ .teaser__overlay {
    height: 100%;
    opacity: 1;
  }
}

@media screen and (hover: hover) and (min-width: 992px) {
  .inspiration.node-promoted .teaser__link:hover ~ .teaser__overlay, .inspiration.node-promoted .teaser__link:focus ~ .teaser__overlay, .inspiration.node-promoted--vertical .teaser__link:hover ~ .teaser__overlay, .inspiration.node-promoted--vertical .teaser__link:focus ~ .teaser__overlay {
    height: 100%;
    opacity: 1;
  }
}

@media (max-width: 991px) {
  .inspiration.node-promoted, .inspiration.node-promoted--vertical {
    max-height: 300px;
    overflow: hidden;
  }
}

.inspiration.node-full h1 {
  margin-bottom: .75rem;
}

.inspiration.node-full .inspiration__date {
  font-size: 1.125rem;
  margin-bottom: 2rem;
}

@media (min-width: 576px) {
  .inspiration.node-full .inspiration__date {
    margin-bottom: 3.5rem;
  }
}

.inspiration.node-full .inspiration__description,
.inspiration.node-full .inspiration__header {
  margin-bottom: 1.5rem;
}

@media (min-width: 576px) {
  .inspiration.node-full .inspiration__description,
  .inspiration.node-full .inspiration__header {
    margin-bottom: 2.5rem;
  }
}

.inspiration.node-full .inspiration__header.header--stacked {
  margin-bottom: 4rem;
  padding-bottom: 2rem;
  position: relative;
}

.inspiration.node-full .inspiration__header.header--stacked .header__main {
  min-height: 160px;
  padding-left: 10%;
}

.inspiration.node-full .inspiration__header.header--stacked .header__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 45%;
}

@media (min-width: 576px) {
  .inspiration.node-full .inspiration__header.header--stacked {
    padding-bottom: 3rem;
  }
  .inspiration.node-full .inspiration__header.header--stacked .header__main {
    min-height: 260px;
  }
}

@media (min-width: 768px) {
  .inspiration.node-full .inspiration__header.header--stacked .header__main {
    min-height: 350px;
  }
}

@media (min-width: 992px) {
  .inspiration.node-full .inspiration__header.header--stacked {
    padding-bottom: 4.5rem;
  }
  .inspiration.node-full .inspiration__header.header--stacked .header__main {
    min-height: 450px;
  }
}

@media (min-width: 1200px) {
  .inspiration.node-full .inspiration__header.header--stacked .header__main {
    min-height: 560px;
  }
}

.inspiration.node-full .paragraphs-items {
  margin: 2.5rem 0 5rem;
}

@media (min-width: 576px) {
  .inspiration.node-full .paragraphs-items {
    margin: 3.5rem 0 7rem;
  }
}

.node-poll .poll__cancel .form-actions {
  display: flex;
  justify-content: flex-end;
}

.node-poll .poll__results .total {
  color: #31667e;
  font-family: "Gotham", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 0.875rem;
  font-weight: bold;
  margin: .5rem 0;
}

.node-poll .poll__bar .text {
  margin-bottom: .5rem;
}

.node-poll .poll__bar .bar {
  background-color: #fff;
}

.node-poll .poll__bar .bar .foreground {
  background-color: #31667e;
}

.node-poll .poll__bar .percent {
  color: #31667e;
  font-size: 0.875rem;
  margin-top: .5rem;
}

@media (min-width: 1200px) {
  .node-poll .poll__results,
  .node-poll .poll__cancel {
    display: inline-block;
  }
  .node-poll .poll__results {
    width: 65%;
  }
  .node-poll .poll__cancel {
    position: absolute;
    right: 2rem;
    width: 35%;
  }
}

.node-poll .poll__vote .vote__choices .form-item-choice {
  margin-bottom: .5rem;
  position: relative;
}

.node-poll .poll__vote .vote__choices .form-radio {
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

.node-poll .poll__vote .vote__choices .form-radio:checked + .option::before {
  background-color: #31667e;
  background-image: url("../images/svg/check.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px;
}

.node-poll .poll__vote .vote__choices .option {
  display: inline-block;
  padding-left: 28px;
}

.node-poll .poll__vote .vote__choices .option::before {
  background-color: #fff;
  border: 3px solid #31667e;
  border-radius: 0;
  content: "";
  display: block;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  z-index: 1;
}

.node-poll .form-submit {
  background: #31667e;
  font-family: "Gotham", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: bold;
  margin-top: 1rem;
  text-transform: uppercase;
  width: auto;
}

.node-poll .form-submit:hover {
  background: #fff;
  color: #31667e;
}

.node-poll.node-promoted {
  background: rgba(49, 102, 126, 0.2);
  padding: 132px 2rem 2rem;
}

.node-poll.node-promoted::before {
  background: #31667e;
  background-image: url("../images/svg/poll-icon.svg");
  background-size: 60px;
  background-repeat: no-repeat;
  background-position: 2rem center;
  content: "";
  display: inline-block;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.node-poll.node-promoted .poll__title {
  font-size: 1.125rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .node-poll.node-promoted {
    padding: 2rem 2rem 2rem 232px;
  }
  .node-poll.node-promoted::before {
    background-size: 80px;
    background-position: center;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
  }
}

@media (min-width: 1200px) {
  .node-poll.node-promoted {
    padding: 2rem 2rem 2rem 292px;
  }
  .node-poll.node-promoted::before {
    width: 260px;
  }
  .node-poll.node-promoted .vote__form {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .node-poll.node-promoted .vote__form .form-type-radios {
    padding-left: 3.5rem;
  }
  .node-poll.node-promoted .form-submit {
    margin-top: 0;
  }
}

.node-teaser,
.node-teaser-without-image,
.node-teaser--mini,
.node-teaser--vertical,
.node-promoted,
.node-promoted--vertical {
  position: relative;
}

.node-teaser .teaser__link,
.node-teaser-without-image .teaser__link,
.node-teaser--mini .teaser__link,
.node-teaser--vertical .teaser__link,
.node-promoted .teaser__link,
.node-promoted--vertical .teaser__link {
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-decoration: none;
  z-index: 3;
}

.node-teaser .teaser__link:hover + .node__wrapper .more__link, .node-teaser .teaser__link:focus + .node__wrapper .more__link,
.node-teaser-without-image .teaser__link:hover + .node__wrapper .more__link,
.node-teaser-without-image .teaser__link:focus + .node__wrapper .more__link,
.node-teaser--mini .teaser__link:hover + .node__wrapper .more__link,
.node-teaser--mini .teaser__link:focus + .node__wrapper .more__link,
.node-teaser--vertical .teaser__link:hover + .node__wrapper .more__link,
.node-teaser--vertical .teaser__link:focus + .node__wrapper .more__link,
.node-promoted .teaser__link:hover + .node__wrapper .more__link,
.node-promoted .teaser__link:focus + .node__wrapper .more__link,
.node-promoted--vertical .teaser__link:hover + .node__wrapper .more__link,
.node-promoted--vertical .teaser__link:focus + .node__wrapper .more__link {
  text-decoration: none;
}

.node-teaser .node__wrapper,
.node-teaser-without-image .node__wrapper,
.node-teaser--mini .node__wrapper,
.node-teaser--vertical .node__wrapper,
.node-promoted .node__wrapper,
.node-promoted--vertical .node__wrapper {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  flex-direction: row;
  padding: 0;
}

@media (max-width: 1199px) {
  .node-teaser .node__wrapper,
  .node-teaser-without-image .node__wrapper,
  .node-teaser--mini .node__wrapper,
  .node-teaser--vertical .node__wrapper,
  .node-promoted .node__wrapper,
  .node-promoted--vertical .node__wrapper {
    word-break: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }
}

.node-teaser .teaser__link:hover + .node__wrapper .more__link, .node-teaser .teaser__link:focus + .node__wrapper .more__link,
.node-teaser-without-image .teaser__link:hover + .node__wrapper .more__link,
.node-teaser-without-image .teaser__link:focus + .node__wrapper .more__link {
  text-decoration: none;
}

.node-teaser--mini .teaser__link:hover ~ .more__link, .node-teaser--mini .teaser__link:focus ~ .more__link {
  text-decoration: none;
}

.node-search-result {
  position: relative;
  color: #fff;
}

@media (max-width: 1199px) {
  .node-search-result {
    word-break: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }
}

.node-search-result .row {
  overflow: hidden;
}

@media (min-width: 576px) {
  .node-search-result .col--left,
  .node-search-result .col--right {
    min-height: 320px;
  }
}

@media (min-width: 992px) {
  .node-search-result .col--left,
  .node-search-result .col--right {
    min-height: 270px;
  }
}

.node-search-result .col--right {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .node-search-result .col--right {
    padding: 1.75rem 2.5rem;
  }
}

.node-search-result .field-name-field-image {
  max-height: 220px;
  overflow: hidden;
}

@media (min-width: 576px) {
  .node-search-result .field-name-field-image {
    height: 100%;
    max-height: none;
  }
  .node-search-result .field-name-field-image .field-items,
  .node-search-result .field-name-field-image .field-item {
    height: 100%;
  }
}

.node-search-result .field-name-field-image img {
  width: 100%;
  height: auto;
}

@media (min-width: 576px) {
  .node-search-result .field-name-field-image img {
    height: 100%;
    max-width: none;
    position: absolute;
    top: 0;
    left: 50%;
    width: auto;
    transform: translateX(-50%);
  }
}

@media (min-width: 1200px) {
  .node-search-result .field-name-field-image img {
    height: auto;
    width: 100%;
  }
}

.node-search-result .more__link {
  display: block;
  margin-top: 1rem;
}

@media (min-width: 576px) {
  .node-search-result .more__link {
    margin-top: auto;
  }
}

.node-search-result a {
  text-decoration: none;
}

.node-search-result a:hover .more__link {
  text-decoration: none;
}

.node-webform.node-full .webform-client-form,
.node-webform.node-full .field-name-body,
.node-webform.node-full .field-name-field-body {
  margin: 0 0 20px;
}

.node-webform.node-full .webform__sidebar_top {
  background: #f16464;
  color: #fff;
  padding: 4rem 1.5rem 1.5rem;
}

@media (min-width: 768px) {
  .node-webform.node-full .webform__sidebar_top {
    margin-top: 4rem;
    padding-top: 50%;
  }
}

@media (min-width: 992px) {
  .node-webform.node-full .webform__sidebar_top {
    margin-top: 5rem;
  }
}

.node-webform.node-full .webform__sidebar_top p:last-of-type {
  margin-bottom: 0;
}

.node-webform.node-full .webform__sidebar_top,
.node-webform.node-full .webform__sidebar_bottom {
  position: relative;
  z-index: 1;
}

.node-webform.node-full .webform__sidebar_bottom,
.node-webform.node-full .webform__right {
  padding: 1.5rem;
  background: #fff;
}

@media (min-width: 768px) {
  .node-webform.node-full .webform__right {
    padding-top: 5rem;
  }
  .node-webform.node-full .webform__right::before {
    background: #fff;
    content: "";
    display: inline-block;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    margin-left: -8rem;
    width: 8rem;
  }
}

@media (min-width: 992px) {
  .node-webform.node-full .webform__right {
    padding-top: 7rem;
  }
}

.coworker_columns .col-lg-3 {
  margin-bottom: 30px;
}

.coworker_columns .coworker_column {
  color: #fff;
  height: 100%;
  position: relative;
}

.coworker_columns .coworker_column.coworker_column--category {
  background: #51917a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 320px;
}

.coworker_columns .coworker_column.coworker_column--category .coworker_column__title {
  font-size: 1.25rem;
  margin-bottom: 0;
  padding: 1.5rem 1rem;
  word-break: break-word;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}

.coworker_columns .coworker_column .coworker_column__main {
  max-height: 380px;
  overflow: hidden;
}

@media (min-width: 576px) {
  .coworker_columns .coworker_column .coworker_column__main {
    max-height: 100%;
  }
}

.coworker_columns .coworker_column .coworker_column__main img,
.coworker_columns .coworker_column .coworker_column__overlay img {
  width: 100%;
  height: auto;
}

.coworker_columns .coworker_column .coworker_column__title,
.coworker_columns .coworker_column .coworker__name {
  text-transform: uppercase;
}

.coworker_columns .coworker_column .coworker_column__overlay {
  height: auto;
  width: 100%;
  overflow: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.2s ease-in-out;
}

.coworker_columns .coworker_column .coworker_column__overlay .overlay__wrapper {
  background: rgba(81, 145, 122, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  padding: 1.5rem 1rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

@media (min-width: 1200px) {
  .coworker_columns .coworker_column .coworker_column__overlay .overlay__wrapper {
    padding-bottom: 2.5rem;
  }
}

@media (min-width: 576px) {
  .coworker_columns .coworker_column .coworker_column__overlay {
    max-height: 100%;
  }
}

.coworker_columns .coworker_column:hover .coworker_column__overlay, .coworker_columns .coworker_column:focus .coworker_column__overlay, .coworker_columns .coworker_column:active .coworker_column__overlay {
  opacity: 1;
}

.coworker_columns .coworker_column .coworker__name {
  font-size: 1.125rem;
  margin-bottom: .25rem;
  word-break: break-word;
  hyphens: auto;
}

@media (min-width: 576px) {
  .coworker_columns .coworker_column .coworker__name {
    font-size: 1.25rem;
  }
}

.coworker_columns .coworker_column .coworker__function,
.coworker_columns .coworker_column .coworker__email,
.coworker_columns .coworker_column .coworker__phone {
  font-size: 0.875rem;
  margin-bottom: 0;
}

.coworker_columns .coworker_column .coworker__function a,
.coworker_columns .coworker_column .coworker__email a,
.coworker_columns .coworker_column .coworker__phone a {
  text-decoration: none;
}

.coworker_columns .coworker_column .coworker__email {
  word-break: break-all;
}

.coworker_columns .coworker_column .coworker__phone {
  font-family: "Gotham", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: bold;
}

.cta {
  display: none;
}

@media (min-width: 992px) {
  .cta {
    display: block;
  }
}

.region-content-top .block--feature-homepage--toerismewerkt-cta-links {
  display: none;
}

@media (min-width: 992px) {
  .region-content-top .block--feature-homepage--toerismewerkt-cta-links {
    display: block;
  }
}

body.front .region-content-top .block--feature-homepage--toerismewerkt-cta-links {
  display: none;
}

.field-name-field-cta > .field-items {
  overflow: hidden;
  display: flex;
  flex-direction: row;
}

.field-name-field-cta > .field-items > .field-item {
  margin: 0;
  flex-basis: 100%;
}

.field-name-field-cta > .field-items > .field-item:nth-child(1) {
  background-color: #7cab97;
}

.field-name-field-cta > .field-items > .field-item:nth-child(2) {
  background-color: #f16464;
}

.field-name-field-cta > .field-items > .field-item:nth-child(3) {
  background-color: #eec6b8;
}

.field-name-field-cta > .field-items > .field-item:nth-child(4) {
  background-color: #31667e;
}

.field-name-field-cta > .field-items .paragraphs-item-cta a {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  padding: 10px 30px;
  color: #fff;
  font-family: "Gotham", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: bold;
  line-height: 1.1;
  text-decoration: none;
  flex-direction: row;
  justify-content: center;
  height: 100%;
}

@media (min-width: 992px) {
  .field-name-field-cta > .field-items .paragraphs-item-cta a {
    font-size: 1.125rem;
    padding: 15px 30px;
  }
}

@media (min-width: 1200px) {
  .field-name-field-cta > .field-items .paragraphs-item-cta a {
    font-size: 1.25rem;
    padding: 20px 60px;
  }
}

.field-name-field-cta > .field-items .paragraphs-item-cta a .image {
  flex: 0 0 30px;
  margin-right: 15px;
  text-align: center;
  width: 30px;
}

.field-name-field-cta > .field-items .paragraphs-item-cta a .image img {
  width: 100%;
  height: auto;
}

@media (min-width: 992px) {
  .field-name-field-cta > .field-items .paragraphs-item-cta a .image {
    flex: 0 0 70px;
    margin-right: 30px;
    width: 70px;
  }
}

@media (min-width: 1200px) {
  .field-name-field-cta > .field-items .paragraphs-item-cta a .image {
    margin-right: 30px;
  }
}

.field-name-field-cta > .field-items .paragraphs-item-cta a a {
  display: block;
  height: 100%;
  position: relative;
}

.field-name-field-cta > .field-items .paragraphs-item-cta a a:hover .field-name-field-title {
  background: #85a732;
  color: #FFF;
  transition: all 200ms ease-in;
}

.field-name-field-cta > .field-items .paragraphs-item-cta a a .field-name-field-title {
  background: #FFF;
  height: 100%;
}

.field-name-field-cta > .field-items .paragraphs-item-cta a a .field-name-field-title .field-items {
  padding: 10px 20px;
  text-transform: uppercase;
}

.field-name-field-cta > .field-items .paragraphs-item-cta a a .field-name-field-image + .field-name-field-title {
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.field-name-field-cta > .field-items .paragraphs-item-cta a a img {
  width: 100%;
}

.field-name-field-cta > .field-items .paragraphs-item-cta a .image {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  margin-bottom: 0;
  width: 50px;
  flex: 0 0 50px;
}

.field-name-field-cta > .field-items .paragraphs-item-cta a span {
  text-align: center;
}

.page_columns .col-lg-3 {
  margin-bottom: 30px;
}

.page_columns .page_column {
  color: #fff;
  height: 100%;
  position: relative;
}

.page_columns .page_column .page_column__link {
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-decoration: none;
  z-index: 3;
}

.page_columns .page_column .page_column__link:hover .page_column__overlay, .page_columns .page_column .page_column__link:focus .page_column__overlay, .page_columns .page_column .page_column__link:active .page_column__overlay {
  opacity: 1;
}

.page_columns .page_column .page_column__title {
  font-size: 1.625rem;
  height: 100%;
  line-height: 1.2;
  min-height: 320px;
  padding: 2.5rem 1.5rem;
  margin-bottom: 0;
  word-break: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

@media (min-width: 1200px) {
  .page_columns .page_column .page_column__title {
    font-size: 1.22rem;
    padding: 3rem 1.5rem;
  }
}

.page_columns .page_column .page_column__overlay {
  min-height: 320px;
  opacity: 0;
  position: relative;
  transition: all 0.2s ease-in-out;
  width: 100%;
}

@media (min-width: 576px) {
  .page_columns .page_column .page_column__overlay {
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.page_columns .page_column:hover .page_column__overlay, .page_columns .page_column:focus .page_column__overlay {
  opacity: 1;
}

.page_columns .page_column.page_column--overlay .page_column__main {
  height: 100%;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

.page_columns .page_column.page_column--overlay .page_column__main img {
  width: 100%;
  height: auto;
}

@media (max-width: 767px) {
  .page_columns .page_column.page_column--overlay .page_column__main img {
    margin-top: -50%;
  }
}

@media (min-width: 576px) {
  .page_columns .page_column.page_column--overlay .page_column__main {
    position: relative;
    left: auto;
    right: auto;
    bottom: auto;
  }
}

.page_columns.color-pink .page_column > .page_column__title {
  background: #f16464;
}

.page_columns.color-pink .page_column .page_column__overlay {
  background: rgba(243, 127, 135, 0.7);
}

.page_columns.color-blue .page_column > .page_column__title {
  background: #31667e;
}

.page_columns.color-blue .page_column .page_column__overlay {
  background: rgba(49, 102, 126, 0.8);
}

.page_intro {
  position: relative;
  padding-bottom: 3rem;
}

@media (min-width: 992px) {
  .page_intro {
    padding-bottom: 4.25rem;
  }
}

@media (min-width: 1200px) {
  .page_intro {
    padding-bottom: 5.5rem;
  }
}

.page_intro .page_intro__main {
  width: 85%;
}

@media (min-width: 768px) {
  .page_intro .page_intro__main {
    width: 80%;
  }
}

.page_intro .page_intro__overlay {
  overflow: hidden;
  position: absolute;
  bottom: 1rem;
  width: 50%;
  right: 0;
}

@media (min-width: 768px) {
  .page_intro .page_intro__overlay {
    width: 45%;
  }
}

@media (min-width: 992px) {
  .page_intro .page_intro__overlay {
    width: 40%;
  }
}

.page_intro .page_intro__title {
  background: rgba(124, 171, 151, 0.8);
  color: #fff;
  font-size: 1.4rem;
  height: 100%;
  line-height: 1.2;
  margin-bottom: 0;
  overflow: hidden;
  padding: 1.15rem 1rem 1rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

@media only screen and (max-width: 359px) {
  .page_intro .page_intro__title {
    font-size: 1.15rem;
  }
}

@media (min-width: 576px) {
  .page_intro .page_intro__title {
    font-size: 2.25rem;
    line-height: 1.3;
    padding: 2.5rem 1.25rem 2rem;
  }
}

@media (min-width: 992px) {
  .page_intro .page_intro__title {
    font-size: 2.625rem;
    padding: 4rem 1.5rem 2rem;
  }
}

@media (min-width: 1200px) {
  .page_intro .page_intro__title {
    font-size: 3.313rem;
    padding: 5rem 2rem 2rem;
  }
}

.page_intro .page_intro__secondary {
  position: relative;
  z-index: 1;
}

@media (min-width: 1200px) {
  .slide__content > div {
    min-height: 540px;
  }
}

.slide_image {
  position: relative;
  overflow: hidden;
}

.slide_image img {
  position: absolute;
  width: auto;
  max-width: none;
  min-width: 1000%;
  min-height: 1000%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) scale(0.1);
  z-index: -1;
}

.slide_teaser .col--left {
  background: #f2f1ef;
  order: 2;
}

.slide_teaser .col--right {
  order: 1;
  padding-left: 0;
  padding-right: 0;
}

.slide_teaser .slide_teaser__info {
  padding: 2rem 1rem;
}

.slide_teaser .slide_teaser__info > * {
  width: 100%;
}

.slide_teaser .slide_teaser__info .slide_teaser__link {
  margin-bottom: 0;
  margin-top: 2rem;
}

.slide_teaser .slide_teaser__info .slide_teaser__link a {
  font-family: "Gotham", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1.125rem;
  font-weight: bold;
  text-decoration: underline;
  text-transform: lowercase;
}

.slide_teaser .slide_teaser__info .slide_teaser__link a:hover, .slide_teaser .slide_teaser__info .slide_teaser__link a:focus {
  text-decoration: none;
}

.slide_teaser .slide_teaser__title {
  margin-bottom: 2rem;
}

.slide_teaser .slide_teaser__intro {
  font-size: 0.875rem;
}

.slide_teaser .slide_teaser__title,
.slide_teaser .slide_teaser__info {
  max-width: 100%;
}

.slide_teaser .slide_teaser__image img {
  height: auto;
}

@media (min-width: 576px) {
  .slide_teaser .col--left {
    order: 1;
  }
  .slide_teaser .col--right {
    order: 2;
  }
  .slide_teaser .slide_teaser__image {
    height: 100%;
    overflow: hidden;
  }
  .slide_teaser .slide_teaser__image img {
    height: 100%;
    max-width: none;
    width: auto;
  }
}

@media (min-width: 768px) {
  .slide_teaser .slide_teaser__info {
    padding: 5rem 2rem;
  }
}

@media (min-width: 1200px) {
  .slide_teaser .container-fluid,
  .slide_teaser .row {
    height: 100%;
  }
  .slide_teaser .slide_teaser__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    margin-left: auto;
    padding: 0 3rem 0 0;
    width: 85%;
  }
  .slide_teaser .slide_teaser__title {
    font-size: 2.05rem;
    margin-bottom: 3rem;
  }
}

@media only screen and (min-width: 1600px) {
  .slide_teaser .slide_teaser__info {
    padding: 0 4rem 0 0;
    width: 75%;
  }
}

.title_image {
  position: relative;
  padding-bottom: 3rem;
}

@media (min-width: 992px) {
  .title_image {
    padding-bottom: 4.25rem;
  }
}

@media (min-width: 1200px) {
  .title_image {
    padding-bottom: 5.5rem;
  }
}

.title_image .title_image__link {
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-decoration: none;
  z-index: 3;
}

.title_image .title_image__main {
  width: 85%;
  margin-left: 15%;
}

@media (min-width: 768px) {
  .title_image .title_image__main {
    width: 80%;
  }
}

@media (min-width: 768px) {
  .title_image .title_image__main {
    margin-left: 20%;
  }
}

.title_image .title_image__overlay {
  overflow: hidden;
  position: absolute;
  bottom: 1rem;
  width: 50%;
  left: 0;
}

@media (min-width: 768px) {
  .title_image .title_image__overlay {
    width: 45%;
  }
}

@media (min-width: 992px) {
  .title_image .title_image__overlay {
    width: 40%;
  }
}

.title_image .title_image__title {
  background: rgba(124, 171, 151, 0.8);
  color: #fff;
  font-size: 1.4rem;
  height: 100%;
  line-height: 1.2;
  margin-bottom: 0;
  overflow: hidden;
  padding: 1.15rem 1rem 1rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

@media only screen and (max-width: 359px) {
  .title_image .title_image__title {
    font-size: 1.15rem;
  }
}

@media (min-width: 576px) {
  .title_image .title_image__title {
    font-size: 2.25rem;
    line-height: 1.3;
    padding: 2.5rem 1.25rem 2rem;
  }
}

@media (min-width: 992px) {
  .title_image .title_image__title {
    font-size: 2.625rem;
    padding: 4rem 1.5rem 2rem;
  }
}

@media (min-width: 1200px) {
  .title_image .title_image__title {
    font-size: 3.313rem;
    padding: 5rem 2rem 2rem;
  }
}

.title_image .title_image__secondary {
  position: relative;
  z-index: 1;
}

@media (min-width: 576px) {
  .view-events.view-display-id-overview_page .views-row-odd .event .col--left {
    order: 1;
  }
  .view-events.view-display-id-overview_page .views-row-odd .event .col--right {
    order: 2;
  }
}

@media (min-width: 576px) {
  .view-events.view-display-id-overview_page .views-row-even .event .col--left {
    order: 2;
  }
  .view-events.view-display-id-overview_page .views-row-even .event .col--right {
    order: 1;
  }
}

.view-events.view-display-id-overview_page .views-row-1 .event .col--left {
  background-color: #f16464;
}

.view-events.view-display-id-overview_page .views-row-1 .event .col--right {
  background-color: #f59289;
}

.view-events.view-display-id-overview_page .views-row-2 .event .col--left {
  background-color: #abc8ca;
}

.view-events.view-display-id-overview_page .views-row-2 .event .col--right {
  background-color: #cfd8d9;
}

.view-events.view-display-id-overview_page .views-row-3 .event .col--left {
  background-color: #51917a;
}

.view-events.view-display-id-overview_page .views-row-3 .event .col--right {
  background-color: #7cab97;
}

.view-events.view-display-id-overview_page .views-row-4 .event .col--left {
  background-color: #eec6b8;
}

.view-events.view-display-id-overview_page .views-row-4 .event .col--right {
  background-color: #f1d0c4;
}

.view-events.view-display-id-overview_page .pager {
  margin-top: 2.5rem;
}

.view-events.view-display-id-overview_page .pager a {
  font-family: "Gotham", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1.125rem;
  font-weight: bold;
  text-decoration: underline;
  text-transform: lowercase;
}

.view-events.view-display-id-overview_page .pager a:hover, .view-events.view-display-id-overview_page .pager a:focus {
  text-decoration: none;
}

.view-events.view-display-id-upcoming_block .slick__slide:nth-child(3n+1) .event, .view-events.view-display-id-homepage_upcoming_block .slick__slide:nth-child(3n+1) .event {
  background-color: #f16464;
}

.view-events.view-display-id-upcoming_block .slick__slide:nth-child(3n+2) .event, .view-events.view-display-id-homepage_upcoming_block .slick__slide:nth-child(3n+2) .event {
  background-color: #eec6b8;
}

.view-events.view-display-id-upcoming_block .slick__slide:nth-child(3n+3) .event, .view-events.view-display-id-homepage_upcoming_block .slick__slide:nth-child(3n+3) .event {
  background-color: #31667e;
}

.view-events.view-display-id-upcoming_block .slick__arrow, .view-events.view-display-id-homepage_upcoming_block .slick__arrow {
  display: none !important;
}

@media (min-width: 576px) {
  .view-events.view-display-id-upcoming_block .slick__arrow, .view-events.view-display-id-homepage_upcoming_block .slick__arrow {
    display: block !important;
  }
}

.view-events.view-display-id-upcoming_block .slick-dots, .view-events.view-display-id-homepage_upcoming_block .slick-dots {
  display: block !important;
}

@media (min-width: 576px) {
  .view-events.view-display-id-upcoming_block .slick-dots, .view-events.view-display-id-homepage_upcoming_block .slick-dots {
    display: none !important;
  }
}

@media (max-width: 575px) {
  .page-events h1,
  .page-events .pager {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 576px) {
  .view-inspiration.view-display-id-overview_page .views-row-odd .inspiration .col--left {
    order: 1;
  }
  .view-inspiration.view-display-id-overview_page .views-row-odd .inspiration .col--right {
    order: 2;
  }
}

@media (min-width: 576px) {
  .view-inspiration.view-display-id-overview_page .views-row-even .inspiration .col--left {
    order: 2;
  }
  .view-inspiration.view-display-id-overview_page .views-row-even .inspiration .col--right {
    order: 1;
  }
}

.view-inspiration.view-display-id-overview_page .views-row:nth-child(4n+1) .inspiration .col--right {
  background-color: #f16464;
}

.view-inspiration.view-display-id-overview_page .views-row:nth-child(4n+1) .inspiration.inspiration--video .col--left::before {
  background: rgba(241, 100, 100, 0.8);
}

.view-inspiration.view-display-id-overview_page .views-row:nth-child(4n+2) .inspiration .col--right {
  background-color: #abc8ca;
}

.view-inspiration.view-display-id-overview_page .views-row:nth-child(4n+2) .inspiration.inspiration--video .col--left::before {
  background: rgba(171, 200, 202, 0.8);
}

.view-inspiration.view-display-id-overview_page .views-row:nth-child(4n+3) .inspiration .col--right {
  background-color: #51917a;
}

.view-inspiration.view-display-id-overview_page .views-row:nth-child(4n+3) .inspiration.inspiration--video .col--left::before {
  background: rgba(81, 145, 122, 0.8);
}

.view-inspiration.view-display-id-overview_page .views-row:nth-child(4n+4) .inspiration .col--right {
  background-color: #eec6b8;
}

.view-inspiration.view-display-id-overview_page .views-row:nth-child(4n+4) .inspiration.inspiration--video .col--left::before {
  background: rgba(238, 198, 184, 0.8);
}

.view-inspiration.view-display-id-overview_page .pager {
  margin-top: 2.5rem;
}

.view-inspiration.view-display-id-overview_page .pager a {
  font-family: "Gotham", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1.125rem;
  font-weight: bold;
  text-decoration: underline;
  text-transform: lowercase;
}

.view-inspiration.view-display-id-overview_page .pager a:hover, .view-inspiration.view-display-id-overview_page .pager a:focus {
  text-decoration: none;
}

.view-inspiration.view-display-id-home_promoted_block .views-row-1 .inspiration .teaser__overlay {
  background-color: rgba(241, 100, 100, 0.8);
}

.view-inspiration.view-display-id-home_promoted_block .views-row-2 .inspiration .teaser__overlay {
  background-color: rgba(171, 200, 202, 0.8);
}

.view-inspiration.view-display-id-home_promoted_block .views-row-3 .inspiration .teaser__overlay {
  background-color: rgba(81, 145, 122, 0.8);
}

.view-inspiration.view-display-id-home_promoted_block .views-row-4 .inspiration .teaser__overlay {
  background-color: rgba(238, 198, 184, 0.8);
}

@media (min-width: 992px) {
  .view-inspiration.view-display-id-home_promoted_block .views-row-5 {
    height: calc(100% - 30px);
  }
}

@media (min-width: 992px) {
  .view-inspiration.view-display-id-home_promoted_block .views-row-5 .inspiration {
    height: 100%;
  }
  .view-inspiration.view-display-id-home_promoted_block .views-row-5 .inspiration .inspiration__image {
    overflow: hidden;
  }
  .view-inspiration.view-display-id-home_promoted_block .views-row-5 .inspiration .inspiration__image,
  .view-inspiration.view-display-id-home_promoted_block .views-row-5 .inspiration .inspiration__image div {
    height: 100%;
  }
  .view-inspiration.view-display-id-home_promoted_block .views-row-5 .inspiration .inspiration__image img {
    width: auto;
    max-width: none;
    height: 100%;
    margin-left: 50%;
    transform: translateX(-50%);
  }
}

@media (min-width: 992px) and (max-width: 991px) {
  .view-inspiration.view-display-id-home_promoted_block .views-row-5 .inspiration .inspiration__image img {
    width: 100%;
    height: auto;
  }
}

.view-inspiration.view-display-id-home_promoted_block .views-row-5 .inspiration .teaser__overlay {
  background-color: rgba(49, 102, 126, 0.8);
}

.view-inspiration.view-display-id-home_recent_block {
  display: none;
}

@media (min-width: 576px) {
  .view-inspiration.view-display-id-home_recent_block {
    display: block;
  }
}

.view-inspiration.view-display-id-home_recent_block .more-link {
  text-align: center;
}

.view-inspiration.view-display-id-home_recent_block .more-link a {
  font-family: "Gotham", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1.125rem;
  font-weight: bold;
  text-decoration: underline;
  text-transform: lowercase;
}

.view-inspiration.view-display-id-home_recent_block .more-link a:hover, .view-inspiration.view-display-id-home_recent_block .more-link a:focus {
  text-decoration: none;
}

.view-inspiration.view-display-id-home_recent_block .views-row-1 .inspiration {
  background-color: #f16464;
}

.view-inspiration.view-display-id-home_recent_block .views-row-2 .inspiration {
  background-color: #abc8ca;
}

.view-inspiration.view-display-id-home_recent_block .views-row-3 .inspiration {
  background-color: #51917a;
}

.view-inspiration.view-display-id-home_recent_block .views-row-4 .inspiration {
  background-color: #eec6b8;
}

.view-inspiration.view-display-id-recent_block .views-row-1 .inspiration .colored__bg {
  background-color: #51917a;
}

.view-inspiration.view-display-id-recent_block .views-row-2 .inspiration .colored__bg {
  background-color: #abc8ca;
}

.view-inspiration.view-display-id-recent_block .views-row-3 .inspiration .colored__bg {
  background-color: #eec6b8;
}

@media (max-width: 575px) {
  .page-inspiration h1,
  .page-inspiration .pager {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 575px) {
  .view-search .view-header,
  .view-search .view-filters {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.view-search .view-header,
.view-search .view-empty {
  font-family: "Gotham", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 30px;
}

.view-search .view-header .query,
.view-search .view-empty .query {
  color: #51917a;
}

.view-search .view-filters {
  margin-bottom: 60px;
}

.view-search .view-filters form {
  max-width: 100%;
  position: relative;
  width: 100%;
}

.view-search .view-filters form .views-widget-filter-search_api_views_fulltext {
  position: relative;
}

.view-search .view-filters form .form-text {
  background: #fff;
  border: 2px solid #51917a;
  color: #51917a;
  font-family: "Gotham", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: bold;
}

.view-search .view-filters form .form-text:focus {
  outline: #51917a auto 2px;
}

.view-search .view-filters form .js-clear-field-button {
  background: url("../images/svg/close-green.svg") center center no-repeat;
  background-color: #fff;
  top: 15px;
  right: 50px;
}

.view-search .view-filters form .js-clear-field-button:hover, .view-search .view-filters form .js-clear-field-button:focus {
  background: url("../images/svg/close.svg") center center no-repeat;
  background-color: #fff;
}

@media (min-width: 1200px) {
  .view-search .view-filters form .js-clear-field-button {
    top: 18px;
    right: 56px;
  }
}

.view-search .view-filters form .form-submit {
  background: transparent;
  height: 50px;
  overflow: hidden;
  padding: 0;
  position: relative;
  text-indent: 101%;
  width: 50px;
  z-index: 1;
}

.view-search .view-filters form .views-submit-button {
  height: 50px;
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.2s ease-in-out;
  width: 50px;
}

.view-search .view-filters form .views-submit-button::before {
  content: "\f002";
  color: #51917a;
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.view-search .view-filters form .views-submit-button:hover, .view-search .view-filters form .views-submit-button:focus {
  opacity: .5;
}

@media (min-width: 992px) {
  .view-search {
    position: relative;
  }
  .view-search .view-header,
  .view-search .view-filters,
  .view-search .view-empty {
    height: 50px;
    width: 50%;
  }
  .view-search .view-header,
  .view-search .view-empty {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 90px;
  }
  .view-search .view-header .query,
  .view-search .view-empty .query {
    margin-left: 7px;
  }
  .view-search .view-header p,
  .view-search .view-empty p {
    margin-bottom: 0;
  }
  .view-search .view-filters {
    padding-left: 2rem;
    position: absolute;
    top: 0;
    right: 0;
  }
  .view-search .view-filters form .form-submit,
  .view-search .view-filters form .views-submit-button {
    height: 50px;
    width: 50px;
  }
}

@media (min-width: 1200px) {
  .view-search {
    position: relative;
  }
  .view-search .view-header,
  .view-search .view-filters,
  .view-search .view-empty {
    font-size: 1.25rem;
    height: 56px;
  }
  .view-search .view-header,
  .view-search .view-empty {
    margin-bottom: 120px;
  }
  .view-search .view-filters {
    padding-left: 4rem;
  }
  .view-search .view-filters form .form-submit,
  .view-search .view-filters form .views-submit-button {
    height: 56px;
    width: 56px;
  }
}

@media (min-width: 576px) {
  .view-search .view-content .views-row-odd .node-search-result .col--left {
    order: 1;
  }
  .view-search .view-content .views-row-odd .node-search-result .col--right {
    order: 2;
  }
}

@media (min-width: 576px) {
  .view-search .view-content .views-row-even .node-search-result .col--left {
    order: 2;
  }
  .view-search .view-content .views-row-even .node-search-result .col--right {
    order: 1;
  }
}

.view-search .view-content .views-row-1 .node-search-result .col--right {
  background-color: #f16464;
}

.view-search .view-content .views-row-1 .node-search-result.inspiration--video .col--left::before {
  background: rgba(241, 100, 100, 0.8);
}

.view-search .view-content .views-row-2 .node-search-result .col--right {
  background-color: #abc8ca;
}

.view-search .view-content .views-row-2 .node-search-result.inspiration--video .col--left::before {
  background: rgba(171, 200, 202, 0.8);
}

.view-search .view-content .views-row-3 .node-search-result .col--right {
  background-color: #51917a;
}

.view-search .view-content .views-row-3 .node-search-result.inspiration--video .col--left::before {
  background: rgba(81, 145, 122, 0.8);
}

.view-search .view-content .views-row-4 .node-search-result .col--right {
  background-color: #eec6b8;
}

.view-search .view-content .views-row-4 .node-search-result.inspiration--video .col--left::before {
  background: rgba(238, 198, 184, 0.8);
}

@media (max-width: 575px) {
  .page-search h1,
  .page-search .pager {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.view .views-row {
  margin: 0 0 30px;
}

.content__wrapper {
  overflow: hidden;
  position: relative;
  padding-top: 0;
  width: 100%;
}

@media (min-width: 768px) {
  .content__wrapper {
    padding-bottom: 8rem;
  }
}

@media (max-width: 767px) {
  .content__wrapper > .container {
    padding-left: 0;
    padding-right: 0;
  }
  .page-node-done .content__wrapper > .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.content__wrapper .content__top {
  left: calc(-1 * (100vw - 100%) / 2);
  min-width: 100vw;
  padding: 0;
  margin-bottom: 4rem;
}

.content__wrapper .node-unpublished {
  background-color: unset;
}

@media (max-width: 575px) {
  .region-content-top {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.header__content .region-cta-links-mobile {
  clear: both;
}

.header__content .cta-links-mobile {
  width: 80%;
  float: left;
}

@media (min-width: 992px) {
  .header__content .cta-links-mobile {
    display: none;
  }
}

.header__content .cta-links-mobile .paragraphs-item-cta {
  height: 40px;
  display: flex;
}

.header__content .cta-links-mobile .paragraphs-item-cta a {
  flex: 1 1 auto;
  padding: 10px;
}

.header__content .cta-links-mobile .paragraphs-item-cta a .image {
  margin: 0 auto;
  flex: 0 0 30px;
}

.header__content .cta-links-mobile .paragraphs-item-cta a span {
  display: none;
}

.doormat {
  margin-bottom: 3rem;
  text-align: center;
  display: none;
}

@media (min-width: 992px) {
  .doormat {
    display: block;
    margin-bottom: 5rem;
  }
}

footer {
  position: relative;
  display: none;
}

@media (min-width: 992px) {
  footer {
    display: block;
  }
}

footer .footer__top > span {
  flex: 1 0 auto;
  height: 10px;
}

@media (min-width: 768px) {
  footer .footer__top > span {
    height: 20px;
  }
}

footer .footer__content {
  background: #f2f1ef;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

footer .block-logo-block {
  max-width: 180px;
}

header {
  position: fixed;
  top: 0;
  width: 100%;
  background: #fff;
  z-index: 996;
}

.admin-menu header {
  padding-top: 29px;
}

@media (min-width: 992px) {
  header .header__top > span {
    flex: 1 0 auto;
    height: 10px;
  }
}

@media (min-width: 992px) and (min-width: 768px) {
  header .header__top > span {
    height: 20px;
  }
}

header .header__content {
  margin: 0 auto;
}

header .mobile__toggle {
  width: 20%;
  float: right;
  background: #fff;
  border: 0;
  cursor: pointer;
  font-size: 20px;
  line-height: 40px;
  height: 40px;
  padding: 0;
}

header .mobile__toggle i {
  margin: 0 auto;
}

header .header__logo-mobile {
  clear: both;
  display: block;
  width: 10rem;
  margin-left: 2.4rem;
}

header .header__logo-mobile img {
  max-width: none;
  width: 100%;
}

@media (min-width: 992px) {
  header .header__logo-mobile {
    display: none;
  }
}

header .region-header {
  background: #fff;
  box-shadow: -3px 0 5px rgba(51, 51, 51, 0.25);
  display: none;
  position: fixed;
  top: 40px;
  right: 0;
  width: 90%;
  height: calc(100% - 40px);
  overflow-x: hidden;
  z-index: 996;
}

.admin-menu header .region-header {
  top: 69px;
  height: calc(100% - 40px - 29px);
}

@media (min-width: 992px) {
  .admin-menu header .region-header {
    top: initial;
  }
}

@media (min-width: 992px) {
  header .region-header {
    position: relative;
    top: 0;
    right: initial;
    box-shadow: none;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
}

header .region-header.mobile--open {
  display: block;
}

header .region-header .header__logo {
  display: block;
  width: 10rem;
  padding-top: 0.3rem;
  margin: 0 30px 0;
}

header .region-header .header__logo img {
  max-width: none;
  width: 100%;
}

@media (min-width: 992px) {
  header .region-header .header__logo {
    margin: 0 30px;
  }
}
