body {
  overflow-x: hidden;
  // OSX font rendering
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  font-family: inherit;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

img {
  max-width: 100%;
}

// Moved from layout/_content.scss so this can be overridden
.region-content:not(.paragraphs-item-wysiwyg) img {
  height: auto;
  width: 100%;
}

.field-type-text-long {
  img.media-element {
    height: auto;
  }
}
