// inspiration page
.inspiration {
  video {
    width: 100%;
  }

  // Split for teaser + teaser-without-image + teaser mini view modes.
  &.node-teaser,
  &.node-teaser--mini,
  &.node-teaser--vertical,
  &.node-promoted,
  &.node-promoted--vertical {
    position: relative;
    color: color(white);
  }

  &.node-teaser {
    color: $white;

    .node__wrapper,
    // FOR IE:
    .node__wrapper .col--left,
    .node__wrapper .col--right {
      @include media-breakpoint-up(sm) {
        min-height: 320px;
      }

      @include media-breakpoint-up(md) {
        min-height: 328px;
      }

      @include media-breakpoint-up(lg) {
        min-height: 271px;
      }

      @include media-breakpoint-up(xl) {
        min-height: 249px;
      }
    }

    .col--right {
      @include padding-teaser;
    }

    .col--left {
      @include media-breakpoint-down(xs) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .inspiration__info {
      .more__link {
        display: block;
        margin-top: 1rem;
      }

      @include media-breakpoint-up(sm) {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
        height: 100%;

        .more__link {
          margin-top: auto;
        }
      }
    }

    .field-name-field-image {
      max-height: 220px;
      overflow: hidden;

      img {
        height: auto;
        max-width: 100%;
        width: 100%;
      }

      @include media-breakpoint-up(sm) {
        height: 100%;
        max-height: 100%;

        .field-items,
        .field-item {
          height: 100%;
        }

        img {
          height: 100%;
          max-width: none;
          position: absolute;
          top: 0;
          left: 0;
          width: auto;
        }
      }

      @include media-breakpoint-up(xl) {
        img {
          height: auto;
          max-width: 100%;
          width: 100%;
        }
      }
    }

    &.inspiration--video {
      // IF HEADER IMAGE IS VIDEO
      .col--left {
        position: relative;

        &::before {
          border: 4px solid $white;
          border-radius: 50%;
          content: "";
          display: block;
          height: 70px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: $transition-base;
          width: 70px;
          z-index: 1;
        }

        &::after {
          color: $white;
          content: "\f04b";
          display: block;
          font-family: $font-awesome;
          font-weight: 400;
          font-size: 26px;
          height: 36px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-45%, -50%);
          text-align: center;
          width: 36px;
          z-index: 2;
        }
      }
    }
  }

  &.node-teaser--mini {
    @include media-breakpoint-up(md) {
      height: 100%;

      .node__wrapper {
        height: 100%;
      }
    }

    .inspiration__image {
      height: 100%;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      .field,
      .field-items,
      .field-item {
        height: 100%;
      }

      img {
        height: 100%;
        max-width: none;
        width: auto;

        @include media-breakpoint-up(lg) {
          height: auto;
          max-width: 100%;
          width: 100%;
        }
      }
    }

    .col--right {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      display: flex;
      flex-direction: column;

      .more__link {
        margin-top: auto;
      }
    }
  }

  // MORE INSPIRATION TEASERS (TAG RELATED)
  &.node-teaser--vertical {
    position: relative;

    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .teaser__link {
      @include media-supports-hover {
        @include media-breakpoint-up(md) {
          &:hover,
          &:focus {
            & + .teaser__overlay {
              height: 100%;
              opacity: 1;
            }
          }
        }
      }
    }

    .teaser__overlay {
      display: none;
      flex-direction: column;
      height: calc(100% - 172px);
      overflow: hidden;
      opacity: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      transition: $transition-collapse;
      width: 100%;
      z-index: 1;

      .overlay__wrapper {
        height: 100%;
        padding: 2rem 1rem;
        display: flex;
        flex-direction: column;

        .more__link {
          margin-top: auto;
        }
      }

      @include media-breakpoint-up(md) {
        display: flex;
      }

      @include media-breakpoint-up(lg) {
        height: calc(100% - 237px);
      }

      @include media-breakpoint-up(xl) {
        height: calc(100% - 286px);
      }
    }

    .inspiration__image {
      img {
        height: auto;
        max-width: 100%;
        width: 100%;
      }
    }

    .inspiration__title {
      @include media-breakpoint-down(md) {
        font-size: $h4-font-size;
      }
    }

    .inspiration__teaser {
      margin-top: -5rem;
      padding: 2rem 1rem;
      position: relative;

      @include media-breakpoint-up(sm) {
        margin-top: -8rem;
      }

      @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-top: 0;

        .more__link {
          margin-top: auto;
        }
      }
    }
  }

  &.node-promoted,
  &.node-promoted--vertical {
    .teaser__overlay {
      height: 100%;
      overflow: hidden;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      transition: opacity .2s ease-in-out, $transition-collapse;

      @include media-breakpoint-up(lg) {
        height: 0;
        opacity: 0;
      }

      .overlay__wrapper {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        padding: 1.5rem;

        @include media-breakpoint-up(lg) {
          padding: 2rem;
        }
      }
    }

    .teaser__link {
      @include media-supports-hover {
        @include media-breakpoint-up(lg) {
          &:hover,
          &:focus {
            & ~ .teaser__overlay {
              height: 100%;
              opacity: 1;
            }
          }
        }
      }
    }

    @include media-breakpoint-down(md) {
      max-height: 300px;
      overflow: hidden;
    }
  }

  &.node-promoted--vertical {
  }

  &.node-full {
    h1 {
      margin-bottom: .75rem;
    }

    .inspiration__date {
      font-size: $font-size-lg;
      margin-bottom: 2rem;

      @include media-breakpoint-up(sm) {
        margin-bottom: 3.5rem;
      }
    }

    .inspiration__description,
    .inspiration__header {
      margin-bottom: 1.5rem;

      @include media-breakpoint-up(sm) {
        margin-bottom: 2.5rem;
      }
    }

    .inspiration__header {
      &.header--stacked {
        margin-bottom: 4rem;
        padding-bottom: 2rem;
        position: relative;

        .header__main {
          min-height: 160px;
          padding-left: 10%;
        }

        .header__overlay {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 45%;
        }

        @include media-breakpoint-up(sm) {
          padding-bottom: 3rem;

          .header__main {
            min-height: 260px;
          }
        }

        @include media-breakpoint-up(md) {
          .header__main {
            min-height: 350px;
          }
        }

        @include media-breakpoint-up(lg) {
          padding-bottom: 4.5rem;

          .header__main {
            min-height: 450px;
          }
        }

        @include media-breakpoint-up(xl) {
          .header__main {
            min-height: 560px;
          }
        }
      }
    }

    .paragraphs-items {
      margin: 2.5rem 0 5rem;

      @include media-breakpoint-up(sm) {
        margin: 3.5rem 0 7rem;
      }
    }
  }
}
