@font-face {
  font-family: 'Gotham Book';
  src:  url('../fonts/Gotham/Gotham-Book.woff2') format('woff2'),
        url('../fonts/Gotham/Gotham-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src:  url('../fonts/Gotham/Gotham-Light.woff2') format('woff2'),
        url('../fonts/Gotham/Gotham-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src:  url('../fonts/Gotham/Gotham-Bold.woff2') format('woff2'),
        url('../fonts/Gotham/Gotham-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@import "framework";

@import "utils/variables/_bootstrap-variables.scss";
@import "utils/variables/_breakpoints.scss";
@import "utils/variables/_colors.scss";
@import "utils/variables/_font-weight.scss";
@import "utils/variables/_variables.scss";
@import "utils/mixins/_block.scss";
@import "utils/mixins/_button.scss";
@import "utils/mixins/_colors.scss";
@import "utils/mixins/_columns.scss";
@import "utils/mixins/_flex.scss";
@import "utils/mixins/_form.scss";
@import "utils/mixins/_icons.scss";
@import "utils/mixins/_image-title.scss";
@import "utils/mixins/_layout.scss";
@import "utils/mixins/_link.scss";
@import "utils/mixins/_max-width-padding.scss";
@import "utils/mixins/_max-width.scss";
@import "utils/mixins/_padding.scss";
@import "utils/mixins/_pager.scss";
@import "utils/mixins/_shapes.scss";
@import "utils/functions/_media-queries.scss";
@import "utils/classes/_flex.scss";

@import "base/_base.scss";
@import "base/_fonts.scss";
@import "base/_form.scss";
@import "base/_general.scss";
@import "base/_typography.scss";
@import "components/block/_cta-links.scss";
@import "components/block/_exposed-widget.scss";
@import "components/block/_facetapi.scss";
@import "components/block/_footer-text.scss";
@import "components/block/_header-links.scss";
@import "components/block/_mailchip-signup-newsletter-toerisme-limburg.scss";
@import "components/block/_menu-block-main-menu-block.scss";
@import "components/block/_poll.scss";
@import "components/block/_search.scss";
@import "components/block/_sliding-popup.scss";
@import "components/block/_social-links.scss";
@import "components/block/_support-logos.scss";
@import "components/block/_testimonial.scss";
@import "components/block/_view-filter.scss";
@import "components/block/_views.scss";
@import "components/field/_components.scss";
@import "components/field/_date.scss";
@import "components/field/_subscription-link.scss";
@import "components/field/_theme.scss";
@import "components/menu/_main-menu.scss";
@import "components/misc/_js-clear-field-buttons.scss";
@import "components/misc/_mailchimp.scss";
@import "components/misc/_messages.scss";
@import "components/misc/_pager.scss";
@import "components/misc/_sharethis.scss";
@import "components/misc/_slick-theme.scss";
@import "components/misc/_tabs.scss";
@import "components/misc/_text-ellipsis.scss";
@import "components/misc/_user.scss";
@import "components/node/_event.scss";
@import "components/node/_full.scss";
@import "components/node/_home.scss";
@import "components/node/_inspiration.scss";
@import "components/node/_news.scss";
@import "components/node/_poll.scss";
@import "components/node/_teaser.scss";
@import "components/node/_view-mode--search-result.scss";
@import "components/node/_webform.scss";
@import "components/paragraphs/_coworker-columns.scss";
@import "components/paragraphs/_cta.scss";
@import "components/paragraphs/_page-columns.scss";
@import "components/paragraphs/_page-intro.scss";
@import "components/paragraphs/_slide.scss";
@import "components/paragraphs/_title-image.scss";
@import "components/views/_events.scss";
@import "components/views/_inspiration.scss";
@import "components/views/_search.scss";
@import "components/views/_views.scss";
@import "layout/_content.scss";
@import "layout/_cta-links-mobile.scss";
@import "layout/_doormat.scss";
@import "layout/_footer.scss";
@import "layout/_header.scss";
