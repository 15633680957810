.node-search-result {
  position: relative;
  color: $white;

  @include media-breakpoint-down(lg) {
    word-break: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }

  .row {
    overflow: hidden;
  }

  .col--left,
  .col--right {
    @include media-breakpoint-up(sm) {
      min-height: 320px;
    }

    @include media-breakpoint-up(lg) {
      min-height: 270px;
    }
  }

  .col--right {
    @include padding-teaser(TRUE);
    display: flex;
    flex-direction: column;
  }

  .field-name-field-image {
    max-height: 220px;
    overflow: hidden;

    @include media-breakpoint-up(sm) {
      height: 100%;
      max-height: none;

      .field-items,
      .field-item {
        height: 100%;
      }
    }

    img {
      width: 100%;
      height: auto;

      @include media-breakpoint-up(sm) {
        height: 100%;
        max-width: none;
        position: absolute;
        top: 0;
        left: 50%;
        width: auto;
        transform: translateX(-50%);
      }

      @include media-breakpoint-up(xl) {
        height: auto;
        width: 100%;
      }
    }
  }

  .more__link {
    display: block;
    margin-top: 1rem;

    @include media-breakpoint-up(sm) {
      margin-top: auto;
    }
  }


  a {
    text-decoration: none;

    &:hover .more__link {
      text-decoration: none;
    }
  }
}
