.sharethis-buttons {
  span {
    margin-left: $grid-gutter-width / 3;
    cursor: pointer;

    &.st_facebook_custom::after {
      content: "\f39e";
      font-family: $font-awesome-brands;
    }

    &.st_email_custom::after {
      content: "\f0e0";
      font-family: $font-awesome;
    }

    &.st_twitter_custom::after {
      content: "\f099";
      font-family: $font-awesome-brands;
    }

    &.st_linkedin_custom::after {
      content: "\f0e1";
      font-family: $font-awesome-brands;
    }
  }
}
