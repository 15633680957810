@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "<";
$slick-next-character: ">";
$slick-dot-character: "•";
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75;
$slick-opacity-on-hover: 1;
$slick-opacity-not-active: 0;


/* Slider */

.slick {
  position: relative;
}

.slick-track {
  display: flex;

  .slide {
    display: flex;
    height: auto;

    .slide__content {
      height: 100%;
      width: 100%;

      > div {
        height: 100%;
      }
    }
  }
}

/* Arrows */

.slick-prev,
.slick-next {
  background: $brand-turquoise-light;
  position: absolute;
  display: block;
  height: 40px;
  width: 40px;
  line-height: 0;
  font-size: 0;
  color: transparent;
  top: 20%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  
  &:hover, &:focus {
    outline: none;
    color: transparent;
  }

  &:hover {
    background: $brand-green;
  }

  &:not(.slick-disabled) {
    cursor: pointer;
  }
  
  &.slick-disabled {
    opacity: $slick-opacity-not-active;
  }
  
  &::before {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $headings-font-family;
    font-size: 20px;
    font-weight: $font-weight-bold;
    line-height: 1;
    color: $slick-arrow-color;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  @include media-breakpoint-up(sm) {
    top: 50%;
  }
}

.slick-prev {
  left: -10px;

  @include media-breakpoint-up(md) {
    left: -30px;
  }

  @media only screen and (min-width: 1300px) {
    left: -80px;
  }
  
  [dir="rtl"] & {
    left: auto;
    right: -15px;
  }
  
  &::before {
    content: $slick-prev-character;
    
    [dir="rtl"] & {
      content: $slick-next-character;
    }
  }
}

.slick-next {
  right: -10px;

  @include media-breakpoint-up(md) {
    right: -30px;
  }

  @media only screen and (min-width: 1300px) {
    right: -80px;
  }
  
  [dir="rtl"] & {
    left: -15px;
    right: auto;
  }
  
  &::before {
    content: $slick-next-character;
    
    [dir="rtl"] & {
      content: $slick-prev-character;
    }
  }
}

// Override slick "theme" for optionset "slides".
.slick--optionset--slides {
  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;
  }
}

/* Dots */

.slick-dotted.slick-slider {
  margin-bottom: 52px;
}

.slick-dots {
  position: absolute;
  bottom: -52px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  
  li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 1rem 0;
    padding: 0;
    cursor: pointer;
    
    button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 1;
      font-size: 18px;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      
      &:hover, &:focus {
        outline: none;
        
        &::before {
          opacity: $slick-opacity-on-hover;
        }
      }
      
      &::before {
        background: $brand-pink-light;
        position: absolute;
        top: 5px;
        left: 5px;
        content: "";
        width: 10px;
        height: 10px;
        text-align: center;
        color: $slick-dot-color;
        opacity: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    
    &.slick-active button::before {
      background: $brand-red;
      opacity: 1;
    }
  }
}