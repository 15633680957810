.event {
  // Generic shared styling.
  .event__description {
    margin-bottom: 1rem;
  }

  .event__date {
    font-family: $headings-font-family;
    font-size: 1.625rem;
    font-weight: $font-weight-bold;
    line-height: 1;

    .d {
      display: block;
      font-size: 4rem;
      line-height: 1;
    }

    @include media-breakpoint-up(sm) {
      font-size: 2rem;
      padding-top: 1rem;
      padding-bottom: 1rem;

      .d {
        font-size: 6rem;
        line-height: .85;
        margin-right: 0;
      }
    }

    @include media-breakpoint-up(md) {
      font-size: 1.875rem;

      .d {
        font-size: 8rem;
      }
    }

    @include media-breakpoint-up(lg) {
      font-size: 2.625rem;

      .d {
        font-size: 9.75rem;
      }
    }
  }

  // Split for full view mode.
  &.node-full {
    .col--right {
      @include shape-white-behind;
      position: relative;
      padding: $grid-gutter-width;
      background: $white;

      @include media-breakpoint-up(lg) {
        padding-left: 5rem;
        padding-right: 5rem;
      }
    }

    .event__info {
      padding: 1.5rem;
      background: $brand-green;
      color: $white;
      position: relative;
      z-index: 1;

      @include media-breakpoint-up(sm) {
        padding-bottom: 7rem;
      }

      @include media-breakpoint-up(md) {
        padding-bottom: 10rem;
        margin-top: 4rem;
      }

      @include media-breakpoint-up(lg) {
        margin-top: 5rem;
      }
    }

    .event__location {
      font-size: $font-size-lg;
      line-height: 1.2;
      width: calc(100% - 70px);
      margin-top: .5rem;
    }

    .event__icon {
      background: $white;
      display: flex;
      padding: 15px;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    .event__ical {
      padding: 1rem 1rem 2rem;
      position: relative;
      background: $white;
      z-index: 1;

      @include media-breakpoint-up(sm) {
        padding: 2rem 0;
      }

      .ical-link a {
        color: $brand-green;
        display: inline-block;
        padding-left: 40px;
        padding-top: 13px;
        position: relative;
        text-decoration: none;

        &::before {
          background: url("../images/svg/ical-icon.svg") center center no-repeat;
          background-size: contain;
          content: "";
          display: inline-block;
          height: 30px;
          position: absolute;
          top: 0;
          left: 0;
          width: 30px;
        }

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }

    .event__back_link,
    .inspiration__sharing {
      padding: 0 $grid-gutter-width * 1.5;

      @include media-breakpoint-up(md) {
        padding: 0 ($grid-gutter-width / 2);
      }
    }
  }

  // Split for teaser + teaser-without-image + teaser mini view modes.
  &.node-teaser,
  &.node-teaser-without-image,
  &.node-teaser--mini {
    position: relative;
    color: color(white);
  }

  &.node-teaser,
  &.node-teaser-without-image {
    .col--left {
      @include padding-teaser;
    }

    // Teasers (promoted, homepage) are displayed in 2 columns:
    // - Information on the left.
    // - An image on the right.
    .col--left {
      background-color: theme-color(green);
    }

    .event__info {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.node-teaser-without-image,
  &.node-teaser--mini {
    position: relative;
    color: color(white);
  }

  // Promoted to front event.
  &.node-teaser {
    .event__info {
      flex-direction: column;
      align-items: flex-start;
      padding-left: 15px;
      padding-right: 15px;

      > div,
      > h3 {
        width: 100%;
      }
    }

    .event__title {
      font-size: $font-size-lg;
      line-height: 1.2;
      margin: 1rem 0 .5rem;
    }

    .event__location {
      margin: 0 0 1rem;
    }

    .more__link {
      display: block;
      margin-top: .5rem;
    }

    .node__wrapper {
      margin-bottom: 4rem;
      margin-top: 6rem;

      @include media-breakpoint-up(lg) {
        margin-bottom: 8rem;
        margin-top: 8rem;
      }
    }

    .col--left {
      z-index: 1;
    }

    .col--right {
      height: calc(100% + 4rem);
      overflow: hidden;
      padding-left: 0;
      padding-right: 0;
      position: absolute;
      top: -2rem;
      right: 0;
      z-index: 0;

      .field-name-field-image,
      .field-items,
      .field-item {
        height: 100%;
      }

      img {
        height: 100%;
        max-width: none;
        width: auto;
      }

      .event__icon {
        background: $brand-green;
        height: 60px;
        padding: 10px;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 60px;

        svg {
          height: auto;
          width: 100%;
        }
      }

      @include media-breakpoint-up(lg) {
        height: calc(100% + 7.6rem);
        top: -3.8rem;
      }

      @include media-breakpoint-up(xl) {
        img {
          height: auto;
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }

  // Split for teaser mini view mode.
  // Mini Teasers are displayed in 1 column:
  // - Date on the top.
  // - Information on the bottom.
  // Also in carousels.
  &.node-teaser--mini {
    text-align: center;

    .event__info {
      padding: 2rem 1.5rem 0;

      @include media-breakpoint-up(md) {
        padding: 3.5rem 2rem 0;
      }
    }

    .event__title {
      font-family: $font-family-base;
      font-size: $h5-font-size;
      font-weight: $font-weight-base;
      line-height: 1.2;
      margin: 1.5rem 0 1rem;
    }

    .event__location {
      margin-bottom: 2rem;
    }

    .more__link {
      display: block;
      margin-top: auto;
      padding: 0 1.5rem 2rem;
    }

    .slick-track & {
      display: flex;
      flex-direction: column;
    }
  }

  // Split for teaser without image view mode.
  // Teasers without image (overview) are displayed in 2 columns:
  // - Date on the left.
  // - Information on the right.
  &.node-teaser-without-image {
    .col--right {
      @include padding-teaser;
    }

    .event__info {
      align-items: center;
      justify-content: center;

      .event__date {
        text-align: center;
      }
    }
  }
}
