@mixin alternate-layout($classname: "event") {
  .views-row-odd .#{$classname} {
    @include media-breakpoint-up(sm) {
      .col--left { order: 1 }
      .col--right { order: 2 }
    }
  }

  .views-row-even .#{$classname} {
    @include media-breakpoint-up(sm) {
      .col--left { order: 2 }
      .col--right { order: 1 }
    }
  }
}

@mixin view-mobile-layout {
  @include media-breakpoint-down(xs) {
    h1,
    .pager {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}