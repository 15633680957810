footer {
  position: relative;

  display: none;

  @include media-breakpoint-up(lg) {
    display: block;
  }

  .footer__top {
    @include colored-strip;
  }

  .footer__content {
    background: $brand-turquoise-bg;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .block-logo-block {
    max-width: 180px;
  }
}
