@mixin button {
  display: inline-block;
  padding: 10px 20px;
  background: $c-green;
  color: $c-white;

  &:hover,
  &:focus {
    background: $c-black;
    color: $c-white;
  }
}
