@mixin flex($wrap: wrap) {
  display: flex;
  flex-wrap: $wrap;
  overflow: hidden;
}

@mixin flex-2 {
  width: 49%;
  margin-right: 2%;

  &:nth-of-type(2n) {
    margin-right: 0;
  }
}

@mixin flex-3 {
  width: 32%;

  &,
  &:nth-of-type(2n) {
    margin-right: 2%;
  }

  &:nth-of-type(3n) {
    margin-right: 0;
  }
}

@mixin flex-4 {
  width: 24%;

  &,
  &:nth-of-type(2n),
  &:nth-of-type(3n) {
    // Workaround for IE and Edge.
    // https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/6498823
    //width: calc((100% - 4 * 24%) / 3);
    margin-right: calc((100% - 4 * 24%) / 100 * 33.333);
  }

  &:nth-of-type(4n) {
    margin-right: 0;
  }
}

@mixin flex-5 {
  width: 19%;

  &,
  &:nth-of-type(2n),
  &:nth-of-type(3n),
  &:nth-of-type(4n) {
    margin-right: calc((100% - 5 * 19%) / 4);
  }

  &:nth-of-type(5n) {
    margin-right: 0;
  }
}

@mixin flex-6 {
  width: 16%;

  &,
  &:nth-of-type(2n),
  &:nth-of-type(3n),
  &:nth-of-type(4n),
  &:nth-of-type(5n) {
    margin-right: calc((100% - 6 * 16%) / 5);
  }

  &:nth-of-type(6n) {
    margin-right: 0;
  }
}

@mixin flex-7 {
  width: 14%;

  &,
  &:nth-of-type(2n),
  &:nth-of-type(3n),
  &:nth-of-type(4n),
  &:nth-of-type(5n),
  &:nth-of-type(6n) {
    margin-right: calc((100% - 7 * 14%) / 100 * 66.666);
  }

  &:nth-of-type(7n) {
    margin-right: 0;
  }
}

@mixin flex-8 {
  width: 12%;

  &,
  &:nth-of-type(2n),
  &:nth-of-type(3n),
  &:nth-of-type(4n),
  &:nth-of-type(5n),
  &:nth-of-type(6n),
  &:nth-of-type(7n) {
    margin-right: calc((100% - 8 * 12%) / 7);
  }

  &:nth-of-type(8n) {
    margin-right: 0;
  }
}

@mixin flex-9 {
  width: 10.5%;

  &,
  &:nth-of-type(2n),
  &:nth-of-type(3n),
  &:nth-of-type(4n),
  &:nth-of-type(5n),
  &:nth-of-type(6n),
  &:nth-of-type(7n),
  &:nth-of-type(8n) {
    margin-right: calc((100% - 9 * 10.5%) / 8);
  }

  &:nth-of-type(9n) {
    margin-right: 0;
  }
}
