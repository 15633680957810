.menu-name-main-menu {
  > .menu {
    list-style-type: none;
    margin: 0;
    padding: 0;

    @include media-breakpoint-up(lg) {
      display: flex;
    }

    > li {
      @include media-breakpoint-up(lg) {
        &::after {
          content: "|";
          color: $gray-300;
          display: inline-block;
        }

        &:last-child::after {
          display: none;
        }
      }

      a {
        border-bottom: 2px solid $gray-300;
        display: block;
        font-family: $headings-font-family;
        font-weight: $font-weight-bold;
        padding: ($grid-gutter-width / 2) $grid-gutter-width;
        text-decoration: none;

        @include media-breakpoint-up(lg) {
          border: 0;
          display: inline-block;
          padding: $grid-gutter-width / 2;
        }

        &:hover,
        &:focus,
        &.active,
        &.active-trail {
          color: $brand-red;
        }
      }
    }

    .menu {
      display: none;
    }
  }
}
