@mixin more-link {
  font-family: $headings-font-family;
  font-size: $font-size-lg;
  font-weight: $font-weight-bold;
  text-decoration: underline;
  text-transform: lowercase;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

@mixin cover-all-link {
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-decoration: none;
  z-index: 3;
}

@mixin cta-link($font-size: $h5-font-size, $icon-width: 50px, $padding: 20px $grid-gutter-width * 2) {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  padding: ($grid-gutter-width / 3) $grid-gutter-width;
  color: $white;
  font-family: $headings-font-family;
  font-weight: $font-weight-bold;
  line-height: $headings-line-height;
  text-decoration: none;

  @include media-breakpoint-up(lg) {
    font-size: $font-size-lg;
    padding: ($grid-gutter-width / 2) $grid-gutter-width;
  }

  @include media-breakpoint-up(xl) {
    font-size: $font-size;
    padding: $padding;
  }

  .image {
    flex: 0 0 30px;
    margin-right: $grid-gutter-width / 2;
    text-align: center;
    width: 30px;

    img {
      width: 100%;
      height: auto;
    }

    @include media-breakpoint-up(lg) {
      flex: 0 0 $icon-width;
      margin-right: $grid-gutter-width;
      width: $icon-width;
    }

    @include media-breakpoint-up(xl) {
      margin-right: $grid-gutter-width;
    }
  }
}