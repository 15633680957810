.coworker_columns {
  .col-lg-3 {
    margin-bottom: 30px;
  }

  .coworker_column {
    color: $white;
    height: 100%;
    position: relative;

    &.coworker_column--category {
      background: $brand-green;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 320px;

      .coworker_column__title {
        font-size: $h5-font-size;
        margin-bottom: 0;
        padding: 1.5rem 1rem;
        word-break: break-word;
        word-wrap: break-word;
        overflow-wrap: break-word;
        hyphens: auto;
      }
    }

    .coworker_column__main {
      max-height: 380px;
      overflow: hidden;

      @include media-breakpoint-up(sm) {
        max-height: 100%;
      }
    }

    .coworker_column__main,
    .coworker_column__overlay {
      img {
        width: 100%;
        height: auto;
      }
    }

    .coworker_column__title,
    .coworker__name {
      text-transform: uppercase;
    }

    .coworker_column__overlay {
      height: auto;
      width: 100%;
      overflow: hidden;
      opacity: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transition: $transition-base;

      .overlay__wrapper {
        background: $brand-green-transparent;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
        padding: 1.5rem 1rem;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        @include media-breakpoint-up(xl) {
          padding-bottom: 2.5rem;
        }
      }

      @include media-breakpoint-up(sm) {
        max-height: 100%;
      }
    }

    &:hover,
    &:focus,
    &:active {
      .coworker_column__overlay {
        opacity: 1;
      }
    }

    .coworker__name {
      font-size: $font-size-lg;
      margin-bottom: .25rem;
      word-break: break-word;
      hyphens: auto;

      @include media-breakpoint-up(sm) {
        font-size: 1.25rem;
      }
    }

    .coworker__function,
    .coworker__email,
    .coworker__phone {
      font-size: $font-size-sm;
      margin-bottom: 0;

      a {
        text-decoration: none;
      }
    }

    .coworker__email {
      word-break: break-all;
    }

    .coworker__phone {
      font-family: $headings-font-family;
      font-weight: $font-weight-bold;
    }
  }
}
