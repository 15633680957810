@include media-breakpoint-up(xl){
  .slide__content > div {
    min-height: 540px;
  }
}

.slide_image {
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    width: auto;
    max-width: none;
    min-width: 1000%;
    min-height: 1000%;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) scale(0.1);
    z-index: -1;
  }
}

.slide_teaser {
  .col--left {
    background: $brand-turquoise-bg;
    order: 2;
  }

  .col--right {
    order: 1;
    padding-left: 0;
    padding-right: 0;
  }

  .slide_teaser__info {
    padding: 2rem 1rem;

    > * {
      width: 100%;
    }

    .slide_teaser__link {
      margin-bottom: 0;
      margin-top: 2rem;

      a {
        @include more-link;
      }
    }
  }

  .slide_teaser__title {
    margin-bottom: 2rem;
  }

  .slide_teaser__intro {
    font-size: $font-size-sm;
  }

  // IE FIX FOR FLEX COLUMN
  .slide_teaser__title,
  .slide_teaser__info {
    max-width: 100%;
  }

  .slide_teaser__image img {
    height: auto;
  }

  @include media-breakpoint-up(sm) {
    .col--left {
      order: 1;
    }

    .col--right {
      order: 2;
    }

    .slide_teaser__image {
      height: 100%;
      overflow: hidden;

      img {
        height: 100%;
        max-width: none;
        width: auto;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .slide_teaser__info {
      padding: 5rem 2rem;
    }
  }

  @include media-breakpoint-up(xl) {
    .container-fluid,
    .row {
      height: 100%;
    }

    .slide_teaser__info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      height: 100%;
      margin-left: auto;
      padding: 0 3rem 0 0;
      width: 85%;
    }

    .slide_teaser__title {
      font-size: $h1-font-size;
      margin-bottom: 3rem;
    }
  }

  @media only screen and (min-width: 1600px) {
    .slide_teaser__info {
      padding: 0 4rem 0 0;
      width: 75%;
    }
  }
}
