.node-teaser,
.node-teaser-without-image,
.node-teaser--mini,
.node-teaser--vertical,
.node-promoted,
.node-promoted--vertical {
  position: relative;

  .teaser__link {
    @include cover-all-link;
    
    &:hover,
    &:focus {
      & + .node__wrapper {
        .more__link {
          text-decoration: none;
        }
      }
    }
  }

  .node__wrapper {
    @include flex;
    flex-direction: row;
    padding: 0;

    @include media-breakpoint-down(lg) {
      word-break: break-word;
      word-wrap: break-word;
      hyphens: auto;
    }
  }
}

.node-teaser,
.node-teaser-without-image {
  .teaser__link {
    &:hover,
    &:focus {
      & + .node__wrapper {
        .more__link {
          text-decoration: none;
        }
      }
    }
  }
}

.node-teaser--mini {
  .teaser__link {
    &:hover,
    &:focus {
      & ~ .more__link {
        text-decoration: none;
      }
    }
  }
}
