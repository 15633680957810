.form-item {
  margin: 0 0 1rem;
  border-radius: 0;

  label {
    display: block;
    margin: 0 0 5px;
  }

  input {
    &:focus {
      outline: $brand-red-light auto 1px;
    }
  }

  .error {
    border: 1px solid $c-red;
  }

  .description {
    color: $gray-700;
    font-size: $font-size-sm;
    margin: 4px 0 0;
  }
}

.form-text,
.form-textarea,
.form-select {
  background: $brand-red-bg;
  border: 0;
  border-radius: 0;
  padding: .75rem;
  width: 100%;
}

// Radios and checkboxes.
.form-type-radio,
.form-type-checkbox {
  display: flex;
}

input[type='radio'],
input[type='checkbox'] {
  $checkbox-radio-width: 20px;
  $checkbox-radio-margin: 10px;
  $checkbox-radio-outer-width: $checkbox-radio-width + $checkbox-radio-margin;
  width: $checkbox-radio-outer-width;
  margin-top: 1px;

  & ~ label {
    position: relative;
    width: calc(100% - #{$checkbox-radio-width});
    cursor: pointer;

    &::before {
      font-family: $font-awesome;
      content: '';
      display: block;
      width: $checkbox-radio-width;
      height: $checkbox-radio-width;
      position: absolute;
      z-index: 1;
      top: 0;
      left: -$checkbox-radio-outer-width;
      background: $brand-red-bg;
      line-height: $checkbox-radio-width;
      text-align: center;
    }

    &:hover::before {
      color: $gray-500;
    }
  }
}

input[type='radio'] {
  & ~ label::before {
    border-radius: 50%;
  }

  &:checked ~ label::before {
    content: '\f111';
    font-weight: $font-weight-bold;
  }
}

input[type='checkbox']:checked ~ label::before {
  content: '\f00c';
  font-weight: $font-weight-normal;
}

.form-textarea-wrapper .grippie {
  display: none;
}

.webform-component-select {
  @include arrow("v", $brand-red, $brand-red-bg);

  .form-select {
    appearance: none;
    cursor: pointer;
  }
}

.captcha {
  margin-bottom: 1rem;
}

.form-submit {
  border: 0;
  border-radius: 0;
  background: $brand-red;
  color: $white;
  padding: .75rem;
  transition: background .35s ease;
  width: 100%;

  &:hover {
    background: $brand-red-bg;
    color: $brand-red;
    cursor: pointer;
  }
}

fieldset {
  border: 0;
  margin: 0 0 40px;
  padding: 0;

  legend {
    margin: 0 0 15px;
    font-size: 1.8rem;
    line-height: 1.8rem;
    text-align: left;
  }
}

.webform-client-form .form-actions {
  @include submit-arrow;
}

.webform-confirmation {
  background: $white;
  padding: 3rem 1.5rem 2rem;
  margin-bottom: 1.5rem;

  @include media-breakpoint-up(sm) {
    padding: 4rem 1.5rem 3rem;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 4rem;
  }
}
