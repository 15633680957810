.block-facetapi {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
  text-align: center;

  &.block--facetapi--field-tags {
    text-align: center;

    h6 {
      margin: 0;
    }
  }

  &:last-child {
    margin-bottom: 5rem;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: .5rem;
  }

  h6 {
    color: $brand-green;
    width: 100%;
  }

  .item-list {
    display: inline-block;

    ul.expanded {
      padding-left: 30px;
    }

    a {
      text-decoration: none;
    }

    .facetapi-facetapi-links {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        margin: 0 1rem 10px 0;

        .facetapi-item--active {
          background: $brand-green;
          color: $white;
          display: inline-block;
          padding: .5rem 54px .5rem 1rem;
          position: relative;

          // Deactivation link.
          .facetapi-active {
            display: flex;
            align-items: center;
            justify-content: center;
            background: $white;
            color: $brand-green;
            font-family: $headings-font-family;
            font-weight: $font-weight-bold;
            height: 38px;
            position: absolute;
            top: 0;
            right: 0;
            width: 38px;

            &:hover,
            &:focus {
              color: $brand-red;
            }
          }
        }

        .facetapi-item--inactive {
          // Full word activation link.
          .facetapi-inactive {
            color: $brand-green;
            display: block;
            background: rgba($white, .5);
            padding: .5rem 1rem;

            &:hover,
            &:focus {
              background: $brand-green;
              color: $white;
            }
          }
        }
      }

      // Inline the Tag links.
      &.facetapi-facet-field-tags {
      }

      &.facetapi-facet-field-publication-date {}
    }
  }
}