.page_columns {
  .col-lg-3 {
    margin-bottom: 30px;
  }

  .page_column {
    color: $white;
    height: 100%;
    position: relative;

    .page_column__link {
      @include cover-all-link;

      &:hover,
      &:focus,
      &:active {
        .page_column__overlay {
          opacity: 1;
        }
      }
    }

    .page_column__title {
      font-size: 1.625rem;
      height: 100%;
      line-height: 1.2;
      min-height: 320px;
      padding: 2.5rem 1.5rem;
      margin-bottom: 0;
      word-break: break-word;
      word-wrap: break-word;
      hyphens: auto;

      @include media-breakpoint-up(xl) {
        font-size: $h2-font-size;
        padding: 3rem 1.5rem;
      }
    }

    .page_column__overlay {
      min-height: 320px;
      opacity: 0;
      position: relative;
      transition: $transition-base;
      width: 100%;

      @include media-breakpoint-up(sm) {
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    &:hover,
    &:focus {
      .page_column__overlay {
        opacity: 1;
      }
    }

    // Indicator for the hover overlay.
    &.page_column--overlay {
      .page_column__main {
        height: 100%;
        overflow: hidden;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;

        img {
          width: 100%;
          height: auto;

          @include media-breakpoint-down(sm) {
            margin-top: -50%;
          }
        }

        @include media-breakpoint-up(sm) {
          position: relative;
          left: auto;
          right: auto;
          bottom: auto;
        }
      }
    }
  }

  // Hover background color variations.
  &.color-pink {
    .page_column {
      > .page_column__title {
        background: $brand-red;
      }

      .page_column__overlay {
        background: $brand-pink-transparent;
      }
    }
  }

  &.color-blue {
    .page_column {
      > .page_column__title {
        background: $brand-blue;
      }

      .page_column__overlay {
        background: $brand-blue-transparent;
      }
    }
  }
}
