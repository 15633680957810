.page_intro {
  @include image-and-title-layout;

  .page_intro__main {
    @include image-main;
  }

  .page_intro__overlay {
    @include image-overlay("right");
  }

  .page_intro__title {
    @include overlay-title($brand-green-light-transparent);
  }

  .page_intro__secondary {
    @include image-secondary;
  }
}

