.header__content {
  .region-cta-links-mobile {
    clear: both;
  }

  .cta-links-mobile {
    width: 80%;
    float: left;

    @include media-breakpoint-up(lg) {
      display: none;
    }

    .paragraphs-item-cta {
      height: $mobile-sticky-height;
      display: flex;

      a {
        flex: 1 1 auto;
        padding: ($grid-gutter-width / 3);

        .image {
          margin: 0 auto;
          flex: 0 0 30px;
        }

        span {
          display: none;
        }
      }
    }
  }
}
