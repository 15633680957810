.region-sidebar-second {
  .views-exposed-widgets {
    padding: 20px;
    background: $c-white;

    label {
      margin: 0 0 10px;
      display: block;
      text-align: left;
      font-size: 1.4rem;
      font-weight: $fw-lighter;
      text-transform: uppercase;
    }

    .form-type-bef-link {
      margin: 0 0 10px;

      a {
        display: block;

        &:hover {
          text-decoration: underline;
        }

        &.active {
          font-weight: $fw-normal;
        }
      }
    }
  }
}
