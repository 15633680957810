.node-poll {
  .poll__cancel {
    .form-actions {
      display: flex;
      justify-content: flex-end;
    }
  }

  .poll__results {
    .total {
      color: $brand-blue;
      font-family: $headings-font-family;
      font-size: $font-size-sm;
      font-weight: $font-weight-bold;
      margin: .5rem 0;
    }
  }

  .poll__bar {
    .text {
      margin-bottom: .5rem;
    }

    .bar {
      background-color: $white;

      .foreground {
        background-color: $brand-blue;
      }
    }

    .percent {
      color: $brand-blue;
      font-size: $font-size-sm;
      margin-top: .5rem;
    }
  }

  @include media-breakpoint-up(xl) {
    .poll__results,
    .poll__cancel {
      display: inline-block;
    }

    .poll__results {
      width: 65%;
    }

    .poll__cancel {
      position: absolute;
      right: 2rem;
      width: 35%;
    }
  }

  .poll__voting {}

  .poll__vote {
    .vote__form {}

    .vote__choices {
      .form-item-choice {
        margin-bottom: .5rem;
        position: relative;
      }

      .form-radio {
        height: 100%;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 2;

        &:checked {
          & + .option::before {
            background-color: $brand-blue;
            background-image: url("../images/svg/check.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 14px;
          }
        }
      }

      .option {
        display: inline-block;
        padding-left: 28px;

        &::before {
          background-color: $white;
          border: 3px solid $brand-blue;
          border-radius: 0;
          content: "";
          display: block;
          height: 20px;
          position: absolute;
          top: 0;
          left: 0;
          width: 20px;
          z-index: 1;
        }
      }
    }
  }

  .form-submit {
    background: $brand-blue;
    font-family: $headings-font-family;
    font-weight: $font-weight-bold;
    margin-top: 1rem;
    text-transform: uppercase;
    width: auto;

    &:hover {
      background: $white;
      color: $brand-blue;
    }
  }

  &.node-promoted {
    background: rgba($brand-blue, .2);
    padding: 132px 2rem 2rem;

    &::before {
      background: $brand-blue;
      background-image: url("../images/svg/poll-icon.svg");
      background-size: 60px;
      background-repeat: no-repeat;
      background-position: 2rem center;
      content: "";
      display: inline-block;
      height: 100px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

    .poll__title {
      font-size: $font-size-lg;
      margin-bottom: 1.5rem;
    }

    @include media-breakpoint-up(md) {
      padding: 2rem 2rem 2rem 232px;

      &::before {
        background-size: 80px;
        background-position: center;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 200px;
      }
    }

    @include media-breakpoint-up(xl) {
      padding: 2rem 2rem 2rem 292px;

      &::before {
        width: 260px;
      }

      .vote__form {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .form-type-radios {
          padding-left: 3.5rem;
        }
      }

      .form-submit {
        margin-top: 0;
      }
    }
  }
}