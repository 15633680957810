.js-clear-field-button {
  position: absolute;
  right: 13px;
  top: 13px;
  width: 20px;
  height: 20px;
  z-index: 100;

  text-indent: -999rem;
  border: none;

  background: url("../images/svg/close.svg") center center no-repeat;
  background-size: 12px;
  // IE fix
  background-color: $white;

  cursor: pointer;

  padding: 0;
}