// MIXINS FOR IMAGES WITH OVERLAY BOX WITH TITLE
@mixin image-and-title-layout {
  position: relative;
  padding-bottom: 3rem;

  @include media-breakpoint-up(lg) {
    padding-bottom: 4.25rem;
  }

  @include media-breakpoint-up(xl) {
    padding-bottom: 5.5rem;
  }
}

@mixin image-main {
  width: 85%;

  @include media-breakpoint-up(md) {
    width: 80%;
  }
}

@mixin image-overlay($position: "right") {
  overflow: hidden;
  position: absolute;
  bottom: 1rem;
  width: 50%;

  @if $position == "right" {
    right: 0;
  } @else if $position == "left" {
    left: 0;
  }

  @include media-breakpoint-up(md) {
    width: 45%;
  }

  @include media-breakpoint-up(lg) {
    width: 40%;
  }
}

@mixin overlay-title($bg-color: $brand-green-light) {
  background: $bg-color;
  color: $white;
  font-size: 1.4rem;
  height: 100%;
  line-height: 1.2;
  margin-bottom: 0;
  overflow: hidden;
  padding: 1.15rem 1rem 1rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;

  // Smaller font size for smallest mobile sizes
  @media only screen and (max-width: 359px) {
    font-size: 1.15rem;
  }

  @include media-breakpoint-up(sm) {
    font-size: 2.25rem;
    line-height: 1.3;
    padding: 2.5rem 1.25rem 2rem;
  }

  @include media-breakpoint-up(lg) {
    font-size: 2.625rem;
    padding: 4rem 1.5rem 2rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: 3.313rem;
    padding: 5rem 2rem 2rem;
  }
}

@mixin image-secondary {
  position: relative;
  z-index: 1;
}