.block--mailchimp-signup--newsletter-toerisme-limburg {
  .right {
    padding: 0 1.5rem;
  }

  .image {
    margin: 0 0 ($grid-gutter-width / 2);
    padding: $grid-gutter-width;
    background: $brand-red;
    text-align: center;

    @include media-breakpoint-up(md) {
      max-width: 200px;
      margin-left: auto;
    }
  }

  h2 {
    color: $brand-red;
    font-size: $font-size-base;
  }
}
