@mixin svg-icon {
  background-size: contain;
  content: "";
  display: inline-block;
  transition: $transition-base;
}

@mixin svg-icon-before($name, $size: 16) {
  &::before {
    background: url("../images/svg/#{$name}.svg") center center no-repeat;
    @include svg-icon;
    height: #{$size}px;
    margin-right: .1rem;
    width: #{$size}px;
  }
}

@mixin svg-icon-after($name, $size: 16) {
  &::after {
    background: url("../images/svg/#{$name}.svg") center center no-repeat;
    @include svg-icon;
    height: #{$size}px;
    margin-left: .5rem;
    width: #{$size}px;
  }
}