@mixin colored-strip {
  > span {
    flex: 1 0 auto;
    height: 10px;

    @include media-breakpoint-up(md) {
      height: 20px;
    }
  }
}
