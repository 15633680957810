.view {
  .views-row {
    margin: 0 0 30px;
  }
}

//.front {
//  .view {
//    &.view-news {
//      .views-row {
//        margin: 0 0 30px;
//      }
//    }
//
//    &.view-events {
//      .views-row {
//        background: $c-white;
//        border-bottom: 1px solid $c-light-grey;
//        margin: 0;
//      }
//    }
//  }
//}
