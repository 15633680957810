.mailchimp-signup-subscribe-form {
  .mailchimp-signup-subscribe-form-description {
    margin-bottom: $grid-gutter-width / 2;
    font-size: .6rem;

    a {
      font-weight: $font-weight-bold;
    }
  }

  .form-actions {
    @include submit-arrow;
    padding: 0;
  }
}
