.tabs__wrapper {
  position: fixed;
  left: 2rem;
  top: 14rem;
  z-index: 998;

  .tabs__toggle {
    background: $brand-green;
    border: 0;
    border-radius: 50%;
    box-shadow: $btn-box-shadow;
    color: $white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-size-lg;
    height: 3rem;
    margin: 0;
    padding: 0;
    width: 3rem;

    &:hover,
    &:focus {
      background: $brand-green-light;
    }
    
    .fa-pen {
      display: inline-block;
    }

    .fa-times {
      display: none;
    }
    
    &.toggle--close {
      .fa-pen {
        display: none;
      }

      .fa-times {
        display: inline-block;
      }
    }
  }

  .tabs {
    background: $brand-green-light;
    box-shadow: $dropdown-box-shadow;
    color: $white;
    display: none;
    list-style: none;
    margin: 1rem 0;
    padding: 0;

    a {
      display: block;
      font-family: $headings-font-family;
      font-weight: $font-weight-bold;
      padding: .75rem 1rem;
      text-decoration: none;

      &:hover,
      &:focus {
        background: $brand-green;
      }
    }
    
    &.tabs--open {
      display: block;
    }
  }
  
  .not-logged-in & {
    display: none;
  }
}