h1 {
  margin-bottom: 3rem;

  @include media-breakpoint-down(sm) {
    font-size: 2.5rem;
    word-break: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }
}

a {
  color: inherit;
  text-decoration: underline;

  &:hover,
  &:focus {
    color: inherit;
    text-decoration: none;
  }
}

a,
button,
.btn {
  transition: $transition-base;
}

.more__link,
.back__link {
  @include more-link;

  &.--small {
    font-size: $font-size-base;
  }
}
