.page-user {
  .region-content {
    form {
      background: $white;
      padding: 2rem 1rem;

      @include media-breakpoint-up(sm) {
        padding: 2rem;
      }

      @include media-breakpoint-up(lg) {
        padding: 6rem;

        > div {
          margin: 0 auto;
          max-width: 576px;
        }

        .form-submit {
          width: auto;
        }
      }
    }
  }
}