.block--feature-homepage--toerismewerkt-sponsor-logos {
  display: flex;
  align-items: center;
  margin: 0 auto;
  border-bottom: 2px solid $gray-300;
  border-top: 2px solid $gray-300;
  padding: .9375rem 0;

  @include media-breakpoint-up(lg) {
    border: 0;
    padding: 0;
  }
}

.sup {

  &-logos {
    display: flex;
    align-items: center;
    margin: 0 auto;
  }

  &-link {
    margin: 0 .625rem;
  }

  &-image {
    width: 75px;
    height: auto;

    &.efro,
    &.province {
      width: 100px;
    }
  }
}
