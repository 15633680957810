.block--feature-homepage--toerismewerkt-social-links {
  padding: ($grid-gutter-width / 2) $grid-gutter-width;

  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: center;
    padding: $grid-gutter-width;
    padding-left: $grid-gutter-width / 2;
  }

  .social-links {
    a {
      display: inline-block;
      margin-right: $grid-gutter-width / 3;
      text-decoration: none;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}
