.block--feature-homepage--toerismewerkt-cta-links {
  @include media-breakpoint-up(lg) {
    width: 100%;
    flex: 0 0 100%;
    order: 10;
  }

  .cta-links {
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: row;
    }

    a {
      @include cta-link;
    }
  }
}
