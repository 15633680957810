.block--feature-homepage--toerismewerkt-footer-text {
  .content {
    p {
      display: flex;
      justify-content: space-evenly;
    }

    img {
      width: auto;
    }
  }
}