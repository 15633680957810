.content__wrapper {
  overflow: hidden;
  position: relative;
  padding-top: 0;
  width: 100%;

  @include media-breakpoint-up(md) {
    padding-bottom: 8rem;
  }

  > .container {
    @include media-breakpoint-down(sm) {
      padding-left: 0;
      padding-right: 0;

      .page-node-done & {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

  .content__top {
    left: calc(-1 * (100vw - 100%) / 2);
    min-width: 100vw;
    padding: 0;
    margin-bottom: 4rem;
  }

  .node-unpublished {
    background-color: unset;
  }
}

.region-content-top {
  @include media-breakpoint-down(xs) {
    padding-left: 15px;
    padding-right: 15px;
  }
}
