.cta {
  display: none;

  @include media-breakpoint-up(lg) {
    display: block;
  }
}

.region-content-top .block--feature-homepage--toerismewerkt-cta-links {
  display: none;

  @include media-breakpoint-up(lg) {
    display: block;
  }
}

body.front .region-content-top .block--feature-homepage--toerismewerkt-cta-links {
  display: none;
}

.field-name-field-cta > .field-items {
  overflow: hidden;
  display: flex;
  flex-direction: row;

  > .field-item {
    //flex: 0 0 25%;
    margin: 0;
    //width: 25%;
    flex-basis: 100%;

    &:nth-child(1) {
      background-color: theme-color(green-light);
    }

    &:nth-child(2) {
      background-color: theme-color(red);
    }

    &:nth-child(3) {
      background-color: theme-color(pink-light);
    }

    &:nth-child(4) {
      background-color: theme-color(blue);
    }
  }

  .paragraphs-item-cta a {
    @include cta-link($h5-font-size, 70px, 20px 60px);

    flex-direction: row;
    justify-content: center;
    height: 100%;

    a {
      display: block;
      height: 100%;
      position: relative;

      &:hover {
        .field-name-field-title {
          background: $c-green;
          color: $c-white;
          transition: all 200ms ease-in;
        }
      }

      .field-name-field-title {
        background: $c-white;
        height: 100%;

        .field-items {
          padding: 10px 20px;
          text-transform: uppercase;
        }
      }

      .field-name-field-image + .field-name-field-title {
        height: auto;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
      }

      img {
        width: 100%;
      }
    }

    .image {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 30px;
      margin-bottom: 0;
      width: 50px;
      flex: 0 0 50px;
    }

    span {
      text-align: center;
    }
  }
}
