.region-content,
.region-content-bottom {
  .block-views {
    margin-top: 5rem;

    @include media-breakpoint-up(md) {
      margin-top: 7rem;
    }

    > h2 {
      margin-bottom: 3rem;

      @include media-breakpoint-down(xs) {
        margin-bottom: 2rem;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}

// Remove large margin on inspiration blocks on homepage.
// Hide duplicate title if both blocks are visible.
.block--views--inspiration-home-promoted-block + .block--views--inspiration-home-recent-block {
  margin-top: 2rem;

  > h2 {
    display: none;
  }
}

// Hide duplicate title if both blocks are visible.
.block--views--events-promoted-block + .block--views--events-homepage-upcoming-block {
  > h2 {
    display: none;
  }
}