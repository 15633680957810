.node-webform {
  &.node-full {
    .webform-client-form,
    .field-name-body,
    .field-name-field-body {
      margin: 0 0 20px;
    }

    .webform__sidebar_top {
      background: $brand-red;
      color: $white;
      padding: 4rem 1.5rem 1.5rem;

      // same layout als event full node (margin top and white shape behind colored block)
      @include media-breakpoint-up(md) {
        margin-top: 4rem;
        padding-top: 50%;
      }

      @include media-breakpoint-up(lg) {
        margin-top: 5rem;
      }

      p:last-of-type {
        margin-bottom: 0;
      }
    }

    .webform__sidebar_top,
    .webform__sidebar_bottom {
      position: relative;
      z-index: 1;
    }

    .webform__sidebar_bottom,
    .webform__right {
      padding: 1.5rem;
      background: $white;
    }

    .webform__right {
      @include shape-white-behind;
    }
  }
}
