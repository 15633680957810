#sliding-popup.sliding-popup-bottom {
  background: $brand-green-light;
  box-shadow: 0 1px 8px rgba(0,0,0, .25);
  overflow: hidden;

  .popup-content {
    max-width: 800px;
    padding: 24px 0;
    width: 100%;

    #popup-text,
    #popup-buttons {
      display: block;
      float: none;
      max-width: none;
      padding: 0 20px;
    }

    #popup-text {
      font-weight: 300;
      margin: 0;
      text-align: center;

      p {
        font-size: 14px;
        font-weight: 300;
        margin: 0 0 10px 0;

        .external-link {
          color: $c-white !important;
          font-weight: 600;
          text-decoration: none;
          
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    #popup-buttons {
      margin: 20px 0 0 0;

      button {
        border: 0;
        border-radius: 0;
        box-shadow: none;
        color: $c-white;
        font-family: $font;
        font-size: 14px;
        font-weight: normal;
        margin: 0;
        padding: 12px;
        text-shadow: none;
        transition: all 200ms ease-in;
        width: 100%;

        &.agree-button {
          background: $c-black;
          border: 1px solid $c-black;
          margin-bottom: 15px;

          &:hover {
            background: $c-white;
            border-color: $c-white;
            color: $brand-green-light;
          }
        }

        &.find-more-button {
          background: transparent;
          border: 1px solid transparent;

          &:hover {
            border-color: $c-white;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  #sliding-popup.sliding-popup-bottom {
    .popup-content {
      #popup-buttons {
        @include clearfix;

        button {
          float: left;
          width: 49%;

          &.agree-button {
            margin-right: 2%;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
