.node-homepage {
  &-full {
    position: relative;
  }
}

.node-type-homepage {
  .region-header .block--feature-homepage--toerismewerkt-cta-links {
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}
