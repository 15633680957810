$size: $font-size-base * $line-height-base + .75rem * 2;

@mixin submit-arrow {
  @include arrow(">", $brand-red-bg, $brand-red);

  .form-submit {
    text-align: left;

    &:first-of-type {
      padding-right: calc(.75rem + #{$size});
    }
  }
}

@mixin arrow($content, $background, $color) {
  position: relative;

  &::after {
    content: $content;
    position: absolute;
    right: 0;
    bottom: 0;
    width: $size;
    height: $size;
    display: block;
    background: $background;
    color: $color;
    font-weight: $font-weight-bold;
    line-height: $size;
    text-align: center;
    pointer-events: none;
  }
}
