.search-header__trigger {
  border: none;
  background: transparent;
  color: $body-color;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: $headings-font-family;
  font-weight: $font-weight-bold;
  height: 46px;
  padding: 0 $grid-gutter-width;
  position: relative;
  transition: $transition-base;
  width: 100%;

  &::before {
    background: $white;
    content: "\f002";
    color: inherit;
    font-family: $font-awesome;
    font-weight: 700;
    margin-right: 8px;
  }

  &:hover,
  &:focus {
    color: $brand-red;
  }

  @include media-breakpoint-up(lg) {
    height: auto;
    padding: 0 20px;
    overflow: hidden;
    text-indent: 99999em;
    width: 46px;

    &::before {
      text-indent: 0;
    }

    &::after {
      content: "|";
      color: $gray-300;
      display: inline-block;
      font-family: $font-family-base;
      font-weight: $font-weight-base;
      height: 18px;
      position: absolute;
      top: 0;
      left: 0;
      text-indent: 0;
      width: 4px;
    }
  }
}

.search-header__overlay {
  display: block;

  z-index: 999;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  height: 100vh;
  background: theme-color(primary);

  transition: $transition-base;

  .logo {
    margin-bottom: 2rem;
    width: 10rem;
  }

  @include media-breakpoint-up(lg) {
    position: absolute;
    height: 100%;

    .logo {
      margin-bottom: 0;
    }
  }

  .content {
    position: relative;
    padding: 1.2rem 1rem 1rem;

    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
      height: 100%;
    }

    // MOBILE SEARCH
    > form {
      max-width: 100%;
      position: relative;
      width: 100%;

      .views-exposed-widget {
        display: inline-block;
      }

      .views-widget-filter-search_api_views_fulltext {
        margin-bottom: 0;
        position: relative;
        width: calc(100% - 53px);

        .js-clear-field-button {
          background: url("../images/svg/close-white.svg") center center no-repeat;
          background-color: $brand-green;

          &:hover,
          &:focus {
            background: url("../images/svg/close.svg") center center no-repeat;
            background-color: $brand-green;
          }
        }
      }

      .form-text {
        background: transparent;
        border-bottom: 2px solid $brand-green-light;
        color: $white;
        font-family: $headings-font-family;
        font-weight: $font-weight-bold;

        &:focus {
          border-bottom: 2px solid $white;
          outline: $brand-green auto 2px;
        }
      }

      input:-internal-autofill-previewed,
      input:-internal-autofill-selected {
        background: rgba(255, 255, 255, .1) !important;
      }

      .form-submit {
        background: transparent;
        height: 48px;
        overflow: hidden;
        padding: 0;
        position: relative;
        text-indent: 101%;
        width: 48px;
        z-index: 1;
      }

      .views-submit-button {
        height: 48px;
        position: relative;
        transition: $transition-base;
        width: 48px;

        &::after {
          content: "\f002";
          color: $white;
          font-family: $font-awesome;
          font-size: $h5-font-size;
          font-weight: 700;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 0;
        }

        &:hover,
        &:focus {
          opacity: .5;
        }
      }

      @include media-breakpoint-up(lg) {
        width: 550px;
        margin: 0 0 0 5rem;
      }
    }
  }

  .search-header__close {
    background: none;
    border: none;
    color: $white;
    cursor: pointer;
    font-size: .75rem;
    overflow: hidden;
    text-align: center;
    text-transform: uppercase;
    transition: $transition-base;

    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 46px;
    height: 46px;
    padding: .7rem 0 0;

    @include svg-icon-before('close-white', 16);

    &::before {
      margin-right: 0;
    }

    &:hover,
    &:focus {
      opacity: .5;
    }

    @include media-breakpoint-up(md) {
      right: 2.5rem;
    }
  }
}

.block-search-header {
  align-self: center;

  .search-header__overlay {
    display: none;
  }

  &.search-header--active  {}
}