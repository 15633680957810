.view-inspiration {
  &.view-display-id-overview_page {
    @include alternate-layout("inspiration");

    // Alternate bg colors.
    .views-row:nth-child(4n+1) .inspiration {
      .col--right {
        background-color: $brand-red;
      }

      &.inspiration--video .col--left {
        &::before {
          background: rgba(241, 100, 100, .8);
        }
      }
    }

    .views-row:nth-child(4n+2) .inspiration {
      .col--right {
        background-color: $brand-turquoise-light;
      }

      &.inspiration--video .col--left {
        &::before {
          background: rgba(171, 200, 202, .8);
        }
      }
    }

    .views-row:nth-child(4n+3) .inspiration {
      .col--right {
        background-color: $brand-green;
      }

      &.inspiration--video .col--left {
        &::before {
          background: rgba(81, 145, 122, .8);
        }
      }
    }

    .views-row:nth-child(4n+4) .inspiration {
      .col--right {
        background-color: $brand-pink-light;
      }

      &.inspiration--video .col--left {
        &::before {
          background: rgba(238, 198, 184, .8);
        }
      }
    }

    .pager {
      @include pager-load-more;
    }
  }

  &.view-display-id-home_promoted_block {
    // Alternate overlay bg colors.
    .views-row-1 .inspiration {
      .teaser__overlay {
        background-color: $brand-red-transparent;
      }
    }

    .views-row-2 .inspiration {
      .teaser__overlay {
        background-color: $brand-turquoise-light-transparent;
      }
    }

    .views-row-3 .inspiration {
      .teaser__overlay {
        background-color: $brand-green-transparent;
      }
    }

    .views-row-4 .inspiration {
      .teaser__overlay {
        background-color: $brand-pink-light-transparent;
      }
    }

    .views-row-5 {

      @include media-breakpoint-up(lg) {
        height: calc(100% - #{$grid-gutter-width});
      }

      .inspiration {
        @include media-breakpoint-up(lg) {
          height: 100%;

          .inspiration__image {
            overflow: hidden;

            &,
            div {
              height: 100%;
            }

            img {
              @include media-breakpoint-down(md) {
                width: 100%;
                height: auto;
              }

              width: auto;
              max-width: none;
              height: 100%;
              margin-left: 50%;
              transform: translateX(-50%);
            }
          }
        }

        .teaser__overlay {
          background-color: $brand-blue-transparent;
        }
      }
    }
  }

  &.view-display-id-home_recent_block {
    display: none;

    @include media-breakpoint-up(sm) {
      display: block;
    }

    .more-link {
      text-align: center;

      a {
        @include more-link;
      }
    }

    // Alternate bg colors.
    .views-row-1 .inspiration {
      background-color: theme-color(red);
    }

    .views-row-2 .inspiration {
      background-color: theme-color(turquoise-light);
    }

    .views-row-3 .inspiration {
      background-color: theme-color(green);
    }

    .views-row-4 .inspiration {
      background-color: theme-color(pink-light);
    }
  }

  &.view-display-id-recent_block {
    .views-row-1 .inspiration .colored__bg {
      background-color: theme-color(green);
    }

    .views-row-2 .inspiration .colored__bg {
      background-color: theme-color(turquoise-light);
    }

    .views-row-3 .inspiration .colored__bg {
      background-color: theme-color(pink-light);
    }
  }
}

.page-inspiration {
  @include view-mobile-layout;
}
