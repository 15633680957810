.block--feature-homepage--toerismewerkt-header-links {
  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;
  }

  .header-links {
    @include media-breakpoint-up(lg) {
      display: flex;
    }

    .header-link {
      @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;

        &::after {
          content: "|";
          color: $gray-300;
          display: inline-block;
        }

        &:last-child::after {
          display: none;
        }
      }

      a {
        display: flex;
        align-items: center;
        padding: ($grid-gutter-width / 2) $grid-gutter-width;
        border-bottom: 2px solid $gray-300;
        font-size: $font-size-sm;
        font-weight: bold;
        text-decoration: none;
        text-transform: uppercase;

        @include media-breakpoint-up(lg) {
          padding: $grid-gutter-width / 2;
          border-bottom: 0;
          font-size: .5rem;
        }

        br {
          content: " ";

          &::after {
            content: " ";
          }

          @include media-breakpoint-up(lg) {
            content: initial;

            &::after {
              display: none;
            }
          }
        }

        .image {
          width: 25px;
          flex: 0 0 25px;
          margin-right: $grid-gutter-width / 2;

          @include media-breakpoint-up(lg) {
            margin-right: $grid-gutter-width / 3;
          }
        }
      }
    }
  }
}
