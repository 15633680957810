header {
  $admin-menu-height: 29px;
  position: fixed;
  top: 0;
  width: 100%;
  background: $white;
  z-index: 996;

  .admin-menu & {
    padding-top: $admin-menu-height;
  }

  .header__top {
    @include media-breakpoint-up(lg) {
      @include colored-strip;
    }
  }

  .header__content {
    margin: 0 auto;
  }

  .mobile__toggle {
    width: 20%;
    float: right;
    background: $white;
    border: 0;
    cursor: pointer;
    font-size: 20px;
    line-height: $mobile-sticky-height;
    height: $mobile-sticky-height;
    padding: 0;

    i {
      margin: 0 auto;
    }
  }

  .header__logo-mobile {
    clear: both;
    display: block;
    width: 10rem;
    margin-left: 2.4rem;
    img {
      max-width: none;
      width: 100%;
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  .region-header {
    background: $white;
    box-shadow: -3px 0 5px rgba($gray-800, .25);
    display: none;
    position: fixed;
    top: $mobile-sticky-height;
    right: 0;
    width: 90%;
    height: calc(100% - #{$mobile-sticky-height});
    overflow-x: hidden;
    z-index: 996;

    .admin-menu & {
      top: $mobile-sticky-height + $admin-menu-height;
      height: calc(100% - #{$mobile-sticky-height} - #{$admin-menu-height});

      @include media-breakpoint-up(lg) {
        top: initial;
      }
    }

    @include media-breakpoint-up(lg) {
      position: relative;
      top: 0;
      right: initial;
      box-shadow: none;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }

    &.mobile--open {
      display: block;
    }

    .header__logo {
      display: block;
      width: 10rem;
      padding-top: 0.3rem;
      margin: 0 $grid-gutter-width 0;

      img {
        max-width: none;
        width: 100%;
      }

      @include media-breakpoint-up(lg) {
        margin: 0 $grid-gutter-width;
      }
    }
  }
}
