.block--views---exp-events-overview-page,
.block--views---exp-inspiration-overview-page {
  max-width: 690px;
  margin: 2rem auto;

  form {
    position: relative;
    
    label {
      color: $brand-turquoise-light;
      font-family: $headings-font-family;
      font-weight: $font-weight-bold;
      margin-bottom: 0;
      pointer-events: none;
      position: absolute;
      top: .75rem;
      left: .75rem;
      transition: $transition-base;
    }

    .floating-label {
      label {
        top: -1.65rem;
      }
    }

    // @see /components/misc/_js-clear-field-buttons.scss
    .js-clear-field-button {
      position: absolute;
      // Clear the borders.
      top: 1px;
      // 13px default + 46px width of submit button.
      right: 59px;
      // Make it as big as the submit button without borders
      width: 44px;
      height: 44px;
      background-size: 16px;

      // IE fix
      background-color: $brand-turquoise-lightest-transparent;
    }

    .form-text {
      background: $brand-turquoise-lightest-transparent;

      &:focus {
        outline: $brand-green-light auto 1px;
      }
    }

    .views-submit-button {
      position: absolute;
      right: 0;
      bottom: 0;

      &::after {
        background: $white;
        content: "\f002";
        color: $brand-turquoise-light;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $font-awesome;
        height: 46px;
        position: absolute;
        top: 0;
        left: 0;
        transition: $transition-base;
        width: 46px;
      }

      &:hover {
        &::after {
          background: $brand-green;
          color: $white;
        }
      }
    }

    .form-submit {
      background: transparent;
      color: $gray-800;
      height: 46px;
      overflow: hidden;
      position: relative;
      text-indent: 99999em;
      width: 46px;
      z-index: 2;
    }
  }
}
