@mixin shape-white-behind {
  @include media-breakpoint-up(md) {
    padding-top: 5rem;

    &::before {
      background: $white;
      content: "";
      display: inline-block;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      margin-left: -8rem;
      width: 8rem;
    }
  }

  @include media-breakpoint-up(lg) {
    padding-top: 7rem;
  }
}
