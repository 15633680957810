.title_image {
  @include image-and-title-layout;

  .title_image__link {
    @include cover-all-link;
  }

  .title_image__main {
    @include image-main;
    margin-left: 15%;

    @include media-breakpoint-up(md) {
      margin-left: 20%;
    }
  }

  .title_image__overlay {
    @include image-overlay("left");
  }

  .title_image__title {
    @include overlay-title($brand-green-light-transparent);
  }

  .title_image__secondary {
    @include image-secondary;
  }
}
